import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  Heading,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Stack,
  Text,
  VStack,
  Image,
} from "@chakra-ui/react";
import { ViewOffIcon, ViewIcon } from "@chakra-ui/icons";
import { Auth } from "aws-amplify";
import UserVerification from "./UserVerification";
import logo from "../../Assets/logo.svg";
import landingHome from "../Assets/homeBg.png";
import { v4 as uuidv4 } from "uuid";

const UserSignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showVerify, setShowVerify] = useState(false);
  const [error, setError] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const handleClick = () => setShowPassword(!showPassword);

  const formatPhoneNumber = (value: string) => {
    const cleaned = value.replace(/\D/g, "");
    const formatted = cleaned.startsWith("1") ? `+${cleaned}` : `+1${cleaned}`;
    return formatted;
  };

  const handleSignUp = async () => {
    try {
      await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          phone_number: formatPhoneNumber(phoneNumber),
          "custom:user_id": uuidv4(),
          "custom:first_name": firstName,
          "custom:last_name": lastName,
        },
      });

      setShowVerify(true);
    } catch (error: any) {
      setError(error.message);
      console.error("Error during sign-up:", error);
    }
  };

  return (
    <Box w="full" minH="100vh" bgColor="#f4eee9" p="10px" pt="10vh" bgImage={landingHome} bgSize="contain" bgRepeat="no-repeat" bgPosition="bottom">
      <Container maxW="lg" py={{ base: "2", md: "24" }} px={{ base: "0", sm: "8" }} fontFamily="'eurostile', sans-serif">
        {showVerify ? (
          <UserVerification email={email} password={password} />
        ) : (
          <Stack spacing="8">
            <Stack spacing="2">
              <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
                <VStack w="full">
                  <Image w="60px" h="60px" mb="10px" src={logo} />
                  <Heading size={{ base: "xs", md: "sm" }}>Start saving on energy costs</Heading>
                </VStack>
              </Stack>
            </Stack>
            <Box
              py={{ base: "0", sm: "8" }}
              px={{ base: "4", sm: "10" }}
              bg={{ base: "#f4eee9", sm: "white" }}
              boxShadow={{ base: "none", sm: "md" }}
              borderRadius={{ base: "none", sm: "xl" }}
            >
              <Stack spacing="6">
                <form>
                  <Stack spacing="5">
                    <HStack>
                      <FormControl>
                        <Input
                          id="first_name"
                          type="first_name"
                          placeholder="First Name"
                          borderColor={{ base: "#141414", sm: "gray.300" }}
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          isRequired
                        />
                      </FormControl>
                      <FormControl>
                        <Input
                          id="last_name"
                          type="last_name"
                          placeholder="Last Name"
                          borderColor={{ base: "#141414", sm: "gray.300" }}
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          isRequired
                        />
                      </FormControl>
                    </HStack>
                    <FormControl>
                      <Input
                        id="phone"
                        type="phone"
                        placeholder="(***) *** ****"
                        borderColor={{ base: "#141414", sm: "gray.300" }}
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        isRequired
                      />
                    </FormControl>
                    <FormControl>
                      <Input
                        id="email"
                        type="email"
                        placeholder="Email"
                        borderColor={{ base: "#141414", sm: "gray.300" }}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        isRequired
                      />
                    </FormControl>
                    <FormControl>
                      <InputGroup size="md">
                        <Input
                          pr="4.5rem"
                          type={showPassword ? "text" : "password"}
                          borderColor={{ base: "#141414", sm: "gray.300" }}
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          isRequired
                        />
                        <InputRightElement width="3rem">
                          <IconButton
                            h="1.75rem"
                            aria-label="Password"
                            size="sm"
                            bgColor={{ base: "gray.50", sm: "gray.100" }}
                            icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                            onClick={handleClick}
                          />
                        </InputRightElement>
                      </InputGroup>
                    </FormControl>
                  </Stack>
                  <Stack mt="10px">
                    <HStack justify="space-between">
                      <Checkbox defaultChecked>Remember me</Checkbox>
                      <Text>
                        Have an account?{" "}
                        <Link color="#4810a3" pl="4px" href="/log-in">
                          Log in
                        </Link>
                      </Text>
                    </HStack>
                    <Stack spacing="6">
                      <Text color="red.500">{error}</Text>
                      <Button onClick={handleSignUp} bgColor={{ base: "#566581", sm: "gray.100" }} color={{ base: "white", sm: "gray.800" }}>
                        Sign Up
                      </Button>
                    </Stack>
                  </Stack>
                </form>
              </Stack>
            </Box>
          </Stack>
        )}
      </Container>
    </Box>
  );
};
export default UserSignUp;
