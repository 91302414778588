import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Footer from "./GuestView/Components/Footer";
import NavBar from "./GuestView/Components/NavBar";
import UserSideBarWithHeader from "./GuestView/Components/UserSideBarWithHeader";
import LandingPage from "./GuestView/Pages/LandingPage";
import PreApprovalForm from "./GuestView/Pages/PreApprovalForm";
import UserSignUp from "./GuestView/Pages/UserSignUp";
import Login from "./GuestView/Pages/Login";
import InstallerSelect from "./GuestView/Pages/InstallerSelect";
import ClientList from "./InstallerView/Pages/ClientList";
import SideBarWithHeader from "./InstallerView/Components/SideBarWithHeader";
import LenderSelect from "./GuestView/Pages/LenderSelect";
import OrderConfirmation from "./GuestView/Pages/OrderConfirmation";
import InstallerSignUp from "./InstallerView/Pages/InstallerSignUp";
import Home from "./InstallerView/Pages/Home";
import ClientProjectDetails from "./InstallerView/Pages/ClientProjectDetails";
import { useAuth } from "./Providers/AuthProvider";
import { CognitoUser } from "amazon-cognito-identity-js";
import InstallerLogin from "./InstallerView/Pages/InstallerLogin";
import Unauthorized from "./Unauthorized";
import PotentialLeads from "./InstallerView/Pages/PotentialLeads";
import ProfilePage from "./InstallerView/Pages/ProfilePage";
import HomeDetails from "./GuestView/Pages/HomeDetails";
import UserPostOrderDash from "./GuestView/Pages/UserPostOrderDash";
import UserDocumentsPage from "./GuestView/Pages/UserDocumentsPage";
import KlaraviaStudio from "./GuestView/Pages/KlaraviaStudio";
import CoverageMap from "./GuestView/Pages/CoverageMap";
import SolarCourse from "./GuestView/Pages/SolarCourse";
import BlogsIntro from "./GuestView/Pages/BlogsIntro";
import BlogShiningALightOnSolar from "./GuestView/Blogs/BlogShiningALightOnSolar";
import BlogToSolarOrNotToSolar from "./GuestView/Blogs/BlogToSolarOrNotToSolar";
import BlogTop10SolarQuestionsAnswered from "./GuestView/Blogs/BlogTop10SolarQuestionsAnswered";
import BlogUnderstandingSolarRoofTaxIncentives from "./GuestView/Blogs/BlogUnderstandingSolarRoofTaxIncentives";
import LoanApplicationProcessingMessage from "./GuestView/Components/LoanApplicationProcessingMessage";
import UserCalendar from "./GuestView/Pages/UserCalendar";
import PurchaseOrQuote from "./GuestView/Pages/PurchaseOrQuote";
import FinancialCalculator from "./GuestView/Pages/FinancialCalculator";
import BillUpload from "./GuestView/Pages/BillUpload";
import AdminDash from "./AdminView/Pages/AdminDash";
import AdminUserImageProcessing from "./AdminView/Pages/AdminUserImageProcessing";
import FinalDesign from "./GuestView/Pages/FinalDesign";
import PoorRoofCondition from "./GuestView/Pages/PoorRoofCondition";
import SupportChat from "./GuestView/Pages/SupportChat";
import ReferralClaim from "./GuestView/Pages/ReferralClaim";
import TermsOfService from "./InstallerView/Pages/TermsOfService";
import Solar101Course from "./GuestView/Pages/Solar101Course";
import KlaraviaStudioG from "./GuestView/Pages/KlaraviaStudioG";
import BlogsWillSolarIncreaseMyHomeValue from "./GuestView/Blogs/BlogsWillSolarIncreaseMyHomeValue";
import UserRoutes from "./UserRoutes";

declare module "amazon-cognito-identity-js" {
  export interface CognitoUser {
    signInUserSession?: {
      accessToken: {
        payload: {
          "cognito:groups"?: string[];
        };
      };
    };
  }
}

interface ProtectedRouteProps {
  cognitoUser: CognitoUser | null;
  allowedGroups: string[]; // groups allowed to access the route
  children: ReactNode; // the components to render if access is granted
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ cognitoUser, allowedGroups, children }): ReactElement | null => {
  const [isAuthorized, setIsAuthorized] = useState<boolean | null>(null);

  useEffect(() => {
    // Setup a debounce timer
    const timerId = setTimeout(() => {
      // Check if the user is part of the allowed groups
      if (cognitoUser && cognitoUser.signInUserSession && cognitoUser.signInUserSession.accessToken.payload["cognito:groups"]) {
        const isUserAuthorized = cognitoUser.signInUserSession.accessToken.payload["cognito:groups"].some((group: string) => allowedGroups.includes(group));
        setIsAuthorized(isUserAuthorized);
      } else {
        setIsAuthorized(false);
      }
    }, 300); // Debounce period of 300 milliseconds

    // Clear the timeout if the user changes or on component unmount
    return () => clearTimeout(timerId);
  }, [cognitoUser, allowedGroups]);

  // If we are still determining the authorization, show a loading indicator
  if (isAuthorized === null) {
    return <div>Loading...</div>;
  }

  return isAuthorized ? <>{children}</> : <Navigate to="/unauthorized" replace />;
};

const Router = () => {
  const { cognitoUser } = useAuth();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/LandingPage" replace />} />
        <Route
          path="/unauthorized"
          element={
            <>
              <NavBar />
              <Unauthorized />
              <Footer />
            </>
          }
        />
        <Route
          path="/user-sign-up"
          element={
            <>
              <NavBar />
              <UserSignUp />
              <Footer />
            </>
          }
        />
        <Route
          path="/log-in"
          element={
            <>
              <NavBar />
              <Login />
              <Footer />
            </>
          }
        />
        <Route
          path="/coverage-map"
          element={
            <>
              <NavBar />
              <CoverageMap />
              <Footer />
            </>
          }
        />
        <Route
          path="/financial-calculator"
          element={
            <>
              <NavBar />
              <FinancialCalculator />
              <Footer />
            </>
          }
        />
        <Route
          path="/blogs"
          element={
            <>
              <NavBar />
              <BlogsIntro />
              <Footer />
            </>
          }
        />
        <Route
          path="/blogs/shining-a-light-on-solar"
          element={
            <>
              <NavBar />
              <BlogShiningALightOnSolar />
              <Footer />
            </>
          }
        />
        <Route
          path="/blogs/to-solar-or-not-to-solar"
          element={
            <>
              <NavBar />
              <BlogToSolarOrNotToSolar />
              <Footer />
            </>
          }
        />
        <Route
          path="/blogs/top-10-solar-questions-answered"
          element={
            <>
              <NavBar />
              <BlogTop10SolarQuestionsAnswered />
              <Footer />
            </>
          }
        />
        <Route
          path="/blogs/understanding-solar-roof-tax-incentives"
          element={
            <>
              <NavBar />
              <BlogUnderstandingSolarRoofTaxIncentives />
              <Footer />
            </>
          }
        />
        <Route
          path="/blogs/will-solar-increase-my-home-value"
          element={
            <>
              <NavBar />
              <BlogsWillSolarIncreaseMyHomeValue />
              <Footer />
            </>
          }
        />
        <Route
          path="/solar-course"
          element={
            <>
              <NavBar />
              <Solar101Course />
              <Footer />
            </>
          }
        />
        <Route
          path="/profile-page"
          element={
            <>
              <NavBar />
              <ProfilePage />
              <Footer />
            </>
          }
        />
        <Route
          path="/LandingPage"
          element={
            <>
              <NavBar />
              <LandingPage />
              <Footer />
            </>
          }
        />
        <Route
          path="/referral-code-claim"
          element={
            <>
              <NavBar />
              <ReferralClaim />
              <Footer />
            </>
          }
        />
        <Route
          path="/terms-of-service"
          element={
            <>
              <NavBar />
              <TermsOfService />
              <Footer />
            </>
          }
        />
        <Route
          path="/klaravia-studio"
          element={
            <>
              <NavBar />
              <KlaraviaStudio />
            </>
          }
        />
        <Route
          path="/klaravia-studiog"
          element={
            <>
              <NavBar />
              <KlaraviaStudioG />
            </>
          }
        />
        <Route
          path="/purchase-or-quote"
          element={
            <>
              <NavBar />
              <PurchaseOrQuote />
              <Footer />
            </>
          }
        />
        <Route
          path="/quote"
          element={
            <>
              <NavBar />
              <PurchaseOrQuote />
              <Footer />
            </>
          }
        />
        <Route
          path="/home-details"
          element={
            <>
              <NavBar />
              <HomeDetails />
              <Footer />
            </>
          }
        />
        <Route
          path="/poor-roof-condition"
          element={
            <>
              <NavBar />
              <PoorRoofCondition />
              <Footer />
            </>
          }
        />
        <Route
          path="/installer-select"
          element={
            <>
              <NavBar />
              <InstallerSelect />
              <Footer />
            </>
          }
        />
        <Route element={<UserRoutes />}>
          <Route
            path="/bill-upload"
            element={
              <>
                <NavBar />
                <BillUpload />
                <Footer />
              </>
            }
          />
          <Route
            path="/loan-message"
            element={
              <>
                <NavBar />
                <LoanApplicationProcessingMessage />
                <Footer />
              </>
            }
          />
          <Route
            path="/user"
            element={
              <>
                <UserSideBarWithHeader>
                  <>
                    <UserPostOrderDash />
                    <Footer />
                  </>
                </UserSideBarWithHeader>
              </>
            }
          />
          <Route
            path="/user/final-design"
            element={
              <>
                <UserSideBarWithHeader>
                  <>
                    <FinalDesign />
                    <Footer />
                  </>
                </UserSideBarWithHeader>
              </>
            }
          />
          <Route
            path="/user/documents"
            element={
              <>
                <UserSideBarWithHeader>
                  <>
                    <UserDocumentsPage />
                    <Footer />
                  </>
                </UserSideBarWithHeader>
              </>
            }
          />
          <Route
            path="/user/support-chat"
            element={
              <>
                <UserSideBarWithHeader>
                  <>
                    <SupportChat />
                    <Footer />
                  </>
                </UserSideBarWithHeader>
              </>
            }
          />
          <Route
            path="/user/calendar"
            element={
              <>
                <UserSideBarWithHeader>
                  <>
                    <UserCalendar />
                    <Footer />
                  </>
                </UserSideBarWithHeader>
              </>
            }
          />
        </Route>
        <Route
          path="/pre-approval-form"
          element={
            <>
              <NavBar />
              <PreApprovalForm />
              <Footer />
            </>
          }
        />
        {/* <Route
          path="/order-details"
          element={
            <>
              <NavBar />
              <OrderConfirmation />
              <Footer />
            </>
          }
        /> */}
        <Route
          path="/lender-select"
          element={
            <>
              <NavBar />
              <LenderSelect />
              <Footer />
            </>
          }
        />
        <Route
          path="/installer/"
          element={
            <>
              <NavBar />
              <InstallerLogin />
              <Footer />
            </>
          }
        />{" "}
        <Route
          path="/installer/sign-up"
          element={
            <>
              <NavBar />
              <InstallerSignUp />
              <Footer />
            </>
          }
        />
        <Route
          path="/installer/log-in"
          element={
            <>
              <NavBar />
              <InstallerLogin />
              <Footer />
            </>
          }
        />
        <Route
          path="/installer/:installer_id/"
          element={
            <ProtectedRoute cognitoUser={cognitoUser} allowedGroups={["dev_installer"]}>
              <SideBarWithHeader>
                <>
                  <Home />
                  <Footer />
                </>
              </SideBarWithHeader>
            </ProtectedRoute>
          }
        />
        <Route
          path="/installer/:installer_id/client-list"
          element={
            <ProtectedRoute cognitoUser={cognitoUser} allowedGroups={["dev_installer"]}>
              <SideBarWithHeader>
                <>
                  <ClientList />
                  <Footer />
                </>
              </SideBarWithHeader>
            </ProtectedRoute>
          }
        />
        <Route
          path="/installer/:installer_id/potential-leads"
          element={
            <ProtectedRoute cognitoUser={cognitoUser} allowedGroups={["dev_installer"]}>
              <SideBarWithHeader>
                <>
                  <PotentialLeads />
                  <Footer />
                </>
              </SideBarWithHeader>
            </ProtectedRoute>
          }
        />
        <Route
          path="/installer/:installer_id/client_project_details/:lead_id"
          element={
            <ProtectedRoute cognitoUser={cognitoUser} allowedGroups={["dev_installer"]}>
              <SideBarWithHeader>
                <>
                  <ClientProjectDetails />
                  <Footer />
                </>
              </SideBarWithHeader>
            </ProtectedRoute>
          }
        />
        <Route path="/admin" element={<AdminDash />} />
        <Route path="/admin-user-images" element={<AdminUserImageProcessing />} />
        <Route path="*" element={<Navigate to="/LandingPage" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
