import React, { useState } from "react";
import { Box, VStack, Heading, Text, Slider, SliderTrack, SliderFilledTrack, SliderThumb, SliderMark } from "@chakra-ui/react";

const LifetimeSavingsComponent = () => {
  const [monthlyBill, setMonthlyBill] = useState(100);

  // Constants
  const ELECTRICITY_PRICE = 0.14; // per kWh
  const SOLAR_COST_PER_WATT = 2.6;
  const SOLAR_PRODUCTION_PER_DAY = 2.5; // kWh per kW of solar panels
  const SYSTEM_LIFETIME = 25; // years
  const FEDERAL_TAX_CREDIT = 0.6; // 30% federal tax credit
  const ANNUAL_ELECTRICITY_PRICE_INCREASE = 0.045; // 2% annual increase

  const calculateSolarMetrics = (monthlyBill: number) => {
    const monthlyUsage = monthlyBill / ELECTRICITY_PRICE;
    const dailyUsage = monthlyUsage / 30;
    const requiredCapacity = dailyUsage / SOLAR_PRODUCTION_PER_DAY;
    const installationCost = requiredCapacity * 1000 * SOLAR_COST_PER_WATT;
    const costAfterIncentives = installationCost * FEDERAL_TAX_CREDIT;

    // Calculate lifetime energy cost with annual price increases
    let lifetimeEnergyCost = 0;
    let yearlyBill = monthlyBill * 12;
    for (let year = 0; year < SYSTEM_LIFETIME; year++) {
      lifetimeEnergyCost += yearlyBill;
      yearlyBill *= 1 + ANNUAL_ELECTRICITY_PRICE_INCREASE;
    }

    const lifetimeSavings = lifetimeEnergyCost - costAfterIncentives;

    return {
      installationCost: Math.round(costAfterIncentives),
      lifetimeSavings: Math.round(lifetimeSavings),
      lifetimeEnergyCost: Math.round(lifetimeEnergyCost),
    };
  };

  const { installationCost, lifetimeSavings, lifetimeEnergyCost } = calculateSolarMetrics(monthlyBill);

  return (
    <Box w="full" margin="auto" p={8} borderRadius="lg" fontFamily="'eurostile', sans-serif">
      <VStack spacing={6} align="stretch">
        <Heading size="lg" fontFamily="'eurostile', sans-serif" color="#566581">
          Calculate your savings
        </Heading>

        <Box>
          <Text mb={2}>
            Current Monthly Electric Bill: <b>${monthlyBill}</b>
          </Text>
          <Slider aria-label="slider-ex-1" colorScheme="green" defaultValue={100} min={0} max={500} step={10} onChange={(value) => setMonthlyBill(value)}>
            <SliderMark value={0} mt={4} ml={0} fontSize="sm">
              $0
            </SliderMark>
            <SliderMark value={250} mt={4} ml={-5} fontSize="sm">
              $250
            </SliderMark>
            <SliderMark value={500} mt={4} ml={-9} fontSize="sm">
              $500
            </SliderMark>
            <SliderTrack h="10px">
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb w="24px" h="24px" border="2px solid #48BB78" />
          </Slider>
        </Box>

        {/* <Box bg="red.50" mt={4} p={4} borderRadius="md">
          <Text fontWeight="bold">Estimated Lifetime Energy Cost:</Text>
          <Box w="full" display="flex" justifyContent="flex-end">
            <Text as="span" fontSize="32px" fontWeight="bold" color="red.600">
              ${lifetimeEnergyCost.toLocaleString()}
            </Text>
          </Box>
        </Box> */}

        <Box bg="blue.100" p={4} borderRadius="md" mt={4}>
          <Text fontWeight="bold" color="#566581">
            Estimated Solar Installation Cost{" "}
            <Text fontSize="10px" color="gray.600">
              (after incentives):
            </Text>
          </Text>
          <Box w="full" display="flex" justifyContent="flex-end">
            <Text as="span" fontWeight="bold" fontSize="24px" color="blue.600">
              ${installationCost.toLocaleString()}
            </Text>
          </Box>
        </Box>

        <Box bg="green.100" p={4} borderRadius="md">
          <Text fontWeight="bold" color="#566581">
            Estimated Lifetime Energy Savings:
          </Text>
          <Box w="full" display="flex" justifyContent="flex-end">
            <Text as="span" fontSize="32px" fontWeight="bold" color="green.600">
              ${lifetimeSavings.toLocaleString()}
            </Text>
          </Box>
        </Box>

        <Text fontSize="sm">
          *Calculations assume a {2.5}% annual increase in electricity prices and a 2% annual inflation increase over {SYSTEM_LIFETIME} years.
        </Text>
      </VStack>
    </Box>
  );
};

export default LifetimeSavingsComponent;
