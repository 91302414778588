import React, { useEffect, useRef, useState } from "react";
import { FormControl, FormErrorMessage, InputGroup, Input, useToast } from "@chakra-ui/react";

interface AddressSearchBarProps {
  onSubmit: (location: { long_name: string; state: string; county: string; city: string; street: string; postal_code: string; lat: number; lng: number }) => Promise<void>;
}

const serviceableStates = ["AZ"];

const AddressSearchBar: React.FC<AddressSearchBarProps> = ({ onSubmit }) => {
  const [address, setAddress] = useState<string>("");
  const [error, setError] = useState<string>("");
  const autocompleteRef = useRef<HTMLInputElement>(null);
  const autocompleteService = useRef<google.maps.places.Autocomplete>();
  const toast = useToast();

  useEffect(() => {
    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.Autocomplete(autocompleteRef.current as HTMLInputElement, {
        types: ["geocode"],
        componentRestrictions: { country: "us" },
      });
      autocompleteService.current.addListener("place_changed", onPlaceChanged);
    }
  }, []);

  const onPlaceChanged = () => {
    if (autocompleteService.current) {
      const place = autocompleteService.current.getPlace();

      // Ensure place and address_components are defined
      if (place?.address_components) {
        // Safely find the state component, assuming place and address_components are not undefined
        const stateComponent = place.address_components.find((component) => component.types.includes("administrative_area_level_1"));
        const state = stateComponent?.short_name;

        if (state && !serviceableStates.includes(state)) {
          return toast({
            title: "State not serviceable",
            description: "We currently service Arizona exclusively",
            status: "warning",
            duration: 9000,
            isClosable: true,
          });
        }

        setAddress(place.formatted_address || "");

        if (place.geometry?.location) {
          onSubmit({
            long_name: place.formatted_address || "",
            street: place.address_components.find((component) => component.types.includes("route"))?.long_name || "",
            city: place.address_components.find((component) => component.types.includes("locality"))?.long_name || "",
            postal_code: place.address_components.find((component) => component.types.includes("postal_code"))?.long_name || "",
            county: place.address_components.find((component) => component.types.includes("administrative_area_level_2"))?.long_name || "",
            state: place.address_components.find((component) => component.types.includes("administrative_area_level_1"))?.long_name || "",
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          }).catch(() => {
            toast({
              title: "Whoops..",
              description: "We encountered an issue while looking up this address.",
              status: "error",
              duration: 9000,
              isClosable: true,
            });
          }); // Handle the promise rejection here if needed
        }
      } else {
        toast({
          title: "Whoops..",
          description: "We encountered an issue while looking up this address.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(e.target.value);
  };

  const hasError = Boolean(error);

  return (
    <FormControl isInvalid={hasError} isRequired>
      <InputGroup>
        <Input
          h="60px"
          id="address"
          type="text"
          variant="unstyled"
          bgColor="rgba(255,255,255,0.9)"
          border="1px solid black"
          fontFamily="'eurostile', sans-serif"
          pl="8px"
          shadow="lg"
          value={address}
          onChange={handleChange}
          placeholder="Enter your address"
          ref={autocompleteRef}
          isRequired
        />
      </InputGroup>
      {hasError && <FormErrorMessage fontFamily="'eurostile', sans-serif">{error}</FormErrorMessage>}
    </FormControl>
  );
};

export default AddressSearchBar;
