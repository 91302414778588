import { VStack, Text, Button, Fade } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const LoanApplicationProcessingMessage = () => {
  const navigate = useNavigate();

  return (
    <VStack w="full" h="100vh" pt="80px" bg="#f4eee9" fontFamily="'eurostile', sans-serif">
      <Fade in={true} transition={{ enter: { duration: 0.8 } }}>
        <VStack h="80vh" maxW="90vw" justifyContent="center" textAlign={{ base: "center" }}>
          <Text fontSize={{ base: "22px", lg: "32px" }} fontWeight={{ base: "bold", lg: 600 }}>
            We'll get to work reviewing your electric bill
          </Text>
          <Text fontSize={{ base: "14px", lg: "16px" }}>In the mean time, you can view the status of your project in your customer portal</Text>
          <Button mt="20px" bgColor="black" color="white" _hover={{ bgColor: "rgba(0,0,0,.8)" }} onClick={() => navigate(`/user`)}>
            Continue to Customer Portal
          </Button>
        </VStack>
      </Fade>
    </VStack>
  );
};

export default LoanApplicationProcessingMessage;
