import React, { useState, useEffect } from "react";
import { Box, Button, HStack, Image, Input, Text, useNumberInput, VStack, Icon, FormControl, FormLabel, Switch, Divider } from "@chakra-ui/react";
import "./KlaraviaStudio.css";
import { useNavigate } from "react-router-dom";
import carbonFactory from "../Assets/carbonFactory.svg";
import tree from "../Assets/tree.svg";
import { UserWithoutTypeName, useUserDataContext } from "../../Providers/LeadDataProvider";
import { ArrowRightIcon } from "@chakra-ui/icons";
import SavingsBreakdown from "../Components/SavingsBreakdown";
import { motion } from "framer-motion";
import { useGetUser } from "../../hooks/userHooks";
import { User } from "../../API";
import logoSpinner from "../../Assets/logo.svg";
import { estimateSolarSystemSize } from "../utils/systemSizeEstimator";

const MotionIcon = motion(Icon);

function getTotalAnnualProductionByMonth(data: any) {
  // Initialize an array of 12 elements to store the sum of production for each month.
  const totalMonthlyProduction = new Array(12).fill(0);

  // Iterate over each panel in the energyTable.
  if (data) {
    data.energyTable.forEach((panel: any) => {
      //@ts-ignore
      panel["Panels Monthly Production"].forEach((monthlyProduction, index) => {
        // Add the monthly production of the current panel to the total.
        totalMonthlyProduction[index] += monthlyProduction;
      });
    });
  }

  return totalMonthlyProduction;
}

const KlaraviaStudioG = () => {
  const navigate = useNavigate();
  const { userData, setUserData } = useUserDataContext();
  const [optimizedOutput, setOptimizedOutput] = useState<any>(null);
  const [selectedNumPanels, setSelectedNumPanels] = useState<number>(-1);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [queriedUserDataState, setQueriedUserDataState] = useState<User | null | undefined>();
  const [requiresBattery, setRequiresBattery] = useState<boolean>(false);
  const [fallbackData, setFallbackData] = useState<any>();

  useEffect(() => {
    if (userData && userData.estimatedAnnualUsage) {
      setFallbackData(estimateSolarSystemSize(userData.estimatedAnnualUsage / 12));
    }
  }, [userData]);

  const totalProductionByMonth = getTotalAnnualProductionByMonth(optimizedOutput);
  const userId = localStorage.getItem("userId");
  const { data: queriedUserData, loading, error } = useGetUser({ variables: { id: userId } });

  useEffect(() => {
    if (queriedUserData && queriedUserData.getUser) {
      setQueriedUserDataState(queriedUserData.getUser as User);
    }
  }, [queriedUserData]);

  useEffect(() => {
    if (selectedNumPanels !== -1 && selectedNumPanels > 0) {
      setFallbackData(estimateSolarSystemSize(userData.estimatedAnnualUsage / 12, selectedNumPanels));
    }
  }, [selectedNumPanels]);

  useEffect(() => {
    if (userData && userData.requires_battery === true) {
      setRequiresBattery(true);
    }
  }, []);

  if (loading) {
    return (
      <VStack w="full" minH="100vh" p={{ base: "20px", lg: "40px" }} pt="80px" bg="#f4eee9" fontFamily="'eurostile', sans-serif" justifyContent="center">
        <VStack w="full" h="90vh" justifyContent="center">
          <Text w="full" textAlign="center" fontSize={{ base: "20px", lg: "32px" }}>
            Calculating the size of your solar system.
          </Text>
          <Text mb="20px">This may take a few minutes...</Text>
          <Image src={logoSpinner} w="60px" alt="cannot load loading component" className="spinner" />
        </VStack>
      </VStack>
    );
  }

  if (!fallbackData) {
    return (
      <VStack w="full" minH="100vh" p={{ base: "20px", lg: "40px" }} pt="80px" bg="#f4eee9" fontFamily="'eurostile', sans-serif" justifyContent="center">
        <Text mt="100px" fontSize="26px">
          Sorry, we weren't able to process this address. Please try again later.
        </Text>
        <Button
          w="120px"
          h="40px"
          mt="20px"
          bgColor="black"
          color="white"
          _hover={{ bgColor: "rgba(0,0,0,.7)" }}
          onClick={() => {
            navigate("/LandingPage");
          }}
        >
          Back
        </Button>
      </VStack>
    );
  }

  if (
    (!userData ||
      !userData.google_solar_object ||
      !userData.google_solar_object.solarPotential ||
      !userData.google_solar_object.solarPotential.solarPanelConfigs ||
      !userData.estimatedAnnualUsage) &&
    !fallbackData
  ) {
    return (
      <VStack w="full" minH="100vh" p={{ base: "20px", lg: "40px" }} pt="80px" bg="#f4eee9" fontFamily="'eurostile', sans-serif" justifyContent="center">
        <Text mt="100px" fontSize="26px">
          Sorry, we weren't able to find this address.
        </Text>
        <Button
          w="120px"
          h="40px"
          mt="20px"
          bgColor="black"
          color="white"
          _hover={{ bgColor: "rgba(0,0,0,.7)" }}
          onClick={() => {
            navigate("/LandingPage");
          }}
        >
          Back
        </Button>
      </VStack>
    );
  }

  const requiredPanelsConfig =
    selectedNumPanels === -1
      ? userData?.google_solar_object?.solarPotential?.solarPanelConfigs
          .filter((config: any) => {
            return config.yearlyEnergyDcKwh > Number(userData?.estimatedAnnualUsage);
          })
          .sort()[0] || userData?.google_solar_object?.solarPotential?.solarPanelConfigs?.[userData?.google_solar_object?.solarPotential?.solarPanelConfigs?.length - 1]
      : userData?.google_solar_object?.solarPotential?.solarPanelConfigs
          .filter((config: any) => {
            return config.panelsCount >= selectedNumPanels;
          })
          .sort()[0];
  const systemSize = ((requiredPanelsConfig?.panelsCount || fallbackData.numberOfPanels) * 400) / 1000;

  const handleBatterySelection = (e: { target: { checked: boolean | ((prevState: boolean) => boolean) } }) => {
    setRequiresBattery(e.target.checked);
  };

  const handleProceedToInstallerSelect = () => {
    //@ts-ignore
    setUserData((prevUserData: UserWithoutTypeName) => {
      if (
        JSON.stringify(prevUserData) !==
        JSON.stringify({
          ...prevUserData,
          google_system_size: systemSize ? systemSize : fallbackData.systemSizeKW,
          requires_battery: requiresBattery,
        })
      ) {
        return {
          ...prevUserData,
          google_system_size: systemSize ? systemSize : fallbackData.systemSizeKW,
          requires_battery: requiresBattery,
        };
      }
      return prevUserData;
    });
    navigate("/installer-select");
  };

  return (
    <>
      {errorMessage || !userData || userData === undefined ? (
        <Box w="full" bgColor="#f4eee9">
          <VStack w="full" h="90vh" justifyContent="center">
            <Text fontSize="40px">Whoops ...</Text>
            <Text mb="20px">{errorMessage || "Cannot load your data"}</Text>
            <Button
              w="120px"
              h="40px"
              bgColor="black"
              color="white"
              _hover={{ bgColor: "rgba(0,0,0,.7)" }}
              onClick={() => {
                navigate("/LandingPage");
              }}
            >
              Back
            </Button>
          </VStack>
        </Box>
      ) : (
        <>
          <Box w="full" minH="100vh" p={{ base: "20px", lg: "40px" }} pt="80px" bg="#f4eee9" fontFamily="'eurostile', sans-serif">
            <HStack
              w="full"
              justifyContent="center"
              gap="40px"
              pt={{ base: "40px", lg: "80px" }}
              flexDirection={{ base: "column", lg: "row" }}
              alignItems={{ base: "center", lg: "flex-start" }}
            >
              <VStack w={{ base: "full", lg: "60vw" }} pr="0px" paddingTop="0px" spacing="20px">
                <VStack w="full">
                  <Text w="full" fontSize={{ base: "22px", lg: "32px" }} fontWeight="bold">
                    Customize your system
                  </Text>
                  <Text w="full" mb="20px" fontSize={{ base: "18px", md: "20px" }}>
                    Using the information you provided, we've put together a system estimate. Feel free to customize it to suit your needs.
                  </Text>
                </VStack>
                <VStack w="full" bgColor="white" borderRadius="10px" p="20px" shadow="lg">
                  <Text w="full" fontSize={{ base: "18px", lg: "20px" }} fontWeight="bold">
                    System Specs
                  </Text>
                  <Divider w="full" borderColor="gray" />

                  <VStack w="full" mt="10px" alignItems="space-between" spacing="10px">
                    <VStack w="full" spacing="0px">
                      <HStack w="full" justifyContent="space-between">
                        <Text fontSize="18px" fontWeight="bold">
                          Annual Solar Production:
                        </Text>
                        <HStack>
                          <Text fontWeight="bold" fontSize="22px">
                            {requiredPanelsConfig
                              ? new Intl.NumberFormat().format(Math.floor(Number(requiredPanelsConfig?.yearlyEnergyDcKwh)))
                              : new Intl.NumberFormat().format(fallbackData.annualKWhOutput)}
                          </Text>
                          <Text>kWh</Text>
                        </HStack>
                      </HStack>
                      <HStack w="full" justifyContent="space-between">
                        <Text fontSize="16px" color="gray.500">
                          Estimated Current Usage:
                        </Text>
                        <HStack>
                          <Text fontSize={{ base: "12px", lg: "16px" }} color="gray.500">
                            {queriedUserDataState
                              ? new Intl.NumberFormat().format(Math.round(Number(queriedUserDataState.estimatedAnnualUsage)))
                              : new Intl.NumberFormat().format(Math.round(Number(userData.estimatedAnnualUsage)))}
                          </Text>
                          <Text color="gray.500" fontSize={{ base: "12px", lg: "16px" }}>
                            kWh
                          </Text>
                        </HStack>
                      </HStack>
                    </VStack>
                    <HStack w="full" justifyContent="space-between">
                      <Text fontSize="18px" fontWeight="bold">
                        Number of Panels:
                      </Text>
                      <HStack>
                        <Text fontWeight="bold" fontSize="22px">
                          {requiredPanelsConfig ? requiredPanelsConfig?.panelsCount : fallbackData.numberOfPanels}
                        </Text>
                        <Text>Panels</Text>
                      </HStack>
                    </HStack>
                    <HStack w="full" justifyContent="space-between">
                      <Text fontSize="18px" fontWeight="bold">
                        System size:
                      </Text>
                      <HStack>
                        <Text fontWeight="bold" fontSize="22px">
                          {systemSize ? systemSize.toFixed(2) : fallbackData.systemSizeKW}
                        </Text>
                        <Text>kW</Text>
                      </HStack>
                    </HStack>
                  </VStack>
                </VStack>
                <VStack w="full" bgColor="white" borderRadius="10px" p="20px" shadow="lg">
                  <Text w="full" fontWeight="bold" fontSize="26px" display="inline-flex" alignItems="flex-end" lineHeight={1}>
                    <Text fontSize="32px" mr="10px">
                      1.
                    </Text>{" "}
                    Energy Needs
                  </Text>
                  {/* <Text>What percentage of your current energy needs will be met by your system?</Text> */}
                  <VStack w={{ base: "full", lg: "80%" }}>
                    <Box w="full">
                      <HStack
                        w={`${
                          requiredPanelsConfig && queriedUserDataState
                            ? (100 * Number(requiredPanelsConfig?.yearlyEnergyDcKwh)) / (Number(queriedUserDataState.estimatedAnnualUsage) * 1.5)
                            : (100 * Number(fallbackData.annualKWhOutput)) / (Number(userData.estimatedAnnualUsage) * 1.5)
                        }%`}
                        maxW={{ base: `${window.innerWidth - 80}px`, lg: "full" }}
                        minW="80px"
                        h="40px"
                        pl="20px"
                        pr="20px"
                        transition="width 0.5s ease"
                        bgColor={
                          requiredPanelsConfig && queriedUserDataState
                            ? (100 * Number(requiredPanelsConfig?.yearlyEnergyDcKwh)) / Number(queriedUserDataState.estimatedAnnualUsage) < 50
                              ? "red.400"
                              : "#48BB78"
                            : (100 * Number(fallbackData.annualKWhOutput)) / Number(userData.estimatedAnnualUsage) < 50
                            ? "red.400"
                            : "#48BB78"
                        }
                        border="2px solid"
                        borderColor={
                          requiredPanelsConfig && queriedUserDataState
                            ? (100 * Number(requiredPanelsConfig?.yearlyEnergyDcKwh)) / Number(queriedUserDataState.estimatedAnnualUsage) < 50
                              ? "red.400"
                              : "#48BB78"
                            : (100 * Number(fallbackData.annualKWhOutput)) / Number(userData.estimatedAnnualUsage) < 50
                            ? "red.400"
                            : "#48BB78"
                        }
                        borderRadius="20px"
                        justifyContent="space-between"
                        position="relative"
                        top="30px"
                        zIndex={1}
                      >
                        <Text w="full" minW="20px" maxW={{ base: `${window.innerWidth - 80}px`, lg: "full" }} textAlign="right" color="white" fontWeight="bold" fontSize="20px">
                          {requiredPanelsConfig && queriedUserDataState
                            ? `${Math.floor((100 * Number(requiredPanelsConfig?.yearlyEnergyDcKwh)) / Number(queriedUserDataState.estimatedAnnualUsage))}%`
                            : `${Math.floor((100 * Number(fallbackData.annualKWhOutput)) / Number(userData.estimatedAnnualUsage))}%`}
                        </Text>
                      </HStack>
                      <HStack w="full" h="40px" bgColor="gray.300" borderRadius="20px" justifyContent="space-evenly" position="relative" bottom="10px">
                        <Text fontWeight="bold">25%</Text>
                        <Text fontWeight="bold">50%</Text>
                        <Text fontWeight="bold">75%</Text>
                        <Text fontWeight="bold">100%</Text>
                        <Text fontWeight="bold">125%</Text>
                        <Text fontWeight="bold">150%</Text>
                      </HStack>
                    </Box>
                  </VStack>
                  {queriedUserDataState ? (
                    (100 * Number(requiredPanelsConfig?.yearlyEnergyDcKwh)) / Number(queriedUserDataState.estimatedAnnualUsage) > 100 ? (
                      <Text mt="20px">
                        {`This system will meet ${Math.floor(
                          (100 * Number(requiredPanelsConfig?.yearlyEnergyDcKwh)) / Number(queriedUserDataState.estimatedAnnualUsage)
                        )}% of your expected energy needs. This means that you will produce a little bit more energy than you require on average.`}
                      </Text>
                    ) : (
                      <Text mt="20px">
                        {`This system will meet ${Math.floor(
                          (100 * Number(requiredPanelsConfig?.yearlyEnergyDcKwh)) / Number(queriedUserDataState.estimatedAnnualUsage)
                        )}% of your expected energy needs. This means that you will produce a little bit less energy than you require on average. The remaining energy will still be purchased by your utility provider.`}
                      </Text>
                    )
                  ) : (100 * Number(fallbackData.annualKWhOutput)) / Number(userData.estimatedAnnualUsage) > 100 ? (
                    <Text mt="20px">
                      {`This system will meet ${Math.floor(
                        (100 * Number(fallbackData.annualKWhOutput)) / Number(userData.estimatedAnnualUsage)
                      )}% of your expected energy needs. This means that you will produce a little bit more energy than you require on average.`}
                    </Text>
                  ) : (
                    <Text mt="20px">
                      {`This system will meet ${Math.floor(
                        (100 * Number(fallbackData.annualKWhOutput)) / Number(userData.estimatedAnnualUsage)
                      )}% of your expected energy needs. This means that you will produce a little bit less energy than you require on average. The remaining energy will still be purchased by your utility provider.`}
                    </Text>
                  )}
                </VStack>
                {(requiredPanelsConfig || fallbackData) && (
                  <VStack w="full" bgColor="white" borderRadius="10px" p="20px" shadow="lg">
                    <Text w="full" fontWeight="bold" fontSize="26px" display="inline-flex" alignItems="flex-end" lineHeight={1}>
                      <Text fontSize="32px" mr="10px">
                        2.
                      </Text>{" "}
                      Customize your build
                    </Text>
                    <VStack w="80%" mt="20px" mb="20px" alignItems="space-between">
                      <HStack w="full" justifyContent="space-between" mb="20px">
                        <Text fontSize="18px" fontWeight="bold">
                          Number of panels:
                        </Text>
                        <HStack>
                          <PanelCounter
                            initialPanelCount={requiredPanelsConfig ? Number(requiredPanelsConfig?.panelsCount) : fallbackData.numberOfPanels}
                            setSelectedNumPanels={setSelectedNumPanels}
                            numSystemPanelsConfigs={
                              userData && requiredPanelsConfig
                                ? userData?.google_solar_object?.solarPotential?.solarPanelConfigs?.[userData?.google_solar_object?.solarPotential.solarPanelConfigs?.length - 1]
                                    ?.panelsCount || 2
                                : 100
                            }
                          />
                        </HStack>
                      </HStack>
                      <FormControl display="flex" alignItems="center" justifyContent="space-between">
                        <FormLabel fontSize="18px" htmlFor="battery-selection" mb="0" fontWeight="bold">
                          Add a Battery
                        </FormLabel>
                        <Switch id="battery-selection" size="lg" colorScheme="green" defaultChecked={requiresBattery} onChange={handleBatterySelection} />
                      </FormControl>
                    </VStack>
                    {/* <Chart options={chartOptions} series={series} type="area" width="600" /> */}
                  </VStack>
                )}
                <SavingsBreakdown
                  estimatedUsage={queriedUserDataState ? Number(queriedUserDataState.estimatedAnnualUsage) : userData.estimatedAnnualUsage}
                  estimatedSystemSize={systemSize ? Number(systemSize) : fallbackData.annualKWhOutput}
                  requiresBattery={requiresBattery}
                />
                <VStack shadow="lg" bgColor="white" p="20px" borderRadius="12px" w="full" alignItems="center" gap={0} spacing={0}>
                  <Text w="full" fontWeight="bold" fontSize="26px">
                    4. Your environmental impact
                  </Text>
                  <Text w="full" fontSize="12px" color="gray.500">
                    over 25 years
                  </Text>
                  <HStack justifyContent="space-between">
                    <VStack bgColor="white" borderRadius="12px" p="40px">
                      <Image w={{ base: "120px", md: "180px" }} h={{ base: "100px", md: "160px" }} src={carbonFactory} />
                      <Text lineHeight={1} fontWeight="bold" fontSize="24px" color="black" mt="20px">
                        {requiredPanelsConfig
                          ? new Intl.NumberFormat().format(Math.round(Number(requiredPanelsConfig?.yearlyEnergyDcKwh) * 0.846 * 25))
                          : new Intl.NumberFormat().format(Math.round(Number(fallbackData.annualKWhOutput) * 0.846 * 25))}
                      </Text>
                      <Text lineHeight={1} color="gray.600" fontSize={{ base: "14px", md: "18px" }}>
                        Pounds of carbon
                      </Text>
                    </VStack>
                    <Text fontSize="30px" fontWeight="bold" ml={{ base: "0px", md: "20px" }} mr={{ base: "0px", md: "20px" }}>
                      =
                    </Text>
                    <VStack bgColor="white" borderRadius="12px" p="40px">
                      <Image w={{ base: "100px", md: "160px" }} h={{ base: "100px", md: "160px" }} src={tree} />
                      <Text lineHeight={1} fontWeight="bold" fontSize="24px" color="black" mt="20px">
                        {requiredPanelsConfig
                          ? new Intl.NumberFormat().format(Math.round((Number(requiredPanelsConfig?.yearlyEnergyDcKwh) * 0.846 * 25) / 48))
                          : new Intl.NumberFormat().format(Math.round((Number(fallbackData.annualKWhOutput) * 0.846 * 25) / 48))}
                      </Text>
                      <Text lineHeight={1} color="gray.600" fontSize={{ base: "14px", md: "18px" }}>
                        Trees
                      </Text>
                    </VStack>
                  </HStack>
                </VStack>
                <VStack w="full" position="sticky" bottom="0px" gap="0px" zIndex={10}>
                  <Box
                    w="full"
                    h="180px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    pt="20px"
                    bg="linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(244,238,233,1) 30%)"
                  >
                    <Button
                      w="full"
                      h="60px"
                      fontSize="20px"
                      onClick={handleProceedToInstallerSelect}
                      bgColor="#141414"
                      color="white"
                      _hover={{ bgColor: "blackAlpha.800" }}
                      rightIcon={<ArrowRightIcon w="16px" h="16px" />}
                    >
                      Continue
                    </Button>
                  </Box>
                </VStack>
              </VStack>
            </HStack>
          </Box>
          {/* ) : (
            <Box w="full" minH="100vh" bg="#f4eee9">
              <VStack w="full" h="90vh" justifyContent="center">
                <Text w="full" textAlign="center" fontSize={{ base: "20px", lg: "40px" }}>
                  Creating your initial design
                </Text>
                <Text mb="20px">This may take a few minutes...</Text>
                <Image src={logoSpinner} w="60px" alt="cannot load loading component" className="spinner" />
              </VStack>
            </Box>
          )} */}
        </>
      )}
    </>
  );
};
export default KlaraviaStudioG;

function PanelCounter({
  initialPanelCount,
  setSelectedNumPanels,
  numSystemPanelsConfigs,
}: {
  initialPanelCount: number;
  setSelectedNumPanels: (num: number) => void;
  numSystemPanelsConfigs: number;
}) {
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps, valueAsNumber } = useNumberInput({
    step: 1,
    defaultValue: initialPanelCount,
    min: 1,
    max: numSystemPanelsConfigs,
    precision: 0,
  });

  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();

  const handleValueChange = () => {
    if (valueAsNumber > 1 && valueAsNumber + 1 < numSystemPanelsConfigs) {
      setSelectedNumPanels(valueAsNumber);
    }
  };

  return (
    <HStack maxW="320px" border="1px solid black" borderRadius="8px" gap="0px">
      <Button
        variant="ghost"
        _hover={{ borderLeftRadius: "8px", borderRightRadius: "0px", bgColor: "gray.100" }}
        borderRadius="12px"
        {...dec}
        pt="17px"
        display="flex"
        alignItems="flex-start"
        isDisabled={valueAsNumber <= 1}
        onClick={handleValueChange}
      >
        <Text fontSize="34px" lineHeight={0}>
          -
        </Text>
      </Button>
      <Input
        maxW="40px"
        textAlign="center"
        border="none"
        borderRadius="0px"
        p="0px"
        fontWeight="bold"
        pointerEvents="none"
        opacity={1}
        cursor="not-allowed"
        borderRight="1px solid gray"
        borderLeft="1px solid gray"
        {...input}
      />
      <Button
        maxW="20px"
        borderRadius="12px"
        p="0px"
        variant="ghost"
        _hover={{ borderLeftRadius: "0px", borderRightRadius: "8px", bgColor: "gray.100" }}
        {...inc}
        fontSize="24px"
        isDisabled={valueAsNumber + 1 > numSystemPanelsConfigs}
        onClick={handleValueChange}
      >
        <Text fontSize="24px" lineHeight={0}>
          +{" "}
        </Text>
      </Button>
    </HStack>
  );
}

const SafeReturn = ({ children }: { children: JSX.Element }): JSX.Element => {
  return <>{children}</>;
};
