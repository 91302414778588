import React from 'react';
import { Box, VStack, Text } from '@chakra-ui/react';
import '../../output.css'
import './additionalCalendarStyling.css'

const Calendar = ({ events }) => {
  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
  const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();

  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const getEventsForDay = (day) => {
    return events.filter(event => {
      const eventDate = new Date(event.date);
      return eventDate.getDate() === day && 
             eventDate.getMonth() === currentMonth && 
             eventDate.getFullYear() === currentYear;
    });
  };

  const generateCalendarDays = () => {
    let days = [];
    let day = 1;

    for (let i = 0; i < 6; i++) {
      let week = [];
      for (let j = 0; j < 7; j++) {
        if (i === 0 && j < firstDayOfMonth) {
          week.push(<td key={`empty-${j}`} className="border p-1 h-40 xl:w-40 lg:w-30 md:w-30 sm:w-20 w-10 overflow-auto transition cursor-pointer duration-500 ease hover:bg-gray-300"></td>);
        } else if (day > daysInMonth) {
          break;
        } else {
          const isCurrentDay = day === currentDay;
          const dayEvents = getEventsForDay(day);
          week.push(
            <td key={`day-${day}`} className="border p-1 h-40 xl:w-40 lg:w-30 md:w-30 sm:w-20 w-10 overflow-auto transition cursor-pointer duration-500 ease hover:bg-gray-300">
              <div className="flex flex-col h-40 mx-auto xl:w-40 lg:w-30 md:w-30 sm:w-full w-10 mx-auto overflow-hidden">
                <div className="top h-5 w-full">
                  <span className={`text-gray-500 ${isCurrentDay ? 'bg-blue-400 text-white rounded-full px-2 py-1 zi' : ''}`}>
                    {day}
                  </span>
                </div>
                <div className="bottom flex-grow h-30 py-1 w-full cursor-pointer overflow-y-auto">
                  {dayEvents.map((event, index) => (
                    <div key={index} className="event bg-purple-400 text-white rounded p-1 text-sm mb-1">
                      <span className="event-name">{event.title}</span>
                    </div>
                  ))}
                </div>
              </div>
            </td>
          );
          day++;
        }
      }
      days.push(<tr key={`week-${i}`} className="text-center h-20">{week}</tr>);
      if (day > daysInMonth) break;
    }
    return days;
  };

  return (
    <Box w='full' minH='90vh'>
      <VStack w='full' bg="#f4eee9">
        <Text fontFamily="'Eurostile', sans-serif" fontSize='30px' fontWeight='bold' mt='20px'>Install Schedule</Text>
        <VStack maxW='80vw' mb='80px'>
          <div className="container mx-auto mt-10">
            <div className="wrapper rounded w-full ">
              <div className="header border flex justify-between p-2">
                <span className="text-lg font-bold">
                  {monthNames[currentMonth]} {currentYear}
                </span>
                <div className="buttons">
                  <button className="p-1">
                    <svg width="1em" fill="gray" height="1em" viewBox="0 0 16 16" className="bi bi-arrow-left-circle" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path fillRule="evenodd" d="M8.354 11.354a.5.5 0 0 0 0-.708L5.707 8l2.647-2.646a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708 0z"/>
                      <path fillRule="evenodd" d="M11.5 8a.5.5 0 0 0-.5-.5H6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 .5-.5z"/>
                    </svg>
                  </button>
                  <button className="p-1">
                    <svg width="1em" fill="gray" height="1em" viewBox="0 0 16 16" className="bi bi-arrow-right-circle" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path fillRule="evenodd" d="M7.646 11.354a.5.5 0 0 1 0-.708L10.293 8 7.646 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0z"/>
                      <path fillRule="evenodd" d="M4.5 8a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5z"/>
                    </svg>
                  </button>
                </div>
              </div>
              <table className="w-full">
                <thead>
                  <tr>
                    <th className="p-2 border-x h-10 xl:w-40 lg:w-30 md:w-30 sm:w-20 w-10 xl:text-sm text-xs">
                      <span className="xl:block lg:block md:block sm:block hidden">Sunday</span>
                      <span className="xl:hidden lg:hidden md:hidden sm:hidden block">Sun</span>
                    </th>
                    <th className="p-2 border-r h-10 xl:w-40 lg:w-30 md:w-30 sm:w-20 w-10 xl:text-sm text-xs">
                      <span className="xl:block lg:block md:block sm:block hidden">Monday</span>
                      <span className="xl:hidden lg:hidden md:hidden sm:hidden block">Mon</span>
                    </th>
                    <th className="p-2 border-r h-10 xl:w-40 lg:w-30 md:w-30 sm:w-20 w-10 xl:text-sm text-xs">
                      <span className="xl:block lg:block md:block sm:block hidden">Tuesday</span>
                      <span className="xl:hidden lg:hidden md:hidden sm:hidden block">Tue</span>
                    </th>
                    <th className="p-2 border-r h-10 xl:w-40 lg:w-30 md:w-30 sm:w-20 w-10 xl:text-sm text-xs">
                      <span className="xl:block lg:block md:block sm:block hidden">Wednesday</span>
                      <span className="xl:hidden lg:hidden md:hidden sm:hidden block">Wed</span>
                    </th>
                    <th className="p-2 border-r h-10 xl:w-40 lg:w-30 md:w-30 sm:w-20 w-10 xl:text-sm text-xs">
                      <span className="xl:block lg:block md:block sm:block hidden">Thursday</span>
                      <span className="xl:hidden lg:hidden md:hidden sm:hidden block">Thu</span>
                    </th>
                    <th className="p-2 border-r h-10 xl:w-40 lg:w-30 md:w-30 sm:w-20 w-10 xl:text-sm text-xs">
                      <span className="xl:block lg:block md:block sm:block hidden">Friday</span>
                      <span className="xl:hidden lg:hidden md:hidden sm:hidden block">Fri</span>
                    </th>
                    <th className="p-2 border-x h-10 xl:w-40 lg:w-30 md:w-30 sm:w-20 w-10 xl:text-sm text-xs">
                      <span className="xl:block lg:block md:block sm:block hidden">Saturday</span>
                      <span className="xl:hidden lg:hidden md:hidden sm:hidden block">Sat</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {generateCalendarDays()}
                </tbody>
              </table>
            </div>
          </div>
        </VStack>
      </VStack>
    </Box>
  );
};

export default Calendar;