import { Box } from "@chakra-ui/react";
import CalendarTest from "../Components/CalendarTest";
import { useAuth } from "../../Providers/AuthenticationProvider";

const UserCalendar = () => {
  const { userData } = useAuth();

  const events: any[] =
    userData?.installation_date && userData?.site_survey_date
      ? [
          { date: userData.site_survey_date, title: "Site Survey" },
          { date: userData.installation_date, title: "Installation" },
        ]
      : userData?.installation_date
      ? [{ date: userData.installation_date, title: "Installation" }]
      : userData?.site_survey_date
      ? [{ date: userData.site_survey_date, title: "Site Survey" }]
      : [];

  return (
    <Box w="full" minH="90vh">
      <CalendarTest events={events} />
    </Box>
  );
};
export default UserCalendar;
