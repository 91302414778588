import { Container, SimpleGrid, Image, Flex, Heading, Text, Stack, StackDivider, VStack, Button } from "@chakra-ui/react";
import customerSupport from "../Assets/customerService.jpg";
import { MdCall } from "react-icons/md";

export default function EngagementInfo() {
  const handleCallNow = () => {
    window.location.href = "tel:+15632995135";
  };

  return (
    <VStack>
      <Container maxW={"full"} py={12}>
        <SimpleGrid columns={{ base: 1, lg: 2 }} spacing="100px">
          <Stack spacing={4}>
            <Text
              textTransform={"uppercase"}
              color={"white"}
              fontWeight={600}
              fontSize={"sm"}
              fontFamily="'eurostile', sans-serif"
              bg={"#566581"}
              p={2}
              alignSelf={"flex-start"}
              rounded={"md"}
            >
              Schedule a call
            </Text>
            <Heading mt="8px" fontFamily="'eurostile', sans-serif" color="gray.600">
              Have questions or need help?
            </Heading>
            <Text fontSize={"lg"} fontFamily="'eurostile', sans-serif">
              While our platform allows you to explore solar options and get started on your own, we’re here to assist you every step of the way. Schedule a call with one of our
              non-commissioned Solar Experts, whose only goal is to provide you with honest, quality service at your pace and based on your needs - not sales quotas.
              <br />
              <br />
              Solar can be one of the smartest choices for your home and budget, and we’re dedicated to making it easy for you, on your schedule. Book a call today and let us help
              you make the switch to solar, with no pressure, just support.
            </Text>
            <Stack spacing={4}>
              <StackDivider w="full" h="2px" bgColor="#566581" borderColor={"#566581"} />
              <VStack w="full">
                <Button
                  w="full"
                  rightIcon={<MdCall style={{ border: "none" }} />}
                  bgColor="#566581"
                  color="white"
                  variant="outline"
                  _hover={{ bgColor: "#6e85a6" }}
                  fontFamily="'eurostile', sans-serif"
                  as="a"
                  href="https://calendly.com/klaravia-support/30min"
                  // onClick={handleCallNow}
                >
                  Schedule a Call
                </Button>
              </VStack>
              <StackDivider w="full" h="2px" bgColor="#566581" borderColor={"#566581"} />
            </Stack>
          </Stack>
          <Flex>
            <Image maxH="600px" rounded={"md"} alt={"feature image"} src={customerSupport} objectFit={"cover"} />
          </Flex>
        </SimpleGrid>
      </Container>
    </VStack>
  );
}
