import React, { useEffect, useState } from "react";
import {
  Box,
  VStack,
  Text,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Heading,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import { useGetUser } from "../../hooks/userHooks";
import { UserWithoutTypeName } from "../../Providers/LeadDataProvider";
import PDFViewer from "../Components/PDFViewer";
import { useAuth } from "../../Providers/AuthenticationProvider";

const UserDocumentsPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: pictureIsOpen, onOpen: pictureOnOpen, onClose: pictureOnClose } = useDisclosure();
  const [pictureSrc, setPictureSrc] = useState<string | null>(null);
  const btnRef = React.useRef();
  const { userData } = useAuth();

  return (
    <Box w="full" minH="90vh" bg="#f4eee9" fontFamily="'eurostile', sans-serif">
      {(userData && userData.user_images && userData.user_images.length > 0) || (userData && userData.installer && userData.installer.user_agreement) ? (
        <VStack w="full">
          <VStack w="full" minH="70vh">
            <Text fontWeight="bold" fontSize="54px" mb="40px">
              Your documents
            </Text>
            <VStack w="full" alignItems="flex-start" p={{ base: "10px", lg: "40px" }}>
              {userData && userData.final_payment_invoice && (
                <VStack w="full">
                  <Text fontSize="32px" fontWeight="bold" mb="14px" pl="8px">
                    Total Remaining Payment Invoice
                  </Text>
                  <VStack w="full" bgColor="rgba(0,0,0,.1)" borderRadius="12px" p="14px">
                    <Text>This is the invoice for the remainder of what you owe for your system. Please make a check out to Klaravia Inc for the amount indicated above.</Text>
                    <Box
                      w="full"
                      p="10px"
                      bgColor="white"
                      borderRadius="12px"
                      shadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                      cursor="pointer"
                      _hover={{ shadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}
                    >
                      <PDFViewer pdfUrl={userData?.installer?.user_agreement} />
                    </Box>
                  </VStack>
                </VStack>
              )}
              {userData && userData.installer && userData.installer.user_agreement && (
                <VStack w="full">
                  <Text fontSize="32px" fontWeight="bold" mb="14px" pl="8px">
                    Installer Agreement
                  </Text>
                  <VStack w="full" bgColor="rgba(0,0,0,.1)" borderRadius="12px" p="14px">
                    <Text>
                      You can review your agreement with your installer here, but the actual agreement will be sent to {userData && userData.email ? userData.email : "your email"}{" "}
                      for you to sign by {userData.installer ? userData.installer.company_name : "your installer"}.
                    </Text>
                    <Box
                      w="full"
                      p="10px"
                      bgColor="white"
                      borderRadius="12px"
                      shadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                      cursor="pointer"
                      _hover={{ shadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}
                    >
                      <PDFViewer pdfUrl={userData.installer.user_agreement} />
                    </Box>
                  </VStack>
                </VStack>
              )}
              {userData && userData.user_images && userData.user_images.length > 0 && (
                <VStack w="full">
                  <Text fontSize="32px" fontWeight="bold" mb="14px" mt="20px" pl="8px">
                    Your Uploads
                  </Text>
                  <HStack w="full" bgColor="rgba(0,0,0,.1)" borderRadius="12px" align="stretch" gap="20px" p="14px" flexDirection={{ base: "column", lg: "row" }}>
                    {userData &&
                      userData.user_images &&
                      userData.user_images.map((e: string | null, i: number) => {
                        return (
                          <>
                            <Box
                              p="10px"
                              bgColor="white"
                              borderRadius="12px"
                              shadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                              cursor="pointer"
                              _hover={{ shadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}
                              onClick={() => {
                                setPictureSrc(e);
                                pictureOnOpen();
                              }}
                            >
                              <Image h="180px" w="160px" src={e || "fallback url"} />
                              <Text>{`Upload ${i + 1}`}</Text>
                            </Box>
                          </>
                        );
                      })}
                  </HStack>
                </VStack>
              )}
            </VStack>
          </VStack>
        </VStack>
      ) : (
        <VStack w="full">
          <VStack minH="70vh" maxW="1440px" justifyContent="center">
            <Text fontWeight="bold" fontSize="54px" mb="10px">
              Nothing to see here..
            </Text>
            <Text>You haven't uploaded any documents yet</Text>
          </VStack>
        </VStack>
      )}
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        //@ts-ignore
        finalFocusRef={btnRef}
      >
        <DrawerContent fontFamily="'eurostile', sans-serif">
          <DrawerCloseButton />
          <DrawerHeader>Upload Documents</DrawerHeader>

          <DrawerBody>
            <Text>
              Welcome to our secure document upload portal! This feature allows you to effortlessly share important documents with our team, ensuring a smooth and efficient process
              towards installing your new solar panel system. Here's how you can get started:
            </Text>
            <br />
            <Heading fontSize="18px" mb="10px" mt="30px">
              Select Documents:
            </Heading>
            <Text>
              Click the "Upload" button to select files from your computer or drag and drop files directly into the designated area. You can upload photos, PDFs, text documents,
              and more.
            </Text>
            <Heading fontSize="18px" mb="10px" mt="30px">
              Review & Submit:
            </Heading>
            <Text>
              Once selected, review your files to ensure they are correct and fully readable. You can upload multiple documents at once. When you're ready, click "Submit" to
              securely send them to our team.
            </Text>
            <Heading fontSize="18px" mb="10px" mt="30px">
              Track Progress:
            </Heading>
            <Text>
              After submission, you can view the status of each document in your account. We will review your documents promptly and reach out if additional information is needed.
            </Text>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      {/* TODO: implement fallback picture url */}
      <PictureModal src={pictureSrc || ""} pictureIsOpen={pictureIsOpen} pictureOnClose={pictureOnClose} />
    </Box>
  );
};
export default UserDocumentsPage;

const PictureModal = ({ src, pictureIsOpen, pictureOnClose }: { src: string; pictureIsOpen: boolean; pictureOnClose: () => void }) => {
  return (
    <Modal isOpen={pictureIsOpen} onClose={pictureOnClose}>
      <ModalOverlay />
      <ModalContent w="full" maxW="800px" pt="40px" resize="both">
        <ModalCloseButton />
        <ModalBody>
          <Image w="full" src={src || "fallback url"} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
