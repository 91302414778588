import { Box, Text, VStack, HStack, UnorderedList, ListItem, Button, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, useDisclosure } from "@chakra-ui/react";
import DocumentUpload from "../Components/DocumentUpload";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const BillUpload = () => {
  const navigate = useNavigate();
  const [filesHaveBeenUploaded, setFilesHaveBeenUploaded] = useState(false);
  const [errorUploadingImage, setErrorUploadingImage] = useState(false);
  const { isOpen, onToggle, onClose } = useDisclosure();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box w="full" minH="100vh" pt="80px" bg="black" fontFamily="'Eurostile', sans-serif">
      <VStack w="full" minH="100vh" justifyContent="center">
        <HStack w="full" minH="100vh" justifyContent="center" align="stretch" spacing="40px" flexDirection={{ base: "column", lg: "row" }}>
          <VStack w={{ base: "full", lg: "50vw" }} h={{ base: "50vh", lg: "100vh" }} justifyContent="center" bgColor="black" p={{ base: "0px", lg: "40px" }}>
            <VStack bgColor="black" borderRadius={{ base: "0px", lg: "12px" }} pb={{ base: "10px", lg: "120px" }} shadow="lg">
              <Text color="white" fontSize={{ base: "20px", md: "34px" }} mt={{ base: "20px", lg: "0px" }} mb="20px" fontWeight="bold">
                Upload your electric bill
              </Text>
              <DocumentUpload setFilesHaveBeenUploaded={setFilesHaveBeenUploaded} setErrorUploadingImage={setErrorUploadingImage} />
              <Popover placement="top-end" isOpen={isOpen} onClose={onClose}>
                <PopoverTrigger>
                  <Text color="gray.400" cursor="pointer" fontSize="12px" pb={{ base: "20px", lg: "0px" }} onClick={() => onToggle()}>
                    Why do we need this information?
                  </Text>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  {/* <PopoverCloseButton /> */}
                  <PopoverBody>
                    <VStack w="full" p="10px">
                      <Text>We need to review your electric bill to ensure we are working with accurate information.</Text>
                      <Text>This is what allows us to craft high quality professional designs.</Text>
                    </VStack>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </VStack>
          </VStack>
          <VStack
            w={{ base: "full", lg: "50vw" }}
            bg="#f4eee9"
            p={{ base: "20px", lg: "40px" }}
            pt="0px"
            justifyContent="center"
            flexDir={{ base: "column-reverse", lg: "column" }}
          >
            <VStack w="full">
              <Text w="full" fontWeight="bold">
                Gather Your Documents:
              </Text>
              <UnorderedList w="full" pl="40px">
                <ListItem>
                  <Text w="full">Ensure you have the most recent electric bill ready.</Text>
                </ListItem>
                <ListItem>
                  <Text w="full">Only upload images of the first page of your bill where the account details and total amount due are visible.</Text>
                </ListItem>
              </UnorderedList>
              <Text w="full" fontWeight="bold" mt="20px">
                Prepare Your Images:
              </Text>
              <UnorderedList w="full" pl="40px">
                <ListItem>
                  <Text w="full">Ensure the text on your electric bill is not blurry.</Text>
                </ListItem>
                <ListItem>
                  <Text w="full">Take the photo in a well-lit area to avoid dark images or glare. Natural light is preferable.</Text>
                </ListItem>
                <ListItem>
                  <Text w="full">Make sure there are no shadows or reflections on the bill.</Text>
                </ListItem>
                <ListItem>
                  <Text w="full">Ensure the entire bill is visible within the frame. Do not cut off any part of the document.</Text>
                </ListItem>
              </UnorderedList>
              <Text w="full" fontWeight="bold" mt="20px">
                Uploading the Images:
              </Text>
              <UnorderedList w="full" pl="40px">
                <ListItem>
                  <Text w="full">Click on the Upload button or drag and drop your images into the designated dropbox area.</Text>
                </ListItem>
                <ListItem>
                  <Text w="full">Supported file formats include JPG, PNG, and PDF. Ensure your images are in one of these formats.</Text>
                </ListItem>
              </UnorderedList>
            </VStack>
            <HStack w="full" alignItems="center" justifyContent="center">
              <Button
                w="160px"
                h={filesHaveBeenUploaded && !errorUploadingImage ? "40px" : "0px"}
                mr={{ base: "0px", lg: "40px" }}
                mt={{ base: "20px", lg: "80px" }}
                mb={{ base: "20px", lg: "0px" }}
                visibility={filesHaveBeenUploaded && !errorUploadingImage ? "visible" : "hidden"}
                bgColor="black"
                color="white"
                _hover={{ bgColor: "rgba(0,0,0,.8)" }}
                onClick={() => {
                  navigate("/loan-message");
                }}
              >
                Continue
              </Button>
            </HStack>
          </VStack>
        </HStack>
      </VStack>
    </Box>
  );
};

export default BillUpload;
