import { Box, VStack, Text, Link } from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import doorSalesman from "../Blogs/BlogImages/doorSalesman.webp";
import solarTown from "../Blogs/BlogImages/solarTown.webp";
import questionInABottle from "../Blogs/BlogImages/questionInABottle.webp";
import homeVal from "../Blogs/BlogImages/aiHomeValueBlog.jpg";

const BlogUnderstandingSolarRoofTaxIncentives = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBlogLinkClick = (link: string) => {
    navigate(link);
  };

  return (
    <Box w="full" minH="90vh" pb="60px" pt="80px">
      <Box w="full" h={{ base: "200px", lg: "500px" }} bgImage={homeVal} bgSize="cover" bgRepeat="no-repeat" bgPosition="center" />
      <VStack w="full" gap="40px" align="stretch" p="20px">
        <VStack w="full">
          <br />
          <VStack w="full" mt="20px" lineHeight={1} maxW="800px">
            <Text w="full" fontWeight="bold" fontSize={{ base: "28px", lg: "32px" }}>
              Will Solar Increase My Home’s Value?
            </Text>
          </VStack>
          <br />
          <Text fontSize="20px" maxW="800px">
            If you&#39;re considering solar panels for your home, you&#39;re probably already excited about the idea of saving on your energy bills and helping the environment. But
            did you know that going solar can also boost your home&#39;s value? It&#39;s true – a solar roof is not only good for the planet and your wallet, but also an investment
            that can make your property more attractive to potential buyers. Let&#39;s dive into how, why, and how much solar can increase your home&#39;s value to help you find
            out if going solar is the right move for you.
          </Text>
          <br />
          <Text w="full" fontWeight="bold" fontSize="18px" maxW="800px">
            Why Buyers Love Solar Homes
          </Text>
          <Text fontSize="20px" maxW="800px">
            The real estate market is quickly catching on to the benefits of solar energy. According to a study by the Lawrence Berkeley National Laboratory, homes with solar sell
            for an average of $15,000 more than comparable homes without solar. With the average home value currently sitting around $363k according to research by Nasdaq, that
            represents a substantial 4%+ bump! It’s a strong testament to the growing value homebuyers place on renewable energy and being energy independent.
          </Text>
          <br />
          <Text fontSize="20px" maxW="800px">
            In fact, we have heard several real estate agents say they have a growing number of clients specifically asking to be shown only homes possessing solar. This begged the
            question: why are buyers so keen on solar? We asked those agents what these solar-focused clients value most in a solar-ready home and below are the most common
            responses we received:
          </Text>
          <br />
          <Text w="full" maxW="700px" textAlign="left">
            1. <b>Financial Savings:</b> One of the biggest reasons buyers are willing to pay more for homes with solar panels is due to the prospect of lower electricity bills.
            Imagine moving into a new home and knowing that your energy costs will be significantly lower each month, especially during the harsh weather seasons. That’s a huge
            selling point, especially after taking on a new mortgage!
          </Text>
          <br />
          <Text w="full" maxW="700px" textAlign="left">
            2. <b>Environmental Impact:</b> More and more buyers are looking for ways to reduce their carbon footprint and work towards a better future for their kids and
            grandkids. Adopting solar panels is a very tangible and attainable way to do just that. By investing in solar, you become considerably more appealing to eco-conscious
            buyers who want to make a positive impact on the environment. And it might surprise you just how many people highly value environmental impact in their decision-making
            process. According to theroundup.org, a whopping 78% of potential homebuyers say they highly value environmental sustainability.
          </Text>
          <br />
          <Text w="full" maxW="700px" textAlign="left">
            3. <b>Energy Independence:</b> Solar panels give homeowners a sense of peace knowing they have control over their energy supply. This is particularly appealing in areas
            with frequent power outages or high electricity rates. Buyers appreciate the reliability and security that comes with having their own source of energy, especially
            considering the existence of our overworked and outdated energy grid.
          </Text>
          <br />
          <Text w="full" maxW="700px" textAlign="left">
            4. <b>Tax Incentives and Rebates:</b> Many states offer tax incentives and rebates for homes with solar installations. These financial perks make your home even more
            attractive to many potential buyers. They’ll see not only the monthly savings but also the potential for tax credits and energy repurchase engagements.
          </Text>
          <br />
          <VStack w="full" maxW="800px">
            <Text w="full" fontWeight="bold" fontSize="18px" maxW="800px">
              The Cost Factor: Critical Considerations
            </Text>
            <Text fontSize="20px">
              At Klaravia, we are whole-heartedly committed to providing you with ALL information clearly and transparently. Therefore, we would be remiss if we didn’t remind you
              that while solar can increase your home value tremendously, you must also consider the costs. Despite what some scallywag door-to-door salesmen might tell you,
              putting solar onto your home isn’t free. The price of equipment and installation can be significant, and it’s crucial to ensure that these costs are managed
              correctly. This means finding the right installer, optimizing government incentives, and choosing the best financing solution for your unique situation.
            </Text>
            <Text fontSize="20px">
              Adding a solar roof is not always a net gain unless everything is done correctly. To maximize your investment, you need to ensure you have a solid game plan in place.
              This includes:
            </Text>
            <br />
            <Text w="full" maxW="700px" textAlign="left">
              • <b>Comparing and Contrasting Installers:</b> Not all installers are created equal. Make sure you choose a reputable company with a proven track record. Look at
              reviews, view multiple quotes side-by-side, and consider what you value most when choosing who will put solar on your home. What’s most important to you? Is it lowest
              cost? Fastest installation? Best warranties and service? These are all things to think of when choosing which provider is the right fit for you. Remember, you are in
              control.
            </Text>
            <br />
            <Text w="full" maxW="700px" textAlign="left">
              • <b>Optimize Government Incentives:</b> Take full advantage of state and federal incentives. These can significantly reduce the upfront cost of your solar
              installation. Currently, the Federal government is offering a 30% tax credit to homeowners who adopt solar. This incentive, known as the Investment Tax Credit (ITC),
              covers the costs of solar panels, equipment, labor, energy storage devices like batteries, and even sales taxes on eligible expenses. This tax credit can be applied
              to both primary and secondary residences, but not rental properties as of now. Your state and local governments might also kick in some help for your project, so be
              sure to do your research and maximize your benefits.
            </Text>
            <br />
            <Text w="full" maxW="700px" textAlign="left">
              • <b>Choose the Right Financing:</b> Whether you pay in cash or borrow to make your solar purchase, be sure to understand the terms and choose the best option for
              your financial situation. There might be more options available to you than you think. For instance, some homeowners have found better rates by taking advantage of
              renovation/rehabilitation mortgages, home equity lines of credit, and even borrowing from their own 401(k). There’s no blanket solution, so compare several options to
              see which best suits you.
            </Text>
            <br />
            <Text w="full" maxW="700px" textAlign="left">
              • <b>Conduct a Cost-Benefit Analysis:</b> Once you have obtained all of the above information, it’s important to do a cost-benefit analysis. Weigh the initial costs
              against the long-term savings and increased property value and ensure the numbers add up in your favor.
            </Text>
          </VStack>
          <br />
          <VStack w="full" maxW="800px">
            <Text w="full" fontWeight="bold" fontSize="18px" maxW="800px">
              How Solar Transformed the Lee Family’s Home Sale
            </Text>
            <Text fontSize="20px">
              Let’s take a real-life example. The Lee family, living in a suburban neighborhood, decided to go solar four years ago. Since their transition to solar, they have
              enjoyed lower energy bills and felt good about their environmental impact. When they decided to sell their home earlier this year, they found that their solar was a
              major selling point. They received higher offers and sold their home significantly faster than similar properties in the area. The buyer’s agent mentioned that the
              home’s solar was the tipping point in the buyer’s decision. Their investment in solar paid off, both in savings and in increased home value.
            </Text>
            <Text fontSize="20px">
              In a competitive housing market, every advantage counts. Solar panels can be that unique feature that sets your home apart. They offer cost savings, environmental
              benefits, and energy independence—all of which are highly attractive to today’s buyers.
            </Text>
          </VStack>
          <br />
          <VStack w="full" maxW="800px">
            <Text w="full" fontWeight="bold" fontSize="18px" maxW="800px">
              Ready to Boost Your Home’s Value?
            </Text>
            <Text fontSize="20px">
              We at Klaravia are passionate about helping homeowners achieve energy independence and boost their property value. Having been in the field, we know that solar energy
              can be a game- changer for your home, and we’re here to guide you every step of the way. Our mission is to make solar power remarkably accessible, simple, and easy to
              understand. Our end-to-end experience is unrivaled, allowing you to quickly design, compare, and complete your project without the pressure of a salesman.
            </Text>
          </VStack>
          <br />
          <VStack w="full" maxW="800px">
            <Text fontSize="20px">
              Investing in solar energy is more than just a step towards sustainability – it can be a smart financial decision that increases the value of your home. Whether you’re
              planning to stay in your home for years to come or considering selling in the near future, solar energy is an investment that pays off in many ways. Visit our website
              today to compare prices and installers near you at www.klaravia.co. We are here to help you through every step in your journey towards a brighter, more sustainable
              future for your family and generations to come. Join the solar revolution today!
            </Text>
          </VStack>
        </VStack>
        <br />
        <VStack w="full">
          <VStack w={{ base: "full", lg: "35vw" }} alignItems="center" gap="20px" maxW="800px" onClick={() => handleBlogLinkClick("/blogs/shining-a-light-on-solar")}>
            <VStack w="full" cursor="pointer">
              <Box w="full" minH="250px" h="auto" bgImage={doorSalesman} bgSize="cover" bgRepeat="no-repeat" bgPosition="top" />
              <VStack w="full" mt="10px" lineHeight={1} pb="10px">
                <Text w="full" color="gray.500">
                  How Klaravia is Fighting for Homeowners
                </Text>
                <Text w="full" fontWeight="bold" fontSize="24px">
                  Shining a Light on Solar
                </Text>
              </VStack>
            </VStack>

            <VStack w="full" cursor="pointer" alignItems="center" onClick={() => handleBlogLinkClick("/blogs/to-solar-or-not-to-solar")}>
              <Box w="full" minH="250px" h="auto" bgImage={solarTown} bgSize="cover" bgRepeat="no-repeat" bgPosition="top" />
              <VStack w="full" mt="10px" lineHeight={1} pb="10px">
                <Text w="full" color="gray.500">
                  Does solar work for you?
                </Text>
                <Text w="full" fontWeight="bold" fontSize="24px">
                  To Solar or not to Solar: When Solar makes Sense
                </Text>
              </VStack>
            </VStack>

            <VStack w="full" cursor="pointer" onClick={() => handleBlogLinkClick("/blogs/top-10-solar-questions-answered")}>
              <Box w="full" minH="250px" h="auto" bgImage={questionInABottle} bgSize="cover" bgRepeat="no-repeat" bgPosition="top" />
              <VStack w="full" mt="10px" lineHeight={1} pb="10px">
                <Text w="full" color="gray.500">
                  Better understanding solar
                </Text>
                <Text w="full" fontWeight="bold" fontSize="24px">
                  Top 10 Solar Questions Answered
                </Text>
              </VStack>
            </VStack>
          </VStack>
        </VStack>
      </VStack>
    </Box>
  );
};
export default BlogUnderstandingSolarRoofTaxIncentives;
