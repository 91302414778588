import { Accordion, AccordionItem, AccordionButton, AccordionPanel, Flex, Text, VStack, Link } from "@chakra-ui/react";

import { ChevronDownIcon } from "@chakra-ui/icons";

export default function FrequentlyAsked() {
  return (
    <VStack w="full" fontFamily="'eurostile', sans-serif">
      <Text fontSize="30px" fontWeight="bold" pb="20px" color="gray.600">
        Frequently Asked Questions
      </Text>
      <VStack w="full">
        <Flex w="full" align={"center"} justify={"center"} color="#535353">
          <Accordion allowMultiple width="100%" rounded="lg">
            <AccordionItem borderColor="gray.400">
              <AccordionButton display="flex" alignItems="center" justifyContent="space-between" p={4} textAlign="left">
                <Text fontSize="md">What is Klaravia?</Text>
                <ChevronDownIcon fontSize="24px" />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <Text color="gray.600">
                  Klaravia is an online platform that helps you design your solar roof, compare local installers, and finance your project in minutes. We also provide front-line
                  customer service and real-time updates throughout your installation, making the switch to solar simple and hassle-free.
                </Text>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem borderColor="gray.400">
              <AccordionButton display="flex" alignItems="center" justifyContent="space-between" p={4} textAlign="left">
                <Text fontSize="md">How do I know which installers are available for my project?</Text>
                <ChevronDownIcon fontSize="24px" />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <Text color="gray.600">
                  After entering your address, Klaravia provides a list of local installers who are available for your solar installation. We are continually expanding our network
                  to ensure you have ample choices and access to the best solutions near you.
                </Text>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem borderColor="gray.400">
              <AccordionButton display="flex" alignItems="center" justifyContent="space-between" p={4} textAlign="left">
                <Text fontSize="md">How long does the installation process take?</Text>
                <ChevronDownIcon fontSize="24px" />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <Text color="gray.600">
                  The entire process, from design to installation, typically takes 1-2 months depending on your location, permitting, and installer. You can find details on each
                  installer’s average install time in the information tab.
                </Text>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem borderColor="gray.400">
              <AccordionButton display="flex" alignItems="center" justifyContent="space-between" p={4} textAlign="left">
                <Text fontSize="md">How do I track the progress of my solar installation?</Text>
                <ChevronDownIcon fontSize="24px" />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <Text color="gray.600">
                  You can track the progress of your solar project through Klaravia’s client portal. The portal provides real-time updates, allows you to upload necessary
                  documents, ask questions via chat, and stay informed every step of the way.
                </Text>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem borderColor="gray.400">
              <AccordionButton display="flex" alignItems="center" justifyContent="space-between" p={4} textAlign="left">
                <Text fontSize="md">Who do I contact for questions during my solar project?</Text>
                <ChevronDownIcon fontSize="24px" />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <Text color="gray.600">
                  While the installer you choose will handle most product and installation-specific questions, Klaravia acts as your communication liaison throughout the entire
                  process. We’re here to assist with any questions or concerns and ensure everything runs smoothly from start to finish.
                </Text>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem borderColor="gray.400">
              <AccordionButton display="flex" alignItems="center" justifyContent="space-between" p={4} textAlign="left">
                <Text fontSize="md">How much can I save with solar?</Text>
                <ChevronDownIcon fontSize="24px" />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <Text color="gray.600">
                  Savings vary based on your location, energy usage, and system size, but most homeowners see significant reductions in their monthly bills. Check out our savings
                  estimate calculator to find out what you might be able to save!
                </Text>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem borderColor="gray.400">
              <AccordionButton display="flex" alignItems="center" justifyContent="space-between" p={4} textAlign="left">
                <Text fontSize="md">How do payments work?</Text>
                <ChevronDownIcon fontSize="24px" />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <Text color="gray.600">
                  Payments through Klaravia are made in three steps. First, a $100 fee to complete your final solar design, then an initial deposit before the project begins, and
                  finally the remainder is invoiced after the solar installation is scheduled.
                </Text>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem borderColor="gray.400">
              <AccordionButton display="flex" alignItems="center" justifyContent="space-between" p={4} textAlign="left">
                <Text fontSize="md">What financing options do you offer and how does it work?</Text>
                <ChevronDownIcon fontSize="24px" />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <Text color="gray.600">
                  We have included several solar financing options on our site for your convenience and are not limited to only those lenders. Each lender has their own unique
                  process. Generally, once you are approved, the lender will send the funds to your bank account. You can then use those funds to complete your solar purchase.
                </Text>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem borderColor="gray.400">
              <AccordionButton display="flex" alignItems="center" justifyContent="space-between" p={4} textAlign="left">
                <Text fontSize="md">Am I eligible for the Tax credit?</Text>
                <ChevronDownIcon fontSize="24px" />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <Text color="gray.600">
                  We encourage all homeowners to check with their accountant on whether or not they qualify. The tax credit will come out of federal tax paid the calendar year you
                  went solar. You can owe taxes at the end of the year and use the credit to eat away at that or receive a refund. For a more in depth breakdown look at this{" "}
                  <Link textDecoration="underline" color="blue" href="https://www.forbes.com/home-improvement/solar/solar-tax-credit-by-state/">
                    article
                  </Link>
                </Text>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem borderColor="gray.400">
              <AccordionButton display="flex" alignItems="center" justifyContent="space-between" p={4} textAlign="left">
                <Text fontSize="md">If I install solar, does that mean I won't have to pay for electricity?</Text>
                <ChevronDownIcon fontSize="24px" />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <Text color="gray.600">You'll still have a small electricity bill if you're connected to the grid, but solar will significantly reduce your energy costs.</Text>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem borderColor="gray.400">
              <AccordionButton display="flex" alignItems="center" justifyContent="space-between" p={4} textAlign="left">
                <Text fontSize="md">Do I need batteries with my solar system?</Text>
                <ChevronDownIcon fontSize="24px" />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <Text color="gray.600">
                  Batteries are optional and allow you to store excess energy for use during outages or at night. Without batteries, you'll rely on the grid when the sun isn’t
                  shining. While not required, we recommend pairing your solar system with a battery to store energy and create your own microgrid.
                </Text>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Flex>
      </VStack>
    </VStack>
  );
}
