import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  VStack,
  Image,
  HStack,
  Text,
  Fade,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  useDisclosure,
  Button,
  Link,
  Checkbox,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
import UserProgressTracker from "../Components/UserProgressTracker";
import DocumentUpload from "../Components/DocumentUpload";
import { FileWithPath } from "react-dropzone";
import { useGetUser, useOnUpdateUserSubscription } from "../../hooks/userHooks";
import { PaymentMethod, ProjectStatus } from "../../API";
import StripeComponent from "../Components/StripeComponent";
import { FaRegCircleCheck } from "react-icons/fa6";
import siteSurvey from "../Assets/site_survey.png";
import { useAuth } from "../../Providers/AuthenticationProvider";

const UserPostOrderDash = () => {
  const [files, setFiles] = useState<FileWithPath[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showPaymentOptions, setShowPaymentOptions] = useState(false);
  const [userData, setUserData] = useState<any>();
  const [hoverIndex, setHoverIndex] = useState<number>(0);
  const [projectStatusIndex, setProjectStatusIndex] = useState<number>(0);
  const btnRef = React.useRef();
  const [filesHaveBeenUploaded, setFilesHaveBeenUploaded] = useState(false);
  const [projectStatus, setProjectStatus] = useState<ProjectStatus>();
  const [userSignedInstallerAgreement, setUserSignedInstallerAgreement] = useState<boolean>(false);
  const [showPaymentReq, setShowPaymentReq] = useState<boolean>(false);
  const [showStripePayment, setShowStripePayment] = useState<boolean>(false);
  const [errorUploadingImage, setErrorUploadingImage] = useState(false);
  const { userData: data } = useAuth();

  const { data: subscriptionData, loading: loadingUserChange, error: subErr } = useOnUpdateUserSubscription(userData && userData.id ? userData.id : "");

  useEffect(() => {
    if (subscriptionData) {
      setProjectStatus(subscriptionData?.onUpdateUser?.projectStatus);
    }
  }, [subscriptionData]);

  useEffect(() => {
    if (projectStatus) {
      setProjectStatusIndex(getProjectStatus(projectStatus));
    }
  }, [projectStatus]);

  const getProjectStatus = (pStatus: ProjectStatus) => {
    if (pStatus) {
      switch (pStatus) {
        case ProjectStatus.AWAITING_ELECTRIC_BILL_REVIEW:
          return 0;
        case ProjectStatus.ELECTRIC_BILL_ISSUE_AWAITING_CUSTOMER_RE_UPLOAD:
          return 0;
        case ProjectStatus.ELECTRIC_BILL_REVIEWED_AWAITING_TRUE_DESIGN_PAYMENT:
          return 1;
        case ProjectStatus.TRUE_DESIGN_PAYMENT_ISSUE_AWAITING_RESOLUTION:
          return 1;
        case ProjectStatus.TRUE_DESIGN_PAYMENT_SUCCEEDED_AWAITING_TRUE_DESIGN_ORDER:
          return 1;
        case ProjectStatus.TRUE_DESIGN_ORDER_FAILED_AWAITING_RESOLUTION:
          return 1;
        case ProjectStatus.TRUE_DESIGN_ORDER_COMPLETED_AWAITING_FINAL_DESIGN_CREATION:
          return 1;
        case ProjectStatus.FINAL_DESIGN_FAILED_AWAITING_RESOLUTION:
          return 1;
        case ProjectStatus.FINAL_DESIGN_COMPLETE_AWAITING_CUSTOMER_APPROVAL:
          return 1;
        case ProjectStatus.CUSTOMER_REJECTED_FINAL_DESIGN_AWAITING_RESOLUTION:
          return 1;
        case ProjectStatus.CUSTOMER_APPROVED_FINAL_DESIGN_AWAITING_INSTALLER_AGREEMENT_SIGNATURE:
          return 2;
        case ProjectStatus.INSTALLER_AGREEMENT_SIGNATURE_OBTAINED_AWAITING_DEPOSIT:
          return 2;
        case ProjectStatus.DEPOSIT_UNSUCCESSFUL_AWAITING_RESOLUTION:
          return 2;
        case ProjectStatus.DEPOSIT_SUCCESSFUL_AWAITING_SITE_SURVEY_SCHEDULE:
          return 3;
        case ProjectStatus.SITE_SURVEY_SCHEDULED_AWAITING_SITE_VISIT:
          return 3;
        case ProjectStatus.SITE_VISITED_AWAITING_SITE_APPROVAL:
          return 3;
        case ProjectStatus.SITE_NOT_APPROVED_CHANGE_ORDER_REQUIRED:
          return 3;
        case ProjectStatus.CHANGE_ORDER_SUBMITTED_AWAITING_CUSTOMER_SIGNATURE:
          return 3;
        case ProjectStatus.CUSTOMER_SIGNATURE_OBTAINED_AWAITING_ENGINEERING_DOCS:
          return 3;
        case ProjectStatus.SITE_NOT_APPROVED_CANNOT_SERVICE:
          return 3;
        case ProjectStatus.SITE_APPROVED_AWAITING_ENGINEERING_DOCS:
          return 4;
        case ProjectStatus.ENGINEERING_DOCS_FINISHED_AWAITING_PERMITS:
          return 4;
        case ProjectStatus.PERMITS_NOT_OBTAINED_AWAITING_RESOLUTION:
          return 4;
        case ProjectStatus.PERMITS_OBTAINED_AWAITING_SECOND_PAYMENT:
          return 5;
        case ProjectStatus.SECOND_PAYMENT_UNSUCCESSFUL_AWAITING_RESOLUTION:
          return 5;
        case ProjectStatus.SECOND_PAYMENT_SUCCESSFUL_AWAITING_INSTALL_SCHEDULE:
          return 6;
        case ProjectStatus.INSTALL_SCHEDULED_AWAITING_INSTALL:
          return 6;
        case ProjectStatus.INSTALL_NOT_COMPLETED_AWAITING_RESOLUTION:
          return 6;
        case ProjectStatus.INSTALL_COMPLETED_AWAITING_UTILITY_APPROVAL:
          return 7;
        case ProjectStatus.UTILITY_APPROVAL_ISSUE_AWAITING_RESOLUTION:
          return 7;
        case ProjectStatus.UTILITY_APPROVED_AWAITING_SYSTEM_TURN_ON:
          return 7;
        case ProjectStatus.SYSTEM_NOT_TURNED_ON_AWAITING_RESOLUTION:
          return 7;
        case ProjectStatus.SYSTEM_TURNED_ON:
          return 7;
        default:
          return 0;
      }
    } else {
      return 0;
    }
  };

  useEffect(() => {
    if (data) {
      setUserData(data);
      if (data.projectStatus) {
        setProjectStatus(data.projectStatus);
      }
    }
  }, [data]);

  useEffect(() => {
    setHoverIndex(projectStatusIndex);
  }, [projectStatusIndex]);

  const handleFileUpload = (uploadedFiles: FileWithPath[]) => {
    setFiles(uploadedFiles);
  };

  useEffect(() => {
    if (!localStorage.getItem("dashV")) {
      onOpen();
      localStorage.setItem("dashV", "true");
      return;
    }
  }, []);

  const handleAgreementAcknowledgement = (e: any) => {
    if (e.target.checked) {
      setUserSignedInstallerAgreement(true);
    } else {
      setUserSignedInstallerAgreement(false);
    }
  };

  const handleUserDownpaymentSelected = () => {
    if (userSignedInstallerAgreement) {
      setShowPaymentReq(false);
      setShowStripePayment(true);
    } else {
      setShowPaymentReq(true);
      setShowStripePayment(false);
    }
  };

  if (!userData) {
    return (
      <VStack w="full" minH="90vh" bg="#f4eee9" justifyContent="center">
        <Text fontSize="32px" fontWeight="bold" fontFamily="'eurostile', sans-serif">
          Loading...
        </Text>
      </VStack>
    );
  }

  return (
    <Box w="full" minH="90vh" p={{ base: "10px", lg: "40px" }} bg="#f4eee9" fontFamily="'eurostile', sans-serif">
      <UserProgressTracker setHoverIndex={setHoverIndex} hoverIndex={hoverIndex} projectStatusIndex={projectStatusIndex} />

      {hoverIndex === 0 &&
        (userData.payment_method === PaymentMethod.CASH ? (
          <Fade in={hoverIndex === 0} transition={{ enter: { duration: 0.9 } }}>
            <VStack mt="40px" shadow="lg" bgColor="white" borderRadius="12px" justifyContent="center" p="40px">
              {(userData.projectStatus === ProjectStatus.AWAITING_ELECTRIC_BILL_REVIEW ||
                (projectStatusIndex === 0 && userData.projectStatus !== ProjectStatus.ELECTRIC_BILL_ISSUE_AWAITING_CUSTOMER_RE_UPLOAD)) && (
                <HStack w="full" flexDirection={{ base: "column", "2xl": "row" }}>
                  <Image borderRadius="12px" w="400px" src="https://speedyelectricac.com/wp-content/uploads/2020/02/electric-bill.jpg" />
                  <VStack w="full">
                    <VStack w={{ base: "full", xl: "50%" }}>
                      <Heading w="full" textAlign="center" mt={{ base: "20px", "2xl": "0px" }} mb="20px" fontSize="26px" fontFamily="'eurostile', sans-serif">
                        Your electric bill is being reviewed
                      </Heading>
                      <VStack w="full" lineHeight={1}>
                        <Text w="full" textAlign="center">
                          We will notify you once we have finished reviewing your electric bill.
                        </Text>
                        <Text w="full" textAlign="center">
                          After that, you will be able to order your final design.
                        </Text>
                      </VStack>
                    </VStack>
                  </VStack>
                </HStack>
              )}
              {userData.projectStatus === ProjectStatus.ELECTRIC_BILL_ISSUE_AWAITING_CUSTOMER_RE_UPLOAD && (
                <VStack w="full">
                  <Text fontSize="32px" fontWeight="bold">
                    We weren't able to review your electric bill
                  </Text>
                  <Text color="gray.600">Please upload new photos of your electric bill, and if you have any questions, please reach out to us in your support chat.</Text>
                  <Box bgColor="rgba(0,0,0,.8)" borderRadius="12px" mt="40px">
                    <DocumentUpload setFilesHaveBeenUploaded={setFilesHaveBeenUploaded} setErrorUploadingImage={setErrorUploadingImage} />
                  </Box>
                </VStack>
              )}
              {(userData.projectStatus === ProjectStatus.ELECTRIC_BILL_REVIEWED_AWAITING_TRUE_DESIGN_PAYMENT || projectStatusIndex > 0) && (
                <HStack w="full" flexDirection={{ base: "column", lg: "row" }} px="20px">
                  <VStack w="full">
                    <Heading w="full" mb="10px" fontSize="26px" fontFamily="'eurostile', sans-serif">
                      Your electric bill has been reviewed!
                    </Heading>
                    <VStack w="full" lineHeight={1}>
                      <Text w="full">
                        We looked over your electric bill to make sure your final design is accurate. You're now ready to purchase your professional solar design.
                      </Text>
                    </VStack>
                  </VStack>
                  <FaRegCircleCheck size="40px" color="#38A169" />
                </HStack>
              )}
            </VStack>
          </Fade>
        ) : (
          <Fade in={hoverIndex === 0} transition={{ enter: { duration: 0.9 } }}>
            <VStack mt="40px" shadow="lg" bgColor="white" borderRadius="12px" justifyContent="center" p="20px">
              <HStack w="full" flexDirection={{ base: "column", lg: "row" }} px="20px">
                <VStack w="full">
                  <Heading w="full" mb="10px" fontSize="26px" fontFamily="'eurostile', sans-serif">
                    Your electric bill is being reviewed
                  </Heading>
                  <VStack w="full" lineHeight={1}>
                    <Text w="full">We will notify you once we have finished reviewing your electric bill. In the meantime, apply for one of the loans listed below.</Text>
                  </VStack>
                </VStack>
                <FaRegCircleCheck size="40px" />
              </HStack>
            </VStack>
            <VStack mt="40px" shadow="lg" bgColor="white" minH="40vh" borderRadius="12px" justifyContent="center" p="40px">
              <HStack w="full" flexDirection={{ base: "column", lg: "row" }}>
                <VStack w="full">
                  <VStack w="full">
                    <HStack w="full">
                      <VStack w="full" alignItems="flex-start">
                        <Text w="full" fontSize="32px" fontWeight="bold" fontFamily="'eurostile', sans-serif">
                          Discover Financing Options
                        </Text>
                        <Text w="full" mb="20px">
                          Apply for any of the loans listed below, or find a loan on your own. After your application has been accepted and the money has been deposited in your
                          account, you can move forward with the process.
                        </Text>
                      </VStack>
                      {/* <VStack w="full">
                        <Button
                          w="140px"
                          h="40px"
                          color="white"
                          bg="linear-gradient(270deg, rgba(223,184,24,1) 0%, rgba(233,167,9,1) 61%)"
                          _hover={{ bg: "linear-gradient(270deg, rgba(241,206,63,1) 0%, rgba(247,187,42,1) 61%)" }}
                        >
                          Pay Now!
                        </Button>
                      </VStack> */}
                    </HStack>
                    <VStack w="full" bgColor="gray.100" p="20px" borderRadius="12px" alignItems="flex-start">
                      <VStack w="full" pt="20px" gap="20px">
                        <Link w="full" href="https://www.lightstream.com/solar-financing" isExternal>
                          <HStack w="full" bgColor="white" p="20px" borderRadius="12px">
                            <VStack w="full">
                              <Box w="full" mb="20px" display="flex" justifyContent="left">
                                <Image w="200px" src="https://www.lightstream.com/getmedia/818a6f23-2baf-4819-b4e7-b1b6b26961cf/LS-logo-horizontal-2022.aspx?ext=.svg" />
                              </Box>
                              <HStack w="full">
                                <VStack w="full">
                                  <Text w="full" fontWeight="bold">
                                    Loan Amount
                                  </Text>
                                  <Text w="full">$5,000 - $100,000</Text>
                                </VStack>
                                <VStack w="full">
                                  <Text w="full" fontWeight="bold">
                                    Est. APR
                                  </Text>
                                  <Text w="full">7.49% - 9.94%</Text>
                                </VStack>
                                <VStack w="full">
                                  <Text w="full" fontWeight="bold">
                                    Credit Score Required
                                  </Text>
                                  <Text w="full">660</Text>
                                </VStack>
                              </HStack>
                            </VStack>
                          </HStack>
                        </Link>
                        <Link w="full" href="https://www.upgrade.com/personal-loans/home-improvement/" isExternal>
                          <HStack w="full" bgColor="white" p="20px" borderRadius="12px">
                            <VStack w="full">
                              <Box w="full" mb="20px" display="flex" justifyContent="left">
                                <Image w="200px" src="https://www.nerdwallet.com/cdn-cgi/image/height=60,quality=85,format=auto/cdn/loans/pl/Upgrade-logo.png" />
                              </Box>
                              <HStack w="full">
                                <VStack w="full">
                                  <Text w="full" fontWeight="bold">
                                    Loan Amount
                                  </Text>
                                  <Text w="full">$1,000 - $50,000</Text>
                                </VStack>
                                <VStack w="full">
                                  <Text w="full" fontWeight="bold">
                                    Est. APR
                                  </Text>
                                  <Text w="full">8.49% - 35.99%</Text>
                                </VStack>
                                <VStack w="full">
                                  <Text w="full" fontWeight="bold">
                                    Credit Score Required
                                  </Text>
                                  <Text w="full">560</Text>
                                </VStack>
                              </HStack>
                            </VStack>
                          </HStack>
                        </Link>
                        <Link w="full" href="https://www.navyfederal.org/loans-cards/personal-loans.html?nav=bold" isExternal>
                          <HStack w="full" bgColor="white" p="20px" borderRadius="12px">
                            <VStack w="full">
                              <Box w="full" mb="20px" display="flex" justifyContent="left">
                                <Image w="150px" src="https://www.nerdwallet.com/cdn/loans/pl/NavyFederalCreditUnion-logo.svg" />
                              </Box>
                              <HStack w="full">
                                <VStack w="full">
                                  <Text w="full" fontWeight="bold">
                                    Loan Amount
                                  </Text>
                                  <Text w="full">$250 - $50,000</Text>
                                </VStack>
                                <VStack w="full">
                                  <Text w="full" fontWeight="bold">
                                    Est. APR
                                  </Text>
                                  <Text w="full">7.49% - 18.00%</Text>
                                </VStack>
                                <VStack w="full">
                                  <Text w="full" fontWeight="bold">
                                    Credit Score Required
                                  </Text>
                                  <Text w="full">None</Text>
                                </VStack>
                              </HStack>
                            </VStack>
                          </HStack>
                        </Link>
                      </VStack>
                    </VStack>
                  </VStack>
                </VStack>
              </HStack>
            </VStack>
          </Fade>
        ))}

      {hoverIndex === 1 && (
        <Fade in={hoverIndex === 1} transition={{ enter: { duration: 0.9 } }}>
          <VStack mt="40px" shadow="lg" bgColor="white" borderRadius="12px" justifyContent="center" p="40px">
            {projectStatusIndex < 1 && (
              <HStack w="full" flexDirection={{ base: "column", "2xl": "row" }}>
                <VStack w="full">
                  <VStack w={{ base: "full", xl: "50%" }}>
                    <Heading mb="20px" fontFamily="'eurostile', sans-serif">
                      Final Design
                    </Heading>
                    <Text fontSize="18px" textAlign="center">
                      After your electric bill has been reviewed, you will be able to order your final solar design.
                    </Text>
                  </VStack>
                </VStack>
              </HStack>
            )}
            {userData.projectStatus === ProjectStatus.ELECTRIC_BILL_REVIEWED_AWAITING_TRUE_DESIGN_PAYMENT && (
              <HStack w="full" flexDirection={{ base: "column", xl: "row" }}>
                <Image borderRadius="12px" w="full" maxW="500px" src="https://solarbuildermag.com/wp-content/uploads/2022/04/EagleView-TrueDesign-50_50-Image.jpg" />
                {showPaymentOptions ? (
                  <VStack w="full" minH="600px" bgColor="white" p="40px" borderRadius="12px" display="flex" justifyContent="center">
                    <StripeComponent chargeAmount={10000} />
                  </VStack>
                ) : (
                  <VStack w="full" ml={{ base: "0px", lg: "14px" }}>
                    <VStack w={{ base: "full", lg: "60%" }} minW="400px" mt={{ base: "20px", lg: "0px" }} px="20px">
                      <Text fontSize={["20px", "24px", "28px"]} fontWeight="bold" mb="20px" fontFamily="'eurostile', sans-serif">
                        Order Your Professional Design - $100
                      </Text>
                      <Text fontSize="18px">
                        It's almost time to order a professional solar design for your home. Once we have reviewed your electric bill, you will be able to click the "Order Design"
                        button to make your payment. We will then notify you once your final system design is complete and ready for you to review.{" "}
                      </Text>
                    </VStack>
                    <VStack mt="60px">
                      <Button w="160px" h="40px" bgColor="black" color="white" _hover={{ bgColor: "rgba(0,0,0,.7)" }} onClick={() => setShowPaymentOptions(true)}>
                        Order Design
                      </Button>
                      <Link fontSize="12px" color="blue" href="https://www.eagleview.com/construction/welcome-to-canyon-park-bothell-our-new-neighborhood/">
                        Learn More
                      </Link>
                    </VStack>
                  </VStack>
                )}
              </HStack>
            )}
            {userData.projectStatus === ProjectStatus.TRUE_DESIGN_PAYMENT_ISSUE_AWAITING_RESOLUTION && (
              <VStack w="full">
                <Alert status="error">
                  <AlertIcon />
                  <AlertTitle>Payment Issue</AlertTitle>
                  <AlertDescription>There was an issue processing your payment for the design. Please check your payment method and try again.</AlertDescription>
                </Alert>
                {!showStripePayment && (
                  <Button mt={4} colorScheme="blue" onClick={() => setShowStripePayment(true)}>
                    Retry Payment
                  </Button>
                )}
                {showStripePayment && (
                  <VStack w="full" minH="600px" bgColor="white" p="40px" borderRadius="12px" display="flex" justifyContent="center">
                    <StripeComponent chargeAmount={10000} />
                  </VStack>
                )}
              </VStack>
            )}
            {userData.projectStatus === ProjectStatus.TRUE_DESIGN_PAYMENT_SUCCEEDED_AWAITING_TRUE_DESIGN_ORDER && (
              <VStack w="full">
                <Text fontSize="32px" fontWeight="bold">
                  Congratulations! You've ordered your final design.
                </Text>
                <Text color="gray.600">Your final design is being prepared.</Text>
              </VStack>
            )}
            {userData.projectStatus === ProjectStatus.TRUE_DESIGN_ORDER_FAILED_AWAITING_RESOLUTION && (
              <VStack w="full">
                <Alert status="warning">
                  <AlertIcon />
                  <AlertTitle>Design Order Issue</AlertTitle>
                  <AlertDescription>There was an issue with your design order. Our team has been notified and will contact you shortly to resolve this.</AlertDescription>
                </Alert>
              </VStack>
            )}
            {userData.projectStatus === ProjectStatus.TRUE_DESIGN_ORDER_COMPLETED_AWAITING_FINAL_DESIGN_CREATION && (
              <VStack w="full">
                <Text fontSize="32px" fontWeight="bold">
                  Your design is being created!
                </Text>
                <Text color="gray.600">Our team is working on your final design. We'll notify you once it's ready for your review.</Text>
              </VStack>
            )}
            {userData.projectStatus === ProjectStatus.FINAL_DESIGN_FAILED_AWAITING_RESOLUTION && (
              <VStack w="full">
                <Alert status="error">
                  <AlertIcon />
                  <AlertTitle>Design Creation Issue</AlertTitle>
                  <AlertDescription>We encountered an issue while creating your final design. Our team is working to resolve this and will update you soon.</AlertDescription>
                </Alert>
              </VStack>
            )}
            {userData.projectStatus === ProjectStatus.FINAL_DESIGN_COMPLETE_AWAITING_CUSTOMER_APPROVAL && (
              <VStack w="full">
                <Text fontSize="32px" fontWeight="bold">
                  Your final design is complete!
                </Text>
                <Text color="gray.600">Please review and sign off on your design.</Text>
              </VStack>
            )}
            {userData.projectStatus === ProjectStatus.CUSTOMER_REJECTED_FINAL_DESIGN_AWAITING_RESOLUTION && (
              <VStack w="full">
                <Text fontSize="32px" fontWeight="bold">
                  Design Revision in Progress
                </Text>
                <Text color="gray.600">We've received your feedback on the design. Our team is working on revisions and will present an updated design soon.</Text>
                {/* TODO: Implement feedback submission logic 
                <Button
                  mt={4}
                  colorScheme="blue"
                  onClick={() => {
                    
                  }}
                >
                  Submit Additional Feedback
                </Button> */}
              </VStack>
            )}
            {(userData.projectStatus === ProjectStatus.DEPOSIT_SUCCESSFUL_AWAITING_SITE_SURVEY_SCHEDULE || projectStatusIndex > 1) && (
              <HStack w="full" justifyContent="space-between">
                <Text fontSize="26px" fontWeight="bold">
                  You've received your final design.
                </Text>
                <FaRegCircleCheck size="40px" color="#38A169" />
              </HStack>
            )}
          </VStack>
          {projectStatusIndex === 1 && (
            <VStack mt="40px" shadow="lg" bgColor="white" borderRadius="12px" p="40px">
              <Text w="full" fontSize="24px" fontWeight="semibold" mb="40px">
                What is a final design?
              </Text>
              <Text w="full">
                The final design is a detailed and complete plan for installing solar panels on your property. This design is created after a thorough analysis of your site using
                advanced technology to ensure accurate measurements and optimal panel placement.
              </Text>
              <Text w="full">
                The final design includes a precise layout of where each solar panel will be installed on your roof, taking into account factors like roof dimensions, angles, and
                potential shading. It also provides detailed electrical schematics showing how the panels will connect to your home’s electrical system. Additionally, the design
                ensures that all local building codes and safety standards are met, and it includes the necessary documents for obtaining installation permits.
              </Text>
              <Text w="full">
                The final design is a comprehensive plan that guarantees your solar system will be installed correctly and efficiently, maximizing energy production and ensuring
                compliance with all regulations. It’s the last step before moving forward with the installation, ensuring everything is perfectly tailored to your property.
              </Text>
            </VStack>
          )}
        </Fade>
      )}

      {hoverIndex === 2 && (
        <Fade in={hoverIndex === 2} transition={{ enter: { duration: 0.9 } }}>
          {projectStatusIndex < 2 && (
            <VStack w="full" mt="40px" shadow="lg" bgColor="white" borderRadius="12px" justifyContent="center" p="40px">
              <HStack w="full" flexDirection={{ base: "column", "2xl": "row" }}>
                <VStack w="full">
                  <VStack w={{ base: "full", xl: "50%" }}>
                    <Heading mb="20px" fontFamily="'eurostile', sans-serif">
                      Documents & Deposit
                    </Heading>
                    <Text fontSize="18px" textAlign="center">
                      After you've ordered your solar design, you will be able to sign the installer agreement and submit your deposit.
                    </Text>
                  </VStack>
                </VStack>
              </HStack>
            </VStack>
          )}
          {userData.projectStatus === ProjectStatus.CUSTOMER_APPROVED_FINAL_DESIGN_AWAITING_INSTALLER_AGREEMENT_SIGNATURE && (
            <VStack w="full">
              <VStack w="full" mt="60px" shadow="lg" bgColor="white" borderRadius="12px" justifyContent="center" p="20px">
                <HStack w="full" flexDirection={{ base: "column", lg: "row" }} px="20px">
                  <VStack w="full">
                    <Heading w="full" mb="10px" fontSize="26px" fontFamily="'eurostile', sans-serif">
                      Sign your contract
                    </Heading>
                    <VStack w="full" lineHeight={1}>
                      <Text w="full">
                        A contract from {userData.installer ? userData.installer.company_name : "the installer you chose"} will be sent to {userData.email || "your email"} shortly.
                        Please sign the agreement document and check the box below once you are finished.
                      </Text>
                    </VStack>
                  </VStack>
                  {/* <FaRegCircleCheck size="40px" /> */}
                </HStack>
                <HStack w="full" justifyContent="center" mt="40px">
                  <Text>I have signed the installer agreement with {userData.installer ? userData.installer.company_name : "my installer"}.</Text>
                  <Checkbox size="lg" onChange={handleAgreementAcknowledgement} />
                </HStack>
              </VStack>
              <VStack mt="20px" shadow="lg" bgColor="white" borderRadius="12px" justifyContent="center" p="20px">
                <HStack w="full" flexDirection={{ base: "column", lg: "row" }} px="20px">
                  <VStack w="full">
                    <Heading w="full" mb="10px" fontSize="26px" fontFamily="'eurostile', sans-serif">
                      Submit your deposit
                    </Heading>
                    <VStack w="full" alignItems="flex-start">
                      <Text w="80%">
                        This deposit is non-refundable and is used to pay the installation company for the work involved in perfomring a site survey and aqcuiring the necessary
                        permits and approvals for your project. Check out our{" "}
                        <Link color="blue.600" href="/terms-of-service#Payments-and-Fees">
                          terms of service
                        </Link>{" "}
                        for more details.
                      </Text>
                    </VStack>
                  </VStack>
                  {!showStripePayment && (
                    <Button
                      h="46px"
                      minW="120px"
                      bg="linear-gradient(270deg, rgba(223,184,24,1) 0%, rgba(233,167,9,1) 61%)"
                      color="white"
                      _hover={{ bg: "linear-gradient(270deg, rgba(241,206,63,1) 0%, rgba(247,187,42,1) 61%)", boxShadow: "lg" }}
                      onClick={handleUserDownpaymentSelected}
                    >
                      Pay Now
                    </Button>
                  )}
                  {showStripePayment && (
                    <VStack w="full" minH="600px" bgColor="white" p="40px" borderRadius="12px" display="flex" justifyContent="center">
                      <StripeComponent chargeAmount={300000} />
                    </VStack>
                  )}
                </HStack>
              </VStack>
            </VStack>
          )}
          {showPaymentReq && (
            <Box w="full" display="flex" justifyContent="center" pt="20px">
              <Text color="red.500">
                You must confirm that you've signed you installer agreement with {userData.installer ? userData.installer.company_name : "your installer"} by checking the box above
                before continuing.
              </Text>
            </Box>
          )}
          {userData.projectStatus === ProjectStatus.DEPOSIT_UNSUCCESSFUL_AWAITING_RESOLUTION && (
            <VStack mt="40px" shadow="lg" bgColor="white" borderRadius="12px" justifyContent="center" p="40px">
              <Alert status="error">
                <AlertIcon />
                <AlertTitle>Deposit Payment Failed</AlertTitle>
                <AlertDescription>There was an issue processing your deposit. Please check your payment method and try again.</AlertDescription>
              </Alert>

              {!showStripePayment && (
                <Button mt={4} colorScheme="blue" onClick={() => setShowStripePayment(true)}>
                  Retry Deposit Payment
                </Button>
              )}
              {showStripePayment && (
                <VStack w="full" minH="600px" bgColor="white" p="40px" borderRadius="12px" display="flex" justifyContent="center">
                  <StripeComponent chargeAmount={300000} />
                </VStack>
              )}
            </VStack>
          )}
          {(userData.projectStatus === ProjectStatus.DEPOSIT_SUCCESSFUL_AWAITING_SITE_SURVEY_SCHEDULE || projectStatusIndex > 2) && (
            <VStack mt="40px" shadow="lg" bgColor="white" borderRadius="12px" justifyContent="center" p="40px">
              <HStack w="full" justifyContent="space-between">
                <Text fontSize="26px" fontWeight="bold">
                  You've signed the installer agreement and we've received your deposit.
                </Text>
                <FaRegCircleCheck size="40px" color="#38A169" />
              </HStack>
            </VStack>
          )}
        </Fade>
      )}

      {hoverIndex === 3 && (
        <Fade in={hoverIndex === 3} transition={{ enter: { duration: 0.9 } }}>
          {projectStatusIndex < 3 && (
            <VStack mt="40px" shadow="lg" bgColor="white" minH="40vh" borderRadius="12px" justifyContent="center" p="40px">
              <HStack w="full" flexDirection={{ base: "column", lg: "row" }}>
                <Image borderRadius="12px" w="400px" src={siteSurvey} />
                <VStack w="full">
                  <VStack w="50%">
                    <Heading mb="20px" fontFamily="'eurostile', sans-serif">
                      Site Survey
                    </Heading>
                    <Text fontSize="18px">After you order you sign your contract, your installer will schedule a site survey.</Text>
                  </VStack>
                </VStack>
              </HStack>
            </VStack>
          )}
          {userData.projectStatus === ProjectStatus.DEPOSIT_SUCCESSFUL_AWAITING_SITE_SURVEY_SCHEDULE && (
            <VStack mt="40px" shadow="lg" bgColor="white" minH="40vh" borderRadius="12px" justifyContent="center" p="40px">
              <HStack w="full" flexDirection={{ base: "column", lg: "row" }}>
                <Image borderRadius="12px" w="400px" src={siteSurvey} />
                <VStack w="full">
                  <VStack w="50%">
                    <Heading mb="20px" fontFamily="'eurostile', sans-serif">
                      Site Survey
                    </Heading>
                    <Text fontSize="18px">We're waiting on {userData && userData.installer ? userData.installer.company_name : "your installer"} to schedule a site survey.</Text>
                  </VStack>
                </VStack>
              </HStack>
            </VStack>
          )}
          {userData.projectStatus === ProjectStatus.SITE_SURVEY_SCHEDULED_AWAITING_SITE_VISIT && (
            <VStack w="full" mt="40px" shadow="lg" bgColor="white" minH="40vh" borderRadius="12px" justifyContent="center" p="40px">
              <HStack w="full" flexDirection={{ base: "column", xl: "row" }}>
                <Image borderRadius="12px" w="400px" src={siteSurvey} />
                <VStack w="full">
                  <VStack w={{ base: "full", xl: "50%" }} mt={{ base: "20px", xl: "0px" }}>
                    <Heading textAlign="center" mb="20px" fontFamily="'eurostile', sans-serif">
                      Site Survey Scheduled
                    </Heading>
                    <Text fontSize="18px" textAlign="center">
                      {userData && userData.installer ? userData.installer.company_name : "Your installer"} has scheduled a site survey of your home!{" "}
                    </Text>
                  </VStack>
                </VStack>
              </HStack>
            </VStack>
          )}
          {userData.projectStatus === ProjectStatus.SITE_VISITED_AWAITING_SITE_APPROVAL && (
            <VStack mt="40px" shadow="lg" bgColor="white" borderRadius="12px" justifyContent="center" p="40px">
              <Text fontSize="26px" fontWeight="bold">
                Site Survey Completed
              </Text>
              <Text>
                The site survey has been completed. <b>{userData && userData.installer ? userData.installer.company_name : "Your installer"}</b> is now reviewing the results to
                ensure everything is in order for the installation.
              </Text>
            </VStack>
          )}
          {userData.projectStatus === ProjectStatus.SITE_NOT_APPROVED_CHANGE_ORDER_REQUIRED && (
            <VStack mt="40px" shadow="lg" bgColor="white" borderRadius="12px" justifyContent="center" p="40px">
              <Alert status="warning">
                <AlertIcon />
                <AlertTitle>Site Survey Results: Changes Required</AlertTitle>
                <AlertDescription>Based on the site survey, some changes to the original plan are necessary. We'll be in touch with details and next steps.</AlertDescription>
              </Alert>
            </VStack>
          )}
          {userData.projectStatus === ProjectStatus.CHANGE_ORDER_SUBMITTED_AWAITING_CUSTOMER_SIGNATURE && (
            <VStack mt="40px" shadow="lg" bgColor="white" borderRadius="12px" justifyContent="center" p="40px">
              <Text fontSize="26px" fontWeight="bold">
                Change Order Ready for Review
              </Text>
              <Text>A change order based on the site survey results has been prepared. Please review and sign the updated agreement.</Text>
              <Button
                mt={4}
                colorScheme="blue"
                onClick={() => {
                  /* TODO: Implement change order review logic */
                }}
              >
                Review Change Order
              </Button>
            </VStack>
          )}
          {userData.projectStatus === ProjectStatus.CUSTOMER_SIGNATURE_OBTAINED_AWAITING_ENGINEERING_DOCS && (
            <VStack mt="40px" shadow="lg" bgColor="white" borderRadius="12px" justifyContent="center" p="40px">
              <Text fontSize="26px" fontWeight="bold">
                Change Order Signed
              </Text>
              <Text>
                Thank you for signing the change order. {userData && userData.installer ? userData.installer.company_name : "Your installer"}'s engineering team is now preparing
                the final documentation for your installation.
              </Text>
            </VStack>
          )}
          {userData.projectStatus === ProjectStatus.SITE_NOT_APPROVED_CANNOT_SERVICE && (
            <VStack mt="40px" shadow="lg" bgColor="white" borderRadius="12px" justifyContent="center" p="40px">
              <Alert status="error">
                <AlertIcon />
                <AlertTitle>Unable to Proceed with Installation</AlertTitle>
                <AlertDescription>
                  Unfortunately, based on the site survey results, we are unable to proceed with the solar installation. A representative will contact you soon to discuss
                  alternatives.
                </AlertDescription>
              </Alert>
            </VStack>
          )}
          {(userData.projectStatus === ProjectStatus.SITE_APPROVED_AWAITING_ENGINEERING_DOCS || projectStatusIndex > 3) && (
            <VStack mt="40px" shadow="lg" bgColor="white" borderRadius="12px" justifyContent="center" p="40px">
              <HStack w="full" justifyContent="space-between">
                <Text fontSize="26px" fontWeight="bold">
                  Your site has passed inspection.
                </Text>
                <FaRegCircleCheck size="40px" color="#38A169" />
              </HStack>
            </VStack>
          )}
        </Fade>
      )}

      {hoverIndex === 4 && (
        <Fade in={hoverIndex === 4} transition={{ enter: { duration: 0.9 } }}>
          {projectStatusIndex < 4 && (
            <VStack w="full" mt="40px" shadow="lg" bgColor="white" borderRadius="12px" justifyContent="center" p="40px">
              <HStack w="full" flexDirection={{ base: "column", "2xl": "row" }}>
                <VStack w="full">
                  <VStack w={{ base: "full", xl: "50%" }}>
                    <Heading mb="20px" fontFamily="'eurostile', sans-serif">
                      Permits & Approvals
                    </Heading>
                    <Text fontSize="18px" textAlign="center">
                      After your site is inspected and approved, your installer will work on getting permits and approvals for the installation.
                    </Text>
                  </VStack>
                </VStack>
              </HStack>
            </VStack>
          )}
          {projectStatusIndex === 4 && (
            <VStack mt="40px" shadow="lg" bgColor="white" minH="40vh" borderRadius="12px" justifyContent="center" p="40px">
              <HStack w="full" flexDirection={{ base: "column", lg: "row" }}>
                <VStack w="full">
                  <VStack w="50%">
                    <Heading mb="20px" fontFamily="'eurostile', sans-serif">
                      Permits & Approvals
                    </Heading>
                    {userData.projectStatus === ProjectStatus.SITE_APPROVED_AWAITING_ENGINEERING_DOCS && (
                      <Text fontSize="18px">
                        Your site has been approved! {userData && userData.installer ? userData.installer.company_name : "Your installer"}'s engineering team is now preparing the
                        necessary documentation for permit applications.
                      </Text>
                    )}
                    {userData.projectStatus === ProjectStatus.ENGINEERING_DOCS_FINISHED_AWAITING_PERMITS && (
                      <Text fontSize="18px">
                        Engineering documentation is complete. {userData && userData.installer ? userData.installer.company_name : "Your installer"} has submitted the necessary
                        applications and are now waiting for permit approvals from local authorities.
                      </Text>
                    )}
                    {userData.projectStatus === ProjectStatus.PERMITS_NOT_OBTAINED_AWAITING_RESOLUTION && (
                      <Text fontSize="18px">
                        There's been a delay in obtaining the necessary permits. {userData && userData.installer ? userData.installer.company_name : "Your installer"} is working
                        with local authorities to resolve any issues. We'll keep you updated on the progress.
                      </Text>
                    )}
                    {userData.projectStatus === ProjectStatus.PERMITS_OBTAINED_AWAITING_SECOND_PAYMENT && (
                      <Text fontSize="18px">
                        Great news! All necessary permits have been obtained. We're now ready to proceed with the next phase of your installation. Please submit the second payment
                        to continue.
                      </Text>
                    )}
                  </VStack>
                </VStack>
              </HStack>
            </VStack>
          )}
          {(userData.projectStatus === ProjectStatus.PERMITS_OBTAINED_AWAITING_SECOND_PAYMENT || projectStatusIndex > 4) && (
            <VStack mt="40px" shadow="lg" bgColor="white" borderRadius="12px" justifyContent="center" p="40px">
              <HStack w="full" justifyContent="space-between">
                <Text fontSize="26px" fontWeight="bold">
                  All permits and approvals have been granted.
                </Text>
                <FaRegCircleCheck size="40px" color="#38A169" />
              </HStack>
            </VStack>
          )}
        </Fade>
      )}

      {/* Second Payment and Installation Stage */}
      {hoverIndex === 5 && (
        <Fade in={hoverIndex === 5} transition={{ enter: { duration: 0.9 } }}>
          {projectStatusIndex < 5 && (
            <VStack w="full" mt="40px" shadow="lg" bgColor="white" borderRadius="12px" justifyContent="center" p="40px">
              <HStack w="full" flexDirection={{ base: "column", "2xl": "row" }}>
                <VStack w="full">
                  <VStack w={{ base: "full", xl: "50%" }}>
                    <Heading mb="20px" fontFamily="'eurostile', sans-serif">
                      System Payment
                    </Heading>
                    <Text fontSize="18px" textAlign="center">
                      After the permits for your installation are obtained, you will be able to make your final system payment.
                    </Text>
                  </VStack>
                </VStack>
              </HStack>
            </VStack>
          )}
          {projectStatusIndex === 5 && (
            <VStack mt="40px" shadow="lg" bgColor="white" borderRadius="12px" justifyContent="center" p="40px">
              {userData.projectStatus === ProjectStatus.PERMITS_OBTAINED_AWAITING_SECOND_PAYMENT && (
                <VStack w="full">
                  <Text fontSize="26px" fontWeight="bold">
                    Second Payment Due
                  </Text>
                  <Text>All permits are in place. To proceed with the installation, please submit your final payment.</Text>
                  <Text>Your invoice can be found in your documents section.</Text>
                  {/* {!showStripePayment && (
                    <Button mt={4} colorScheme="blue" onClick={() => setShowStripePayment(true)}>
                      Make Second Payment
                    </Button>
                  )}
                  {showStripePayment && (
                    <VStack w="full" minH="600px" bgColor="white" p="40px" borderRadius="12px" display="flex" justifyContent="center">
                      <StripeComponent chargeAmount={userData.final_payment_amount} />
                    </VStack>
                  )} */}
                </VStack>
              )}
              {userData.projectStatus === ProjectStatus.SECOND_PAYMENT_UNSUCCESSFUL_AWAITING_RESOLUTION && (
                <VStack w="full">
                  <Alert status="error">
                    <AlertIcon />
                    <AlertTitle>Payment Issue</AlertTitle>
                    <AlertDescription>There was an issue processing your second payment. Please check your payment method and try again.</AlertDescription>
                  </Alert>
                  {!showStripePayment && (
                    <Button mt={4} colorScheme="blue" onClick={() => setShowStripePayment(true)}>
                      Retry Payment
                    </Button>
                  )}
                  {showStripePayment && (
                    <VStack w="full" minH="600px" bgColor="white" p="40px" borderRadius="12px" display="flex" justifyContent="center">
                      <StripeComponent chargeAmount={userData.final_payment_amount} />
                    </VStack>
                  )}
                </VStack>
              )}
            </VStack>
          )}
          {(userData.projectStatus === ProjectStatus.SECOND_PAYMENT_SUCCESSFUL_AWAITING_INSTALL_SCHEDULE || projectStatusIndex > 5) && (
            <VStack mt="40px" shadow="lg" bgColor="white" borderRadius="12px" justifyContent="center" p="40px">
              <HStack w="full" justifyContent="space-between">
                <Text fontSize="26px" fontWeight="bold">
                  Your second payment has been recieved.
                </Text>
                <FaRegCircleCheck size="40px" color="#38A169" />
              </HStack>
            </VStack>
          )}
        </Fade>
      )}

      {/* Utility Approval and System Activation Stage */}
      {hoverIndex === 6 && (
        <Fade in={hoverIndex === 6} transition={{ enter: { duration: 0.9 } }}>
          {projectStatusIndex < 6 && (
            <VStack w="full" mt="40px" shadow="lg" bgColor="white" borderRadius="12px" justifyContent="center" p="40px">
              <HStack w="full" flexDirection={{ base: "column", "2xl": "row" }}>
                <VStack w="full">
                  <VStack w={{ base: "full", xl: "50%" }}>
                    <Heading mb="20px" fontFamily="'eurostile', sans-serif">
                      Installation
                    </Heading>
                    <Text fontSize="18px" textAlign="center">
                      After we receive your system payment, your installer will schedule a time to install your solar system.
                    </Text>
                  </VStack>
                </VStack>
              </HStack>
            </VStack>
          )}
          {projectStatusIndex === 6 && (
            <VStack mt="40px" shadow="lg" bgColor="white" borderRadius="12px" justifyContent="center" p="40px">
              {userData.projectStatus === ProjectStatus.SECOND_PAYMENT_SUCCESSFUL_AWAITING_INSTALL_SCHEDULE && (
                <VStack w="full">
                  <Text fontSize="26px" fontWeight="bold">
                    Payment Received - Scheduling Installation
                  </Text>
                  <Text>
                    Thank you for your payment. {userData && userData.installer ? userData.installer.company_name : "Your installer"} is now coordinating with their team to
                    schedule your solar panel installation.
                  </Text>
                </VStack>
              )}
              {userData.projectStatus === ProjectStatus.INSTALL_SCHEDULED_AWAITING_INSTALL && (
                <VStack w="full">
                  <Text fontSize="26px" fontWeight="bold">
                    Installation Scheduled
                  </Text>
                  <Text>
                    Great news! Your installation has been scheduled for{" "}
                    {`${new Date(userData.installation_date).toLocaleDateString()} at ${new Date(userData.installation_date).toLocaleTimeString()}` || "[Date to be confirmed]"}.
                    Our team will be in touch with more details.
                  </Text>
                  {/* TODO: Implement reschedule logic
                  <Button
                    mt={4}
                    colorScheme="blue"
                    onClick={() => {
                    }}
                  >
                    Reschedule (if needed)
                  </Button> */}
                </VStack>
              )}
              {userData.projectStatus === ProjectStatus.INSTALL_NOT_COMPLETED_AWAITING_RESOLUTION && (
                <VStack w="full">
                  <Alert status="warning">
                    <AlertIcon />
                    <AlertTitle>Installation Delay</AlertTitle>
                    <AlertDescription>
                      There's been a delay with your installation. Our team is working to resolve the issue and will contact you shortly with more information.
                    </AlertDescription>
                  </Alert>
                </VStack>
              )}
            </VStack>
          )}
          {(userData.projectStatus === ProjectStatus.INSTALL_COMPLETED_AWAITING_UTILITY_APPROVAL || projectStatusIndex > 6) && (
            <VStack mt="40px" shadow="lg" bgColor="white" borderRadius="12px" justifyContent="center" p="40px">
              <HStack w="full" justifyContent="space-between">
                <Text fontSize="26px" fontWeight="bold">
                  Your solar system has been installed!
                </Text>
                <FaRegCircleCheck size="40px" color="#38A169" />
              </HStack>
            </VStack>
          )}
        </Fade>
      )}

      {/* Project Completion Stage */}
      {hoverIndex === 7 && (
        <Fade in={hoverIndex === 7} transition={{ enter: { duration: 0.9 } }}>
          {projectStatusIndex < 7 && (
            <VStack w="full" mt="40px" shadow="lg" bgColor="white" borderRadius="12px" justifyContent="center" p="40px">
              <HStack w="full" flexDirection={{ base: "column", "2xl": "row" }}>
                <VStack w="full">
                  <VStack w={{ base: "full", xl: "50%" }}>
                    <Heading mb="20px" fontFamily="'eurostile', sans-serif">
                      System Turn On
                    </Heading>
                    <Text fontSize="18px" textAlign="center">
                      Once your system has been installed and your utility company has passed off on the installation, your system will be turned on.
                    </Text>
                  </VStack>
                </VStack>
              </HStack>
            </VStack>
          )}
          {projectStatusIndex === 7 && (
            <VStack mt="40px" shadow="lg" bgColor="white" borderRadius="12px" justifyContent="center" p="40px">
              {userData.projectStatus === ProjectStatus.INSTALL_COMPLETED_AWAITING_UTILITY_APPROVAL && (
                <VStack w="full">
                  <Text fontSize="26px" fontWeight="bold">
                    Installation Complete - Awaiting Utility Approval
                  </Text>
                  <Text>
                    Congratulations! Your solar panel installation is complete. {userData && userData.installer ? userData.installer.company_name : "Your installer"} is now waiting
                    for final approval from your utility company before they can activate your system.
                  </Text>
                </VStack>
              )}
              {userData.projectStatus === ProjectStatus.UTILITY_APPROVAL_ISSUE_AWAITING_RESOLUTION && (
                <VStack w="full">
                  <Alert status="warning">
                    <AlertIcon />
                    <AlertTitle>Utility Approval Delay</AlertTitle>
                    <AlertDescription>
                      {userData && userData.installer ? userData.installer.company_name : "Your installer"} has encountered an issue with the utility approval process. Our team is
                      working with {userData && userData.installer ? userData.installer.company_name : "your installer"} to resolve this as quickly as possible.
                    </AlertDescription>
                  </Alert>
                </VStack>
              )}
              {userData.projectStatus === ProjectStatus.UTILITY_APPROVED_AWAITING_SYSTEM_TURN_ON && (
                <VStack w="full">
                  <Text fontSize="26px" fontWeight="bold">
                    Utility Approved - Preparing for System Activation
                  </Text>
                  <Text>
                    Great news! Your utility company has given their approval. {userData && userData.installer ? userData.installer.company_name : "Your installer"} is now
                    preparing to activate your solar panel system.
                  </Text>
                  {/* Implement system activation scheduling logic
                  <Button
                    mt={4}
                    colorScheme="green"
                    onClick={() => {
                    }}
                  >
                    Schedule System Activation
                  </Button> */}
                </VStack>
              )}
              {userData.projectStatus === ProjectStatus.SYSTEM_NOT_TURNED_ON_AWAITING_RESOLUTION && (
                <VStack w="full">
                  <Alert status="error">
                    <AlertIcon />
                    <AlertTitle>System Activation Issue</AlertTitle>
                    <AlertDescription>
                      {userData && userData.installer ? userData.installer.company_name : "Your installer"} has encountered an issue while trying to activate your system. Our team
                      is working on resolving this and will update you soon.
                    </AlertDescription>
                  </Alert>
                </VStack>
              )}
              {/* {userData.projectStatus === ProjectStatus.SYSTEM_NOT_TURNED_ON_AWAITING_RESOLUTION && (
              <VStack w="full">
                <Alert status="info">
                  <AlertIcon />
                  <AlertTitle>Final Steps in Progress</AlertTitle>
                  <AlertDescription>We're in the final stages of activating your system. Our team is working diligently to resolve any remaining issues.</AlertDescription>
                </Alert>
              </VStack>
            )} */}
              {userData.projectStatus === ProjectStatus.SYSTEM_TURNED_ON && (
                <VStack w="full">
                  <Text fontSize="32px" fontWeight="bold" color="green.500">
                    Congratulations!
                  </Text>
                  <Text fontSize="24px">Your Solar System is Now Active</Text>
                  <Text mt={4}>Your solar panel system is now fully operational. You're officially producing clean, renewable energy!</Text>
                </VStack>
              )}
            </VStack>
          )}
        </Fade>
      )}

      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        //@ts-ignore
        finalFocusRef={btnRef}
      >
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader fontFamily="'eurostile', sans-serif">Stay in the loop</DrawerHeader>

          <DrawerBody fontFamily="'eurostile', sans-serif">
            <Text>
              Welcome to your personalized Solar Installation Progress Tracker! This interactive tool is designed to keep you informed and engaged throughout the journey of
              installing solar panels on your rooftop. Here's how to make the most of it:
            </Text>
            <br />
            <Heading fontSize="18px" mb="10px" mt="30px">
              Explore Milestones:
            </Heading>
            <Text>At the top of the page, you'll find a progress bar divided into key milestones of your solar installation process.</Text>
            <Heading fontSize="18px" mb="10px" mt="30px">
              Click for Details:
            </Heading>
            <Text>
              Simply click on any milestone in the progress bar to reveal detailed information about the current status of that stage. You'll find insights into what has been
              completed, what's currently in progress, and what to expect next.
            </Text>
            <Heading fontSize="18px" mb="10px" mt="30px">
              Stay Informed:
            </Heading>
            <Text>
              Each milestone provides you with actionable insights and updates. Whether it's document approval, design finalization, or installation day, you'll have all the
              details right at your fingertips.
            </Text>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};
export default UserPostOrderDash;
