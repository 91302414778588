import { ReactElement, useEffect, useRef, useState } from "react";
import { Box, VStack, HStack, Heading, Text, Button, Container, Flex, SimpleGrid, Stack, StackDivider, useColorModeValue, Icon, Divider } from "@chakra-ui/react";
import CallToAction from "../Components/CallToAction";
import EngagementInfo from "../Components/EngagementInfo";
import FrequentlyAsked from "../Components/FrequentlyAsked";
import { motion, useTransform, MotionValue, useScroll, useInView, useAnimation } from "framer-motion";
import EngagementSectionLearningPrompt from "./EngagementSectionLearningPrompt";
import { useNavigate } from "react-router-dom";
import CoverageMap from "./CoverageMap";
import { BatteryFull, DollarSign, Leaf } from "lucide-react";
import "./LandingPage.css";
import landingHome from "../Assets/homeBg.png";
import LifetimeSavingsComponent from "../Components/LifetimeSavingsComponent";
import { IoBuild, IoLogoUsd, IoSearchSharp } from "react-icons/io5";

function LandingPage() {
  const [showBanner, setShowBanner] = useState(true);
  const MotionVStack = motion(VStack);

  function useParallax(value: MotionValue<number>, distance: number) {
    return useTransform(value, [0, 1], [-distance, distance - 200]);
  }

  const ref = useRef(null);
  const { scrollYProgress } = useScroll({ target: ref });
  const y = useParallax(scrollYProgress, -400);

  const popUpRef = useRef(null);
  const isInView = useInView(popUpRef, { once: true });

  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);

  const handleHideBanner = () => {
    setShowBanner(false);
  };

  const MotionHStack = motion(HStack);

  interface FeatureProps {
    text: string;
    iconBg: string;
    icon?: ReactElement;
  }

  const Feature = ({ text, icon, iconBg }: FeatureProps) => {
    return (
      <Stack direction={"row"} align={"center"}>
        <Flex w={8} h={8} align={"center"} justify={"center"} rounded={"full"} bg={iconBg}>
          {icon}
        </Flex>
        <Text fontWeight={600}>{text}</Text>
      </Stack>
    );
  };

  return (
    <Box w="full" minH="100vh" bg="#f4eee9" ref={ref}>
      {/* {showBanner && (
        <AnimatePresence>
          <MotionHStack
            px="20px"
            py="16px"
            justifyContent="space-between"
            bgColor="#566581"
            position="fixed"
            bottom="0px"
            borderTopRadius="10px"
            // Animation properties
            initial={{ y: 100 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ type: "spring", stiffness: 300, damping: 20 }}
          >
            <Text color="white" fontFamily="'eurostile', sans-serif">
              Have a referral code? 🎁
            </Text>
            <HStack gap="20px">
              <Button
                size="xs"
                colorScheme="whiteAlpha"
                onClick={() => {
                  navigate("/referral-code-claim");
                }}
              >
                Claim Discount!
              </Button>
              <CloseIcon w="10px" h="10px" color="white" cursor="pointer" onClick={handleHideBanner} />
            </HStack>
          </MotionHStack>
        </AnimatePresence>
      )} */}
      <VStack
        h={{ base: "86vh", md: "100vh" }}
        w="full"
        id="call-to-action-wrapper"
        bgColor="#f4eee9"
        justifyContent="center"
        p={{ base: "0px", lg: "20px" }}
        pt="0px"
        pb="0px"
        bgImage={landingHome}
        bgSize="contain"
        bgRepeat="no-repeat"
        bgPosition="bottom"
      >
        <VStack w="full" maxW="1440px" minH="100vh" borderRadius="6px" pt={{ base: "18vh", md: "20vh" }}>
          <CallToAction />
          <br />
          <br />
          <br /> <br />
        </VStack>
      </VStack>
      <section className="py-16 bg-gray-50" style={{ fontFamily: "'eurostile', sans-serif" }}>
        <div className="flex justify-center pt-20">
          <EngagementSectionLearningPrompt />
        </div>
      </section>
      <section className="bg-gray-100 py-16" style={{ fontFamily: "'eurostile', sans-serif" }}>
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">How It Works</h2>
          <VStack w="full" pt="10px">
            <div className="max-w-3xl mx-auto">
              {["Enter Address & Energy Use", "Compare Installers & Quotes", "Upload Electric Bill", "Order Solar Design", "Track Installation", "Start Saving"].map(
                (step, index) => (
                  <div key={index} className="flex items-center mb-4">
                    <div className="w-8 h-8 bg-yellow-500 rounded-full flex items-center justify-center mr-4">
                      <span className="text-white font-bold">{index + 1}</span>
                    </div>
                    <p className="text-lg">{step}</p>
                  </div>
                )
              )}
            </div>
          </VStack>
        </div>
      </section>
      <section className="py-12 bg-gray-50" style={{ fontFamily: "'eurostile', sans-serif" }}>
        <Container w="full" maxW={{ base: "full", "2xl": "1440px" }} px={{ base: "0px", lg: "20px" }} py={24}>
          <SimpleGrid columns={{ base: 1, lg: 2 }} spacing="100px">
            <Stack spacing={4} px={{ base: "40px", lg: "0px" }}>
              <Text
                textTransform={"uppercase"}
                color={"green.500"}
                fontWeight={600}
                fontSize={"sm"}
                bg={useColorModeValue("green.100", "green.900")}
                p={2}
                alignSelf={"flex-start"}
                rounded={"md"}
              >
                Savings
              </Text>
              <Heading>Be confident you're getting the best</Heading>
              <Text color={"gray.500"} fontSize={"lg"} mb="20px">
                Compare the profiles of the best solar installers in your area to find the companies with the best warranties, reviews, and pricing.
              </Text>
              <Stack spacing={4} divider={<StackDivider borderColor={useColorModeValue("gray.100", "gray.700")} />}>
                <Feature icon={<Icon as={IoBuild} color={"yellow.500"} w={5} h={5} />} iconBg={useColorModeValue("yellow.100", "yellow.900")} text={"Discover installers"} />
                <Feature icon={<Icon as={IoSearchSharp} color={"purple.500"} w={5} h={5} />} iconBg={useColorModeValue("purple.100", "purple.900")} text={"Find the best rates"} />
                <Feature icon={<Icon as={IoLogoUsd} color={"green.500"} w={5} h={5} />} iconBg={useColorModeValue("green.100", "green.900")} text={"Start saving on electricity"} />
              </Stack>
            </Stack>
            <Flex>
              <LifetimeSavingsComponent />
            </Flex>
          </SimpleGrid>
        </Container>
        <VStack w="full" mb="40px">
          <Divider w="90vw" borderWidth="2px" borderColor="#566581" />
        </VStack>
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                icon: <BatteryFull className="text-yellow-500" size={48} />,
                title: "Energy Independence",
                description: "Reduce reliance on the grid and take control of your energy production.",
              },
              { icon: <DollarSign className="text-green-500" size={48} />, title: "Save Money", description: "Lower your electricity bills and increase your home's value." },
              { icon: <Leaf className="text-green-600" size={48} />, title: "Eco-Friendly", description: "Reduce your carbon footprint and contribute to a sustainable future." },
            ].map((item, index) => (
              <div key={index} className="text-center">
                <div className="mb-4 flex justify-center">{item.icon}</div>
                <h3 className="text-xl font-semibold mb-2">{item.title}</h3>
                <p className="text-gray-600">{item.description}</p>
              </div>
            ))}
          </div>
        </div>
        <VStack w="full">
          <Button
            colorScheme="green"
            mt="80px"
            fontSize="20px"
            p="40px"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            Get Your Instant Quote
          </Button>
        </VStack>
      </section>
      <VStack w="full" p={{ base: "10px", lg: "0px" }}>
        <Box w="full" maxW="1440px" pb="80px">
          <MotionVStack w="full" py="12vh" justifyContent="center" px={{ base: "0px", lg: "40px" }}>
            <EngagementInfo />
          </MotionVStack>
          <VStack w="full" mb="40px">
            <Divider w="90vw" borderWidth="2px" borderColor="#566581" />
          </VStack>
          <CoverageMap />
          <VStack w="full" mt="120px">
            <Divider w="90vw" borderWidth="2px" borderColor="#566581" />
          </VStack>
          <VStack w="full" mt="120px">
            <FrequentlyAsked />
          </VStack>
        </Box>
      </VStack>
    </Box>
  );
}

export default LandingPage;
