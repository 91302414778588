import { useEffect, useState } from "react";
import {
  Box,
  Text,
  Button,
  Stack,
  HStack,
  VStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  FormHelperText,
  FormControl,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  useDisclosure,
  ListItem,
  OrderedList,
  Link,
  Image,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useUserDataContext } from "../../Providers/LeadDataProvider";
import AddressSearchBar from "./AddressSearchBar";
import axios from "axios";
import { useSolarReadyContext } from "../../Providers/SolarReadyProvider";
import { TfiHelpAlt } from "react-icons/tfi";
import { User, UserUpdateOptions } from "../../API";
import logo from "../../Assets/logo.svg";

export default function CallToAction() {
  const [addressObject, setAddressObject] = useState({ long_name: "", state: "", county: "", city: "", postal_code: "", street: "", lat: 0, lng: 0 });
  const [monthlykWhValue, setMonthlykWhValue] = useState<number | undefined>(undefined);
  const [localKwHPrice, setlocalKwHPrice] = useState(0);
  const [annualKwHUsage, setAnnualKwHUsage] = useState(0);
  const [termsOfServiceChecked, setTermsOfServiceChecked] = useState(false);
  const navigate = useNavigate();
  const { userData, setUserData } = useUserDataContext();
  const { setSolarReadyData, setSolarReadyDesignTrackingId } = useSolarReadyContext();
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [errorMessage, setErrorMessage] = useState(false);
  const toast = useToast();

  const format = (val: string) => `$` + val;
  const parse = (val: string) => val.replace(/^\$/, "");

  // useEffect(() => {
  //   if (electricBillValue !== "" && localKwHPrice !== 0) {
  //     console.log("monthly usage:", (Number(electricBillValue) / localKwHPrice) * 0.8);
  //     setAnnualKwHUsage((Number(electricBillValue) / localKwHPrice) * 12 * 0.8);
  //   }
  // }, [electricBillValue, localKwHPrice]);

  useEffect(() => {
    if (monthlykWhValue && monthlykWhValue !== 0) {
      setAnnualKwHUsage(monthlykWhValue * 12);
    }
  }, [monthlykWhValue]);

  const handleToSAcknowledged = (e: { target: { checked: boolean | ((prevState: boolean) => boolean) } }) => {
    setTermsOfServiceChecked(e.target.checked);
  };

  async function fetchApiData(lat: string, lng: string) {
    const url = `https://4zpask5mw0.execute-api.us-east-2.amazonaws.com/dev/address-processing-layer?lat=${lat}&lng=${lng}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        setErrorMessage(true);
        throw new Error(`API call failed with status: ${response.status}`);
      }

      const data = await response.json();

      return data;
    } catch (error) {
      setErrorMessage(true);
      console.error("Error calling the API:", error);
      throw error;
    }
  }

  const callGoogleSolarApi = async (lat: string, lng: string) => {
    try {
      const googleSolarRes = axios.get(`https://4zpask5mw0.execute-api.us-east-2.amazonaws.com/dev/getGoogleSolarApi?lat=${lat}&lng=${lng}`);

      return googleSolarRes;
    } catch (error) {
      setErrorMessage(true);
      console.error("error getting response from google solar api:", error);
    }
  };

  const handleAddressSubmit = async (location: {
    long_name: string;
    state: string;
    county: string;
    city: string;
    street: string;
    postal_code: string;
    lat: number;
    lng: number;
  }) => {
    fetchApiData(`${location.lat}`, `${location.lng}`)
      .then((data) => {
        setlocalKwHPrice(data);
      })
      .catch((error) => {
        // Handle any errors
        setErrorMessage(true);
        console.error("Failed to fetch data from API:", error);
      });

    setAddressObject(location);

    callGoogleSolarApi(`${location.lat}`, `${location.lng}`).then((res) => {
      try {
        if (res) {
          // @ts-ignore
          setUserData((prevLeadData: User) => {
            if (
              JSON.stringify(prevLeadData) !==
              JSON.stringify({
                ...prevLeadData,
                google_solar_object: res.data,
              })
            ) {
              return {
                ...prevLeadData,
                google_solar_object: res.data,
              };
            }
            return prevLeadData;
          });
        }
      } catch (error: any) {
        setErrorMessage(true);
        console.error("Error processing response from google solar api");
      }
    });
  };

  const handleFormSubmit = () => {
    if (addressObject.long_name && monthlykWhValue) {
      // @ts-ignore
      setUserData((prevLeadData: User) => {
        if (
          JSON.stringify(prevLeadData) !==
          JSON.stringify({
            ...prevLeadData,
            address: addressObject.long_name,
            estimatedAnnualUsage: `${annualKwHUsage}`,
            updated_by: UserUpdateOptions.DYNAMO,
          })
        ) {
          return {
            ...prevLeadData,
            address: addressObject.long_name,
            estimatedAnnualUsage: `${annualKwHUsage}`,
          };
        }
        return prevLeadData;
      });

      // const placeSolarReadyOrder = async () => {
      //   console.log("address:", addressObject.long_name);
      //   try {
      //     const response = await axios.post("https://4zpask5mw0.execute-api.us-east-2.amazonaws.com/dev/orderSolarReadyDesign", {
      //       moduleParams: {
      //         dcRating: 400,
      //         length: 74,
      //         width: 41.1,
      //         unit: "inch",
      //         orientation: "Landscape",
      //         alignment: "Aligned",
      //         otherLosses: 12,
      //       },
      //       filters: {
      //         ignoreObstructions: false,
      //         minimumArraySize: 2,
      //         minimumPitch: 0,
      //         minimumProductionMultiplier: 0,
      //         minimumSystemProductionMultiplier: 0,
      //         minimumTSRF: 0,
      //       },
      //       address: {
      //         address: `${addressObject.long_name}`,
      //         productId: 95,
      //       },
      //       homeClassification: {
      //         classifierOne: {
      //           color: "green",
      //           operator: "",
      //           criteria: [
      //             {
      //               panelCountNumber: 4,
      //               panelCountOperator: ">",
      //             },
      //           ],
      //         },
      //         classifierTwo: {
      //           color: "yellow",
      //           operator: "OR",
      //           criteria: [
      //             {
      //               panelCountNumber: 15,
      //               panelCountOperator: ">",
      //               systemProductionMultiplierNumber: 1.6,
      //               systemProductionMultiplierOperator: ">=",
      //             },
      //             {
      //               panelCountNumber: 75,
      //               panelCountOperator: "<",
      //               systemProductionMultiplierNumber: 1,
      //               systemProductionMultiplierOperator: ">",
      //             },
      //           ],
      //         },
      //       },
      //       requestSource: "other",
      //     });

      //     // Check if the request was successful
      //     if (response.status !== 200) {
      //       throw new Error(`API call failed with status: ${response.status}`);
      //     }

      //     return response.data;
      //   } catch (error) {
      //     // Handle any errors that occur during the fetch
      //     console.error("Error calling the API:", error);
      //     throw error; // Rethrow the error if you want to handle it further up the call stack
      //   }
      // };

      // placeSolarReadyOrder().then((res) => {
      //   if (res) {
      //     console.log("SolarReady order", res);
      //     setSolarReadyDesignTrackingId(res);
      //   }
      // });

      // navigate("/purchase-or-quote");
      navigate("/home-details");
    } else {
      toast({
        title: "Missing Information",
        description: "Please enter your address and monthly kWh usage.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  return (
    <Box w="full" p={{ base: "20px", md: "40px" }} pt="0px">
      <Stack w="full" as={Box} textAlign={"center"} spacing={{ base: 8, md: "10px" }}>
        <VStack w="full">
          <HStack display={{ base: "none", md: "inline-flex" }}>
            <Image w={{ base: "72px", lg: "90px" }} h={{ base: "72px", lg: "90px" }} src={logo} />
            <Text w="full" fontWeight={"thin"} fontSize={{ base: "60px", lg: "74px" }} lineHeight={1} fontFamily="'new-astro', sans-serif" color="rgba(0,0,0,.8)">
              Klaravia
            </Text>
          </HStack>
          <Image w={{ base: "80px", sm: "100px" }} h={{ base: "100px", sm: "120px" }} display={{ base: "block", md: "none" }} mb="0px" src={logo} />
          <Text
            w="full"
            textAlign="center"
            fontSize={{ base: "20px", lg: "24px" }}
            fontWeight={{ base: "bold" }}
            mt="10px"
            fontFamily="'eurostile', sans-serif"
            color={{ base: "black", md: "gray.600" }}
            display={{ base: "none", md: "block" }}
          >
            No commissions. No sales calls. No hidden agendas.
          </Text>
          <Text
            w="full"
            textAlign="center"
            fontSize={{ base: "26px", sm: "32px", md: "24px" }}
            fontWeight={{ base: "bold", md: "thin" }}
            mt="0px"
            fontFamily="'eurostile', sans-serif"
            color={{ base: "gray.700", md: "gray.600" }}
            display={{ base: "block", md: "none" }}
          >
            Go solar on your own terms
          </Text>
        </VStack>
        <Stack w={{ base: "100%", md: "700px" }} direction={"column"} spacing={3} align={"center"} alignSelf={"center"} position={"relative"} mt={{ base: "10px", md: "20px" }}>
          {/* TODO: add form and error handling */}
          <FormControl w="full">
            <HStack w="full">
              <VStack w={{ base: "60%", lg: "75%" }}>
                <FormHelperText fontFamily="'eurostile', sans-serif" fontSize={{ base: "10px", md: "14px" }}>
                  Address
                </FormHelperText>
                <AddressSearchBar onSubmit={handleAddressSubmit} />
              </VStack>
              <VStack w={{ base: "40%", lg: "25%" }}>
                <HStack w="full" alignItems="flex-end" justifyContent="center">
                  <FormHelperText fontFamily="'eurostile', sans-serif" fontSize={{ base: "10px", md: "14px" }}>
                    Monthly kWh Usage
                  </FormHelperText>
                  <TfiHelpAlt color="RGBA(0, 0, 0, 0.48)" onClick={() => onToggle()} cursor="pointer" />
                </HStack>
                <Popover placement="top-end" isOpen={isOpen} onClose={onClose}>
                  <PopoverTrigger>
                    <NumberInput
                      w="full"
                      fontFamily="'eurostile', sans-serif"
                      onChange={(valueString) => setMonthlykWhValue(Number(valueString))}
                      placeholder="0"
                      value={monthlykWhValue}
                      variant="unstyled"
                      step={1}
                      isRequired
                      min={200}
                    >
                      <NumberInputField h="60px" border="1px solid black" bg="rgba(255,255,255,.9)" shadow="lg" pl="8px" />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    {/* <PopoverCloseButton /> */}
                    <PopoverHeader fontWeight="bold">How to find your monthly kWh usage</PopoverHeader>
                    <PopoverBody>
                      <VStack w="full" p="10px">
                        <OrderedList w="full" textAlign="left" fontSize="14px">
                          <ListItem w="full">
                            <Text w="full">Locate your most recent electric bill.</Text>
                          </ListItem>
                          <br />
                          <ListItem w="full">
                            <Text w="full">Find the section of your bill that shows how many kilowatt hours (kWh) you consumed that month.</Text>
                          </ListItem>
                          <br />
                          <ListItem w="full">
                            <Text w="full">Take a picture or screenshot of the bill and keep it on hand for later on in the process.</Text>
                          </ListItem>
                        </OrderedList>
                      </VStack>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </VStack>
            </HStack>
            <VStack w={{ base: "100%", lg: "100%" }} mt={{ base: "10px", lg: "10px" }} gap="0px">
              <Button
                w="full"
                h="60px"
                type="submit"
                bgColor="#141414"
                color="white"
                _hover={{ bgColor: "gray.800" }}
                fontFamily="'eurostile', sans-serif"
                onClick={handleFormSubmit}
                // isDisabled={addressNotServiceable || !termsOfServiceChecked ? true : false}
              >
                Get Started
              </Button>
              <FormHelperText fontFamily="'eurostile', sans-serif" color="gray.500" fontSize="12px">
                By clicking the "Get Started" button above, you are agreeing to Klaravia's <Link href="/terms-of-service">terms of service</Link>.
              </FormHelperText>
            </VStack>
          </FormControl>
        </Stack>
      </Stack>
    </Box>
  );
}
