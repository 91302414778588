import { useRef } from "react";
import { Step, StepDescription, StepIcon, StepIndicator, StepNumber, StepSeparator, StepStatus, StepTitle, Stepper, useSteps, Box, HStack, Button } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const UserProgressTracker = ({ setHoverIndex, hoverIndex, projectStatusIndex }: { setHoverIndex: (index: number) => void; hoverIndex: number; projectStatusIndex: number }) => {
  const stepperRef = useRef(null);

  const scrollStepper = (direction: string) => {
    if (stepperRef.current) {
      const scrollAmount = direction === "left" ? -380 : 380; // Adjust scroll amount as needed
      //@ts-ignore
      stepperRef.current.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });
    }
  };
  const steps = [
    { title: "Electric Bill", description: "Your electric bill is being reviewed" },
    { title: "Final Design", description: "Awaiting electric bill review" },
    { title: "Documents + Deposit", description: "Awaiting final design order" },
    { title: "Site Survey", description: "Awaiting contract signature" },
    { title: "Permits & Approvals", description: "Awaiting site survey" },
    { title: "Payment", description: "Awaiting permits & approvals" },
    { title: "Installation", description: "Awaiting payment" },
    { title: "System Turn On", description: "Awaiting install" },
  ];

  const { activeStep } = useSteps({
    index: projectStatusIndex,
    count: steps.length,
  });

  return (
    <Box bg="white" borderRadius="60px" p="20px" shadow="lg" fontFamily="'eurostile', sans-serif">
      <HStack w="full">
        <Button leftIcon={<ChevronLeftIcon boxSize="40px" />} variant="unstyled" color="black" onClick={() => scrollStepper("left")} />
        <Stepper colorScheme="white" size="sm" index={activeStep} overflowX="scroll" className="hide-scrollbar" ref={stepperRef}>
          {steps.map((step, index) => (
            <Step key={index} style={{ minWidth: "320px" }}>
              <HStack
                cursor="pointer"
                borderRadius="60px"
                p="10px"
                pr="14px"
                bg={index === projectStatusIndex ? "white" : index < projectStatusIndex ? "green.500" : hoverIndex === index ? "gray.100" : ""}
                border={index === projectStatusIndex ? "2px solid #38A169" : ""}
                color={index === projectStatusIndex ? "black" : index < projectStatusIndex ? "white" : hoverIndex === index ? "black" : "black"}
                _hover={{ bg: "gray.100", color: "black", border: "2px solid #38A169" }}
                onClick={() => setHoverIndex(index)}
              >
                <StepIndicator style={{ border: index === projectStatusIndex ? "2px solid #38A169" : "" }}>
                  <StepStatus
                    complete={<StepIcon />}
                    incomplete={<StepNumber style={{ position: "relative", bottom: "1px" }} />}
                    active={<StepNumber style={{ position: "relative", bottom: "1px" }} />}
                  />
                </StepIndicator>

                <Box flexShrink="0">
                  <StepTitle style={{ fontWeight: 700 }}>{step.title}</StepTitle>
                  <StepDescription style={{ color: "inherit" }}>{step.description}</StepDescription>
                </Box>
              </HStack>

              <StepSeparator style={{ height: "10px", minWidth: "60px", borderRadius: "20px", backgroundColor: `${index < projectStatusIndex ? "#38A169" : ""}` }} />
            </Step>
          ))}
        </Stepper>
        <Button rightIcon={<ChevronRightIcon boxSize="40px" />} variant="unstyled" color="black" onClick={() => scrollStepper("right")} />
      </HStack>
    </Box>
  );
};

export default UserProgressTracker;
