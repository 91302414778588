import React, { useEffect, useState } from "react";
import { Box, VStack, HStack, Text, Image, Badge, Input, Select, Button, InputGroup, InputLeftElement, Avatar } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import doorSalesman from "../Blogs/BlogImages/doorSalesman.webp";
import solarTown from "../Blogs/BlogImages/solarTown.webp";
import questionInABottle from "../Blogs/BlogImages/questionInABottle.webp";
import solarCalc from "../Blogs/BlogImages/solarCalc.webp";
import homeVal from "../Blogs/BlogImages/aiHomeValueBlog.jpg";
import { IoSearch } from "react-icons/io5";
import josiahLongenecker from "../Blogs/BlogImages/josiahLongenecker.jpg";

const blogs = [
  {
    title: "Shining a Light on Solar",
    subTitle: "How Klaravia is Fighting for Homeowners",
    coverImage: doorSalesman,
    category: ["Solar Problems", "Financial Considerations"],
    link: "/blogs/shining-a-light-on-solar",
  },
  {
    title: "To Solar or not to Solar?",
    subTitle: "The benefits and drawbacks of installing solar as a homeowner.",
    coverImage: solarTown,
    category: ["Solar Basics", "Financial Considerations"],
    link: "/blogs/to-solar-or-not-to-solar",
  },
  {
    title: "Top 10 Solar Questions Answered",
    subTitle: "Understand solar better by seeing what others have to ask.",
    coverImage: questionInABottle,
    category: ["Solar Basics", "Financial Considerations", "Environmental Impact"],
    link: "/blogs/top-10-solar-questions-answered",
  },
  {
    title: "Understanding Solar Roof Tax Incentives",
    subTitle: "",
    coverImage: solarCalc,
    category: ["Financial Considerations", "Policy and Regulation"],
    link: "/blogs/understanding-solar-roof-tax-incentives",
  },
  {
    title: "Will Solar Increase My Home’s Value?",
    subTitle: "Here's what you need to know",
    coverImage: homeVal,
    category: ["Financial Considerations", "Solar Basics"],
    link: "/blogs/will-solar-increase-my-home-value",
  },
];

const categories = [
  "All Categories",
  "Solar Basics",
  "Solar Problems",
  "Financial Considerations",
  "Installation Process",
  "Energy Savings and Efficiency",
  "Environmental Impact",
  "Policy and Regulation",
];

const BlogsIntro = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("All Categories");
  const [filteredBlogs, setFilteredBlogs] = useState(blogs);

  const handleBlogClick = (link: string) => {
    navigate(link);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const filtered = blogs.filter((blog) => {
      const matchesSearch = blog.title.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesCategory = selectedCategory === "All Categories" || blog.category.includes(selectedCategory);
      return matchesSearch && matchesCategory;
    });
    setFilteredBlogs(filtered);
  }, [searchTerm, selectedCategory]);

  return (
    <Box w="full" pt="80px" pb="80px" bg="#f4eee9" fontFamily="'Inter', sans-serif" display="flex" justifyContent="center">
      <VStack w="full" maxW="1240px" spacing={8} align="center">
        <VStack h="300px" spacing={2} textAlign="center" justifyContent="center">
          <Badge colorScheme="purple" px={3} py={1} borderRadius="full">
            Read Our Blog
          </Badge>
          <Text fontSize={{ base: "3xl", md: "4xl" }} fontWeight="bold">
            Browse Our Resources
          </Text>
          <Text fontSize="lg" color="gray.600" maxW="90%">
            We're committed to providing you with high quality educational resources on solar.
          </Text>
        </VStack>

        <HStack w="full" alignItems="flex-start" spacing={"80px"} px={4}>
          <VStack w={{ base: "full", md: "250px" }} spacing={6} align="stretch" display={{ base: "none", md: "unset" }}>
            <VStack align="stretch" spacing={4}>
              <Text fontWeight="semibold">Search</Text>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <IoSearch color="gray" />
                </InputLeftElement>
                <Input placeholder="Search articles..." borderColor="gray" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
              </InputGroup>
            </VStack>

            <VStack align="stretch" spacing={4}>
              <Text fontWeight="semibold">Filter</Text>
              <Select placeholder="Filter articles..." borderColor="gray" value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
                {categories.map((category, index) => (
                  <option key={index} value={category}>
                    {category}
                  </option>
                ))}
              </Select>
            </VStack>
          </VStack>

          <Box flex={1}>
            <VStack spacing={8} align="stretch">
              {filteredBlogs.slice(0, 1).map((blog, index) => (
                <Box
                  w="full"
                  key={index}
                  borderRadius="20px"
                  overflow="hidden"
                  onClick={() => handleBlogClick(blog.link)}
                  cursor="pointer"
                  _hover={{ bgColor: "rgba(255,255,255,.6)" }}
                  display="flex"
                  flexDir={{ base: "column", lg: "row" }}
                  mb="10px"
                >
                  <Image w={{ base: "full", lg: "60%" }} src={blog.coverImage} borderRadius="20px" alt={blog.title} objectFit="cover" h="300px" />
                  <VStack minW="40%" p={6} align="stretch" spacing={2}>
                    <Text fontSize="24px" fontWeight="semibold">
                      {blog.title}
                    </Text>
                    <Text fontSize="sm" color="gray.600">
                      {blog.subTitle}
                    </Text>
                    <HStack justify="space-between">
                      <Text fontSize="sm" color="gray.500">
                        Josiah Longenecker
                      </Text>
                      <Text fontSize="sm" color="gray.500">
                        5min read
                      </Text>
                    </HStack>
                  </VStack>
                </Box>
              ))}
              <HStack w="full" justifyContent="space-between" flexWrap="wrap" spacing={{ base: 8, lg: 4 }} align="stretch">
                {filteredBlogs.slice(1, filteredBlogs.length).map((blog, index) => (
                  <Box
                    w={{ base: "full", lg: "48%" }}
                    key={index}
                    borderRadius="20px"
                    overflow="hidden"
                    onClick={() => handleBlogClick(blog.link)}
                    cursor="pointer"
                    _hover={{ bgColor: "rgba(255,255,255,.6)" }}
                  >
                    <Image src={blog.coverImage} borderRadius="20px" alt={blog.title} objectFit="cover" h="200px" w="full" />
                    <VStack p={4} align="stretch" spacing={2}>
                      <Text fontSize="xl" fontWeight="semibold">
                        {blog.title}
                      </Text>
                      <Text fontSize="sm" color="gray.600">
                        {blog.subTitle}
                      </Text>
                      {/* <VStack w="full" alignItems="flex-start">
                        <Avatar size="sm" src={josiahLongenecker} />
                        <HStack w="full" justify="space-between">
                          <Text fontSize="sm" color="gray.500">
                            Josiah Longenecker
                          </Text>
                          <Text fontSize="sm" color="gray.500">
                            5min read
                          </Text>
                        </HStack>
                      </VStack> */}
                    </VStack>
                  </Box>
                ))}
              </HStack>
            </VStack>
          </Box>
        </HStack>
      </VStack>
    </Box>
  );
};

export default BlogsIntro;
