import { Box, VStack, Text, Link } from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import doorSalesman from "../Blogs/BlogImages/doorSalesman.webp";
import solarTown from "../Blogs/BlogImages/solarTown.webp";
import questionInABottle from "../Blogs/BlogImages/questionInABottle.webp";
import solarCalc from "../Blogs/BlogImages/solarCalc.webp";

const BlogToSolarOrNotToSolar = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBlogLinkClick = (link: string) => {
    navigate(link);
  };

  return (
    <Box w="full" minH="90vh" pb="60px" pt="80px">
      <Box w="full" h={{ base: "200px", lg: "500px" }} bgImage={solarTown} bgSize="cover" bgRepeat="no-repeat" bgPosition="center" />
      <VStack w="full" gap="40px" align="stretch" p="20px">
        <VStack w="full">
          <br />
          <VStack w="full" mt="20px" lineHeight={1} maxW="800px">
            <Text w="full" fontWeight="bold" fontSize={{ base: "28px", lg: "32px" }}>
              To Solar or Not to Solar?
            </Text>
          </VStack>
          <br />
          <Text fontSize="20px" maxW="800px">
            Thinking about making the jump and installing solar panels on your roof? It’s a big decision, and one that is best made after carefully weighing the potential costs
            with the potential benefits for your specific situation. In this article, we will discuss the ups and downs of solar roof installation and help equip you to make an
            informed decision on whether solar is the right move for you.
          </Text>
          <br />
          <Text w="full" textAlign="center" fontWeight="bold" fontSize="24px" maxW="800px">
            The Benefits
          </Text>
          <br />
          <Text w="full" fontWeight="bold" fontSize="18px" maxW="800px">
            1. Savings Galore: &#129297;
          </Text>
          <Text fontSize="20px" maxW="800px">
            Death, taxes, and bills. No matter how intentional you are about keeping your monthly bills low, they always seems to creep higher and higher. Car insurance, fuel,
            groceries, and utility bills all have an uncanny ability to grow faster than our paychecks can keep up with. What if I told you there was a way to take one of your
            highest and fastest increasing bills and reduce it to zero? Close your eyes and picture your bank statement. Look for the constant, pestering little section that holds
            your energy bill each month. Now imagine that number being erased completely. Think of the endless ways you could put that excess cash to work on things you enjoy
            rather than that monotonous and pesky energy bill! That’s what a solar roof can do for you. With solar panels, you can save big on your electricity costs and in many
            cases reduce your monthly bill down to nothing. Solar puts money back into your pocket for a change. Sweet, right?
          </Text>
          <br />
          <VStack w="full" maxW="800px">
            <Text w="full" fontWeight="bold" fontSize="18px" maxW="800px">
              2. Freedom from the Grid: &#9889;
            </Text>
            <Text fontSize="20px">
              Tired of worrying about the power going out? Just last year my grandfather lost a whole freezer full of steaks to a power outage in his area while he was on vacation.
              The smell of spoiled meat was almost as bad as the hundreds of dollars worth of meat lost. Problems like this are only increasing as homeowners become more and more
              dependent on a grid that is struggling to support demand. With solar, you can be more self-sufficient. No more relying on the grid or your local energy company that
              cares more about their profit margins than your wellbeing – just you and the sun, taking your future by the horns and powering your home forward.
            </Text>
          </VStack>
          <br />
          <VStack w="full" maxW="800px">
            <Text w="full" fontWeight="bold" fontSize="18px" maxW="800px">
              3. Environmental Benefits: &#127758;
            </Text>
            <Text fontSize="20px">
              We all want to do our part for Mother Earth, right? While modern technology and development makes life more convenient for us, it also leaves behind a nasty little
              carbon footprint that keeps growing and growing! It’s becoming increasingly important that everyone does their part in keeping the world a beautiful place for the
              next generation. Solar roofs produce clean, renewable, and totally green energy. By going solar, you’re helping reduce your carbon footprint and building a
              sustainably green system to keep our planet healthy one sunny day at a time.
            </Text>
          </VStack>
          <br />
          <VStack w="full" maxW="800px">
            <Text w="full" fontWeight="bold" fontSize="18px" maxW="800px">
              4. Boost Your Home’s Value: &#127968;
            </Text>
            <Text fontSize="20px">
              It shouldn’t be a surprise that homes with solar roofs sell for more. What might surprise you is that the average increase in value of a home after going green is
              five percent. That’s about a $20,000 increase on average! Solar is a hot commodity in the real estate market, so installing panels can actually increase the resale
              value of your home in a big way. Let that sink in… not only can you say hasta la bye-bye to your ever-increasing energy bill, but you can simultaneously bump up the
              value of your home. Talk about a win-win!
            </Text>
          </VStack>
          <br />
          <VStack w="full" maxW="800px">
            <Text w="full" fontWeight="bold" fontSize="18px" maxW="800px">
              5. Federal Savings: &#128176;
            </Text>
            {/* TODO: insert article link */}
            <Text fontSize="20px">
              With Federal incentives and tax credits available for homeowners switching to solar, there's never been a better time to green-ify your home. These incentives can
              help offset the upfront costs of solar panels, making them more affordable for homeowners. This year, qualifying homeowners that install solar can receive a tax
              credit worth 30% of the cost of installation with no cap! Most residential homeowners qualify for the tax credit, but to learn more about "who and how" you can refer
              to our “Claiming my Federal Tax Credit” article here: __________. Your state also might chip in some help as well, though each state differs in their current
              offerings.
            </Text>
          </VStack>
          <br />
          <Text fontSize="20px" maxW="800px">
            It's clear that you and the planet can benefit in big ways by going solar, so why doesn’t everyone pull the trigger? To answer that, you need to clearly understand some
            of the potential obstacles that arise when going green.
          </Text>
          <br />
          <Text w="full" textAlign="center" fontWeight="bold" fontSize="24px" maxW="800px">
            The Drawbacks
          </Text>
          <br />
          <Text w="full" fontWeight="bold" fontSize="18px" maxW="800px">
            1. Upfront Costs
          </Text>
          <Text fontSize="20px" maxW="800px">
            Okay, let’s be real: solar roofs can’t be purchased with the loose change you find in the couch cushions. The initial investment can be steep depending on how you
            decide to pay for it. This is where understanding and finding the right financing options is vital to making solar a long-term asset rather than a nagging liability.
            Making sure you compare your financing options and find the best fit for you is just as important as any other step in the solar adoption process. It’s also important
            to take advantage of the Federal tax incentives that exist and see what help your state has available to you.
          </Text>
          <br />
          <VStack w="full" maxW="800px">
            <Text w="full" fontWeight="bold" fontSize="18px" maxW="800px">
              2. Sunlight Not Included:
            </Text>
            <Text fontSize="20px">
              We love the sun, but unfortunately it’s not always shining through the clouds. Solar energy production can be affected by weather and daylight hours, so your panels
              might not crank out power 24/7. This means it’s extremely important to ensure optimal panel placement on your roof. This is best done by using roof design tools such
              as the one available at{" "}
              <Link href="https://www.klaravia.co" color="blue">
                www.klaravia.co
              </Link>{" "}
              to make sure you’re getting every ounce of sunlight. It’s also worth considering the purchase of a solar battery with your roof. Batteries allow you to harness and
              store surplus energy generated by your solar panels during daylight hours which you can tap into when it’s needed.
            </Text>
          </VStack>
          <br />
          <VStack w="full" maxW="800px">
            <Text w="full" fontWeight="bold" fontSize="18px" maxW="800px">
              3. Beauty is in the Eye of the Beholder:
            </Text>
            <Text fontSize="20px">
              While some people think solar panels are sleek and modern, others aren’t as enthusiastic about the curb appeal that a solar roof brings along. Some panels can be
              bulkier and stick out more than others, and improper placement can look awkward. It’s totally okay to have different tastes when it comes to what you prefer in your
              roof’s visuals! Just know that installing solar panels could definitely change the look of your home, for better or worse.
            </Text>
          </VStack>
          <br />
          <VStack w="full" maxW="800px">
            <Text w="full" fontWeight="bold" fontSize="18px" maxW="800px">
              4. Maintenance May Be Required:
            </Text>
            <Text fontSize="20px">
              Like any home appliance, solar panels need a little TLC from time to time. While they're pretty low-maintenance overall, you might need to invest some time and money
              in upkeep over the years. Some installers offer warranties and/or periodic check ups for your new roof. It’s important to understand what benefits are available to
              you and compare a few installers before deciding which company is the best fit to place your new solar roof.
            </Text>
          </VStack>
          <br />
          <Text fontSize="20px" maxW="800px">
            So, should you go solar? It's a personal decision that depends on your priorities and your budget. While solar panels offer many benefits, like lower energy bills and a
            smaller carbon footprint, they also come with some considerations, like upfront costs and navigating different options. Take your time, do your research, and make sure
            you take full advantage of the resources that are available to you. Ready to learn more? Visit{" "}
            <Link href="https://www.klaravia.co" color="blue">
              www.klaravia.co
            </Link>{" "}
            today and gain access to invaluable educational resources. Through our revolutionary platform, you can get a custom solar design and compare quotes and benefits from
            installers and lending institutions ready to help make your green dream a reality.
          </Text>
        </VStack>
        <br />
        <VStack w="full">
          <VStack w={{ base: "full", lg: "35vw" }} alignItems="center" gap="20px" maxW="800px">
            <VStack w="full" cursor="pointer" alignItems="center" onClick={() => handleBlogLinkClick("/blogs/top-10-solar-questions-answered")}>
              <Box w="full" minH="250px" h="auto" bgImage={questionInABottle} bgSize="cover" bgRepeat="no-repeat" bgPosition="top" />
              <VStack w="full" mt="10px" lineHeight={1} pb="10px">
                <Text w="full" color="gray.500">
                  Better understanding solar
                </Text>
                <Text w="full" fontWeight="bold" fontSize="24px">
                  Top 10 Solar Questions Answered
                </Text>
              </VStack>
            </VStack>

            <VStack w="full" cursor="pointer" onClick={() => handleBlogLinkClick("/blogs/understanding-solar-roof-tax-incentives")}>
              <Box w="full" minH="250px" h="auto" bgImage={solarCalc} bgSize="cover" bgRepeat="no-repeat" bgPosition="top" />
              <VStack w="full" mt="10px" lineHeight={1} pb="10px">
                <Text w="full" color="gray.500">
                  Solar tax incentives
                </Text>
                <Text w="full" fontWeight="bold" fontSize="24px">
                  Understanding Solar Roof Tax Incentives
                </Text>
              </VStack>
            </VStack>

            <VStack w="full" cursor="pointer" onClick={() => handleBlogLinkClick("/blogs/shining-a-light-on-solar")}>
              <Box w="full" minH="250px" h="auto" bgImage={doorSalesman} bgSize="cover" bgRepeat="no-repeat" bgPosition="top" />
              <VStack w="full" mt="10px" lineHeight={1} pb="10px">
                <Text w="full" color="gray.500">
                  How Klaravia is Fighting for Homeowners
                </Text>
                <Text w="full" fontWeight="bold" fontSize="24px">
                  Shining a Light on Solar
                </Text>
              </VStack>
            </VStack>
          </VStack>
        </VStack>
      </VStack>
    </Box>
  );
};
export default BlogToSolarOrNotToSolar;
