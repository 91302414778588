import React, { useState, createContext, useContext, useEffect, useRef } from "react";
import { User, UserUpdateOptions } from "../API";
import { Auth } from "aws-amplify";
import { useUpdateUser } from "../hooks/userHooks";
import { useAuth } from "./AuthenticationProvider";

export type UserWithoutTypeName = Omit<User & { [key: string]: any }, "__typename">;

const defaultUserObject: UserWithoutTypeName = {
  id: "",
  updated_by: UserUpdateOptions.DYNAMO,
};

const getInitialUserObject = () => {
  const savedUserObject = localStorage.getItem("userObject");
  return savedUserObject ? JSON.parse(savedUserObject) : defaultUserObject;
};

const userDataContext = createContext({
  userData: defaultUserObject,
  setUserData: (userObj: User) => {},
  googleSolarObject: {},
  setGoogleSolarObject: (userObj: User) => {},
});

export const useUserDataContext = () => {
  return useContext(userDataContext);
};

const UserDataProvider = ({ children }: { children: React.ReactNode }) => {
  const [userData, setUserData] = useState<User>(getInitialUserObject);
  const [googleSolarObject, setGoogleSolarObject] = useState<any>();
  const prevUserObject = useRef(defaultUserObject);
  const { userData: userD } = useAuth();
  const { updateUser } = useUpdateUser();

  function deepEqual(obj1: UserWithoutTypeName, obj2: UserWithoutTypeName): boolean {
    if (obj1 === obj2) {
      return true; // for the same reference or primitive values
    }
    if (typeof obj1 !== "object" || typeof obj2 !== "object" || obj1 == null || obj2 == null) {
      return false; // if either is not an object or is null
    }

    const keysObj1 = Object.keys(obj1).filter((key) => key !== "id" || "userId");
    const keysObj2 = Object.keys(obj2).filter((key) => key !== "id" || "userId");

    if (keysObj1.length !== keysObj2.length) {
      return false; // different number of properties
    }

    for (let key of keysObj1) {
      if (!keysObj2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
        return false; // if keys are not equal or if values are not deeply equal
      }
    }
    return true;
  }

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const userIsSignedIn = await Auth.currentAuthenticatedUser().then((user) => {
  //         return true;
  //       });
  //       if (userIsSignedIn && !deepEqual(prevUserObject.current, userData)) {
  //         if (userData && userD && !userData.id) {
  //           let userDCopy = { ...userD };
  //           // @ts-ignore
  //           if (userData.google_solar_object) {
  //             // @ts-ignore
  //             delete userData.google_solar_object;
  //           }

  //           userDCopy.updated_by = UserUpdateOptions.DYNAMO;
  //           //@ts-ignore
  //           delete userDCopy.createdAt;
  //           delete userDCopy.installer;
  //           delete userDCopy.installerId;
  //           delete userDCopy.state;
  //           delete userDCopy.stateId;
  //           //@ts-ignore
  //           delete userDCopy.updatedAt;
  //           delete userDCopy.utility_provider;
  //           // @ts-ignore
  //           delete userDCopy.__typename;

  //           userData.id = userDCopy.id;
  //           updateUser({
  //             variables: {
  //               input: userDCopy,
  //             },
  //           });
  //           setUserData(userDCopy);
  //           prevUserObject.current = userDCopy;
  //         } else {
  //           updateUser({
  //             variables: {
  //               input: userData,
  //             },
  //           });
  //           prevUserObject.current = userData;
  //         }
  //       }
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   })();
  // }, [userData]);

  useEffect(() => {
    (async () => {
      try {
        const userIsSignedIn = await Auth.currentAuthenticatedUser().then((user) => {
          return true;
        });

        if (userIsSignedIn && !deepEqual(prevUserObject.current, userData)) {
          updateUser({
            variables: {
              input: userData,
            },
          });
          prevUserObject.current = userData;
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [userData]);

  return (
    <userDataContext.Provider
      value={{
        userData,
        setUserData,
        googleSolarObject,
        setGoogleSolarObject,
      }}
    >
      {children}
    </userDataContext.Provider>
  );
};

export default UserDataProvider;
