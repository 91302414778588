import React, { useState } from "react";
import { Box, Text, HStack, VStack, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, useDisclosure, Button } from "@chakra-ui/react";

const FinalDesign = () => {
  const { isOpen: pictureIsOpen, onOpen: pictureOnOpen, onClose: pictureOnClose } = useDisclosure();
  const [pictureSrc, setPictureSrc] = useState("");

  return (
    <Box w="full" minH="90vh" bg="#f4eee9">
      <VStack w="full" alignItems="flex-start" p="40px">
        <Text fontSize="32px" fontWeight="bold" mb="14px" pl="8px">
          Final Design
        </Text>
        <HStack w="full" minH="200px" bgColor="rgba(0,0,0,.1)" borderRadius="12px" align="stretch" gap="20px" p="14px" justifyContent="center" alignItems="center">
          <Text fontSize="20px">After you order your final design, you will be able to view it here.</Text>
        </HStack>
      </VStack>
      {/* <VStack w="full" alignItems="flex-start" p="40px">
        <Text fontSize="32px" fontWeight="bold" mb="14px" pl="8px">
          Final Design
        </Text>
        <HStack w="full" minH="200px" bgColor="rgba(0,0,0,.1)" borderRadius="12px" align="stretch" gap="20px" p="14px" justifyContent="center" alignItems="center">
          <Box
            p="10px"
            bgColor="white"
            borderRadius="12px"
            shadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
            cursor="pointer"
            _hover={{ shadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}
            onClick={() => {
              setPictureSrc("");
              pictureOnOpen();
            }}
          >
            <Image src="" />
            <Text mt="10px">{`Professional Design`}</Text>
            <HStack w="full" justifyContent="center" my="10px">
              <Button colorScheme="green">Accept</Button>
              <Button variant="outline" colorScheme="red">
                Reject
              </Button>
            </HStack>
          </Box>
          <PictureModal src={pictureSrc} pictureIsOpen={pictureIsOpen} pictureOnClose={pictureOnClose} />
        </HStack>
      </VStack> */}
    </Box>
  );
};
export default FinalDesign;

const PictureModal = ({ src, pictureIsOpen, pictureOnClose }: { src: string; pictureIsOpen: boolean; pictureOnClose: () => void }) => {
  return (
    <Modal isOpen={pictureIsOpen} onClose={pictureOnClose}>
      <ModalOverlay />
      <ModalContent w="full" maxW="800px" pt="40px" resize="both">
        <ModalCloseButton />
        <ModalBody>
          <Image w="full" src={src || "fallback url"} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
