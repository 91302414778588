import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Stack,
  Text,
  Image,
  VStack,
} from "@chakra-ui/react";
import { Auth } from "aws-amplify";
import { ViewOffIcon, ViewIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Providers/AuthProvider";
import landingHome from "../Assets/homeBg.png";
import logo from "../../Assets/logo.svg";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const auth = useAuth();
  const navigate = useNavigate();

  const handleClick = () => setShowPassword(!showPassword);

  const handleSignIn = async () => {
    try {
      const cognitoUser = await Auth.signIn(email, password);
      auth.setCognitoUser(cognitoUser);
      // User signed in successfully, redirect to dashboard or home page
      // useHistory from react-router-dom could be used here for redirection
      navigate("/LandingPage");
    } catch (error: any) {
      setError(error.message);
      console.error("Error signing in", error);
    }
  };

  const handleKeyDown = (event: { key: string }) => {
    if (event.key === "Enter") {
      handleSignIn();
    }
  };

  return (
    <Box w="full" minH="100vh" bgColor="#f4eee9" pt="10vh" fontFamily="'eurostile', sans-serif" bgImage={landingHome} bgSize="contain" bgRepeat="no-repeat" bgPosition="bottom">
      <Container maxW="lg" py={{ base: "12", md: "24" }} px={{ base: "0", sm: "8" }}>
        <Stack spacing="8">
          <Stack spacing="6">
            <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
              <VStack w="full">
                <Image w="60px" h="60px" mb="10px" src={logo} />
                <Heading size={{ base: "xs", md: "sm" }}>Log in to your account</Heading>
              </VStack>
            </Stack>
          </Stack>
          <Box
            py={{ base: "0", sm: "8" }}
            px={{ base: "4", sm: "10" }}
            bg={{ base: "#f4eee9", sm: "white" }}
            boxShadow={{ base: "none", sm: "md" }}
            borderRadius={{ base: "none", sm: "xl" }}
          >
            <Stack spacing="6">
              <Stack spacing="5">
                <FormControl>
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input id="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} borderColor={{ base: "#141414", sm: "gray.300" }} />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="password">Password</FormLabel>
                  <InputGroup size="md">
                    <Input
                      pr="4.5rem"
                      type={showPassword ? "text" : "password"}
                      border="1px solid rgb(238, 239, 241)"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      onKeyDown={handleKeyDown}
                      borderColor={{ base: "#141414", sm: "gray.300" }}
                    />
                    <InputRightElement width="3rem">
                      <IconButton
                        h="1.75rem"
                        aria-label="Password"
                        size="sm"
                        bgColor={{ base: "gray.50", sm: "gray.100" }}
                        icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                        onClick={handleClick}
                      />
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
              </Stack>
              <HStack justify="space-between">
                <Checkbox defaultChecked>Remember me</Checkbox>
                <Button variant="text" size="sm">
                  Forgot password?
                </Button>
              </HStack>
              <Stack spacing="6">
                <Text color="red.500">{error}</Text>
                <Button onClick={handleSignIn} bgColor={{ base: "#566581", sm: "gray.100" }} color={{ base: "white", sm: "gray.800" }}>
                  Log in
                </Button>
                {/* <HStack>
                  <Divider />
                  <Text textStyle="sm" whiteSpace="nowrap" color="fg.muted">
                    or continue with
                  </Text>
                  <Divider />
                </HStack> */}
              </Stack>
            </Stack>
          </Box>
          <Text w="full" textAlign="center">
            Don't have an account?{" "}
            <Link href="/user-sign-up" color="blue">
              Sign up
            </Link>
          </Text>
        </Stack>
      </Container>
    </Box>
  );
};
export default Login;
