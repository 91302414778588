import { Container, SimpleGrid, Image, Flex, Heading, Text, Stack, StackDivider, useColorModeValue, VStack, Button, HStack, Icon } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import learn from "../Assets/undraw_education.svg";
import website from "../Assets/undraw_website.svg";
import join from "../Assets/undraw_join.svg";
import { ReactElement } from "react";

export default function EngagementSectionLearningPrompt() {
  const navigate = useNavigate();

  return (
    <VStack w="full" maxW={{ base: "full", "2xl": "1440px" }} px={{ base: "0px", lg: "20px" }}>
      <Container w="full" maxW={"full"} py={12} mb="5%">
        <Flex w="full" gap={{ base: "80px", xl: "0px" }} justifyContent="center" flexDirection={{ base: "column", xl: "row" }}>
          {/* <Stack spacing={4}>
            <Text
              textTransform={"uppercase"}
              fontWeight={600}
              fontSize={"sm"}
              color="white"
              fontFamily="'eurostile', sans-serif"
              bg={"#566581"}
              p={2}
              alignSelf={"flex-start"}
              rounded={"md"}
            >
              Learn About Solar
            </Text>
            <Heading mt="8px" fontFamily="'eurostile', sans-serif" color="gray.600">
              Is solar a good fit for you?{" "}
            </Heading>
            <Text fontSize={"lg"} fontFamily="'eurostile', sans-serif" color="gray.600">
              There is a lot to learn about solar before ordering your system. We've put together a series of educational videos and blogs to help you truly understand everything
              you need to know before making the leap. Click the "Learn Now" button to get started.
            </Text>
            <Stack spacing={4}>
              <VStack w="full">
                <Button
                  w="full"
                  mt="20px"
                  bgColor="#566581"
                  color="white"
                  // colorScheme="purple"
                  // variant="outline"
                  _hover={{ bgColor: "#6e85a6" }}
                  fontFamily="'eurostile', sans-serif"
                  onClick={() => {
                    navigate("/blogs");
                  }}
                >
                  Learn Now
                </Button>
              </VStack>
            </Stack>
          </Stack> */}
          <VStack gap={14} w={{ base: "full", xl: "full" }}>
            <HStack w="full" flexDirection={{ base: "column", md: "row" }}>
              <Image w="200px" h="200px" src={website} borderRadius="12px" />
              <VStack pl={{ base: "0px", md: "60px" }}>
                <Text w="full" fontWeight="bold" fontSize={{ base: "18px", md: "20px" }}>
                  Seamless Experience
                </Text>
                <Text fontSize={{ base: "14px", md: "18px" }}>
                  With Klaravia, you’re just a few clicks away from saving on your energy costs by going solar. Receive an instant, customized solar design for your home - no phone
                  number or email required. Easily track progress with real-time updates and gain access to a team fighting for you from initial design to system activation. Get
                  started in minutes and take the first step toward clean energy on your terms.
                </Text>
              </VStack>
            </HStack>
            <HStack w="full" flexDirection={{ base: "column", md: "row" }}>
              <Image w="200px" h="200px" src={join} borderRadius="12px" />
              <VStack pl={{ base: "0px", md: "60px" }}>
                <Text w="full" fontWeight="bold" fontSize={{ base: "18px", md: "20px" }}>
                  Competitive Marketplace
                </Text>
                <Text fontSize={{ base: "14px", md: "18px" }}>
                  Klaravia connects you with top-rated, certified local installers. By eliminating sales commissions and creating healthy pricing competition, we significantly
                  lower costs and choose to pass those savings on to you. Save time and stress by comparing pricing and services all in one place to find the best value for your
                  tailored solar project.
                </Text>
              </VStack>
            </HStack>
            <HStack w="full" flexDirection={{ base: "column", md: "row" }}>
              <Image w="200px" h="200px" src={learn} borderRadius="12px" />
              <VStack pl={{ base: "0px", md: "60px" }}>
                <Text w="full" fontWeight="bold" fontSize={{ base: "18px", md: "20px" }}>
                  Expert Solar Education
                </Text>
                <Text fontSize={{ base: "14px", md: "18px" }}>
                  We equip you with all the knowledge you need to make the best solar decision for you. Explore our extensive library of solar education materials, take our Solar
                  101 course, and use our interactive solar calculator to estimate your savings potential. Plus, our non-commissioned solar experts are always available to guide
                  you and answer your questions every step of the way. Visit our resource library to start learning now.
                </Text>
              </VStack>
            </HStack>
          </VStack>
          {/* <VStack w={{ base: "full", xl: "40vw", "2xl": "30vw" }}>
            <LifetimeSavingsComponent />
          </VStack> */}
        </Flex>
      </Container>
    </VStack>
  );
}
