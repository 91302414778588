import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useBreakpointValue,
  Image,
  Link,
  DrawerBody,
} from "@chakra-ui/react";
import { FiMenu, FiBell, FiChevronDown } from "react-icons/fi";
import { IconType } from "react-icons";
import { GoMail } from "react-icons/go";
import { BsFiles, BsCardImage } from "react-icons/bs";
import { IoTodayOutline } from "react-icons/io5";
import { BiHomeAlt } from "react-icons/bi";
import { useEffect, useState } from "react";
import logo from "../../Assets/logo.svg";
import logoLight from "../../Assets/logoLight.svg";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Providers/AuthProvider";
import { useOnCreateMessageSubscription } from "../../hooks/userHooks";
import { Bell } from "lucide-react";
import { useAuth as useAuthContext } from "../../Providers/AuthenticationProvider";

interface LinkItemProps {
  name: string;
  icon: IconType;
  route: string;
}

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: React.ReactNode;
}

interface MobileProps extends FlexProps {
  onOpen: () => void;
  sideBarHover: boolean;
  signOut: () => void;
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
  sideBarHover: boolean;
  setSideBarHover: React.Dispatch<React.SetStateAction<boolean>>;
}

const LinkItems: Array<LinkItemProps> = [
  { name: "Home", icon: BiHomeAlt, route: "" },
  { name: "Final Design", icon: BsCardImage, route: "final-design" },
  { name: "Documents", icon: BsFiles, route: "documents" },
  { name: "Calendar", icon: IoTodayOutline, route: "calendar" },
  { name: "Support Chat", icon: GoMail, route: "support-chat" },
];

const SidebarContent = ({ onClose, sideBarHover, setSideBarHover, ...rest }: SidebarProps) => {
  const breakPoint = useBreakpointValue({ base: "base", sm: "sm", md: "md", lg: "lg", xl: "xl" });
  const navigate = useNavigate();

  return (
    <Box color="white" bgColor="#141414" w={{ base: "full", md: 60 }} pos="fixed" h="full" {...rest} shadow="lg" fontFamily="'eurostile', sans-serif">
      <VStack w="full" h="full" alignItems="flex-start" justifyContent="space-between">
        <VStack w="full" alignItems="flex-start">
          <Flex w="full" h="20" alignItems="center" justifyContent={{ base: "space-between", md: "flex-start" }} pl="24px">
            <HStack onClick={() => navigate("/")} cursor="pointer">
              <Image h="38px" w="38px" src={breakPoint === "base" || breakPoint === "sm" ? logoLight : logoLight} />
              <Link href="/" textAlign={{ base: "center", md: "left" }} fontFamily="'new-astro', sans-serif" fontSize="28px">
                Klaravia
              </Link>
            </HStack>
            <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
          </Flex>
          {LinkItems.map((link) => (
            <NavItem key={link.name} icon={link.icon} onClick={() => navigate(`/user/${link.route}`)}>
              <Box _hover={{ w: "100%" }} h="24px">
                <Text w="120px" overflow="hidden">
                  {link.name}
                </Text>
              </Box>
            </NavItem>
          ))}
        </VStack>
        {/* TODO: Implement help center */}
        {/* <NavItem key={"Help Center"} icon={IoMdHelpCircleOutline} mb="20px" cursor="not-allowed">
          <Box _hover={{ w: "100%" }} h="24px">
            <Text
              w="120px"
              overflow="hidden" // Ensure the text is hidden when the box is narrow
            >
              Help Center
            </Text>
          </Box>
        </NavItem> */}
      </VStack>
    </Box>
  );
};

const NavItem = ({ icon, children, ...rest }: NavItemProps) => {
  return (
    <Box as="a" style={{ textDecoration: "none" }} _focus={{ boxShadow: "none" }}>
      <Flex
        align="center"
        p="4"
        mx="6"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "rgba(255,255,255,.2)",
          color: "white",
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Box>
  );
};

const MobileNav = ({ onOpen, sideBarHover, signOut, ...rest }: MobileProps) => {
  const navigate = useNavigate();
  const { onOpen: onNotificationsOpen, onClose: onNotificationsClose, isOpen: isNotificationsOpen } = useDisclosure();
  const [hasViewedNewMessages, setHasViewedNewMessages] = useState<boolean>(false);
  const [messageCount, setMessageCount] = useState<number>(0);
  const { userData } = useAuthContext();

  const { data: subscriptionData, loading: loadingMessages, error: subErr } = useOnCreateMessageSubscription(userData?.id || "");

  useEffect(() => {
    if (subscriptionData && subscriptionData?.onCreateMessage && subscriptionData?.onCreateMessage?.sender) {
      if (subscriptionData?.onCreateMessage?.sender === "support") {
        setMessageCount(messageCount + 1);
      }
    }
  }, [subscriptionData]);

  const handleNotificationClose = () => {
    onNotificationsClose();
    setMessageCount(0);
  };

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height={{ base: "60px", md: "80px" }}
      alignItems="center"
      bg={{ base: "#141414", md: "#f4eee9" }}
      justifyContent={{ base: "space-between", md: "flex-end" }}
      transition="margin 0.4s ease-in-out"
      {...rest}
    >
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        _hover={{ bgColor: "rgba(0,0,0,.2)" }}
        _focus={{ bgColor: "rgba(0,0,0,.2)" }}
        variant="ghost"
        color="white"
        aria-label="open menu"
        icon={<FiMenu />}
      />
      <HStack w="full" pl="20px">
        <Link
          href="/"
          textAlign={{ base: "center", md: "left" }}
          fontFamily="'new-astro', sans-serif"
          fontSize="24px"
          color={useColorModeValue("white", "white")}
          display={{ base: "block", md: "none" }}
        >
          Klaravia
        </Link>
      </HStack>
      <HStack spacing={{ base: "3", md: "6" }}>
        {messageCount > 0 && (
          <Box
            w="18px"
            h="18px"
            borderRadius="50%"
            bgColor="red.500"
            color="white"
            fontWeight="bold"
            fontSize="10px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="relative"
            left={{ base: "52px", md: "66px" }}
            bottom="12px"
            zIndex={10}
          >
            <Text>{messageCount}</Text>
          </Box>
        )}
        <IconButton
          size="lg"
          variant="ghost"
          _hover={{ bg: "none" }}
          onClick={onNotificationsOpen}
          color={{ base: "white", md: "black" }}
          aria-label="open notifications"
          icon={<FiBell />}
        />
        <Flex alignItems={"center"}>
          <Menu>
            <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: "none" }}>
              <HStack>
                <Avatar
                  size={"sm"}
                  fontFamily="'eurostile', sans-serif"
                  bgColor="gray.400"
                  border="1px solid #D3D3D3"
                  color="white"
                  name={userData && userData.email ? userData.email : "Error"}
                />
                <VStack display={{ base: "none", md: "flex" }} alignItems="flex-start" spacing="1px" ml="2">
                  <Text fontSize="sm" fontFamily="'eurostile', sans-serif">
                    {userData && userData.email ? userData.email.slice(0, userData.email.indexOf("@")) : "Error"}
                  </Text>
                  <Text fontSize="xs" color="gray.600">
                    Your Account
                  </Text>
                </VStack>
                <Box display={{ base: "none", md: "flex" }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList bg={useColorModeValue("white", "gray.900")} borderColor={useColorModeValue("gray.200", "gray.700")}>
              <MenuItem
                onClick={() => {
                  navigate("/log-in");
                  signOut();
                }}
              >
                Sign out
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
      <Drawer isOpen={isNotificationsOpen} placement="right" onClose={handleNotificationClose}>
        <DrawerContent p="0px" pt="20px" bgColor="rgba(0,0,0,.2)" shadow="none">
          <DrawerBody p="0px" bgColor="rgba(0,0,0,0)" px="10px">
            <VStack w="full" gap="10px">
              {messageCount > 0 ? (
                Array.from({ length: messageCount }, (_, i) => i).map((message) => {
                  return (
                    <HStack
                      w="full"
                      minH="80px"
                      bgColor="green.500"
                      p="14px"
                      borderRadius="20px"
                      cursor="pointer"
                      onClick={() => {
                        navigate(`/user/support-chat`);
                        onNotificationsClose();
                        setMessageCount(0);
                      }}
                    >
                      <Bell width="16px" height="16px" color="white" />
                      <VStack w="full">
                        <Text color="white" fontSize="12px" maxW="240px">
                          You have recieved a new message from Klaravia.
                        </Text>
                      </VStack>
                    </HStack>
                  );
                })
              ) : (
                <Box w="full" p="10px" borderRadius="20px" bgColor="white" fontFamily="'eurostile', sans-serif">
                  <Text w="full" textAlign="center">
                    You don't have any new notifications
                  </Text>
                </Box>
              )}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

const SidebarWithHeader = ({ children }: { children: React.ReactNode }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOnboardingOpen, onOpen: onOnboardingOpen, onClose: onOnboardingClose } = useDisclosure();
  const [sideBarHover, setSideBarHover] = useState<boolean>(false);

  const { signOut } = useAuth();

  useEffect(() => {
    onOnboardingOpen();
  }, []);

  return (
    <Box minH="100vh">
      <SidebarContent onClose={() => onClose} display={{ base: "none", md: "block" }} sideBarHover={sideBarHover} setSideBarHover={setSideBarHover} />
      <Drawer isOpen={isOpen} placement="left" onClose={onClose} returnFocusOnClose={false} onOverlayClick={onClose} size="xs">
        <DrawerContent>
          <SidebarContent onClose={onClose} sideBarHover={sideBarHover} setSideBarHover={setSideBarHover} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} sideBarHover={sideBarHover} signOut={signOut} />
      <Box ml={{ base: 0, md: 60 }} transition="margin 0.4s ease-in-out">
        {children}
      </Box>
    </Box>
  );
};

export default SidebarWithHeader;
