import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useDisclosure,
  Link,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  VStack,
  Avatar,
  Image,
  Tag,
  Badge,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon, ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Providers/AuthProvider";
import { FiChevronDown } from "react-icons/fi";
import logo from "../../Assets/logoLight.svg";

export default function WithSubnavigation() {
  const { isOpen, onToggle } = useDisclosure();
  const [signedIn, setSignedIn] = useState(false);
  const { cognitoUser, user, setUser, signOut } = useAuth();
  const [userName, setUserName] = useState<string>();
  const menuListColor = useColorModeValue("white", "gray.900");
  const menuListBorderColor = useColorModeValue("gray.200", "gray.700");
  const navigate = useNavigate();

  useEffect(() => {
    if (cognitoUser !== null) {
      //@ts-ignore
      setUserName(cognitoUser.attributes.email);
      setSignedIn(true);
    }
    if (user !== null && user.username) {
      setUserName(user?.username || "");
      setSignedIn(true);
    }
    if (user === null && cognitoUser === null) {
      setSignedIn(false);
    }
  }, [user, cognitoUser]);

  const handleLogOut = () => {
    signOut();
    navigate("/LandingPage");
  };

  return (
    <Box w="full" position="absolute" bgColor="#141414">
      <Flex
        bg={useColorModeValue("transparent", "gray.800")}
        color={useColorModeValue("white", "white")}
        minH={{ base: "40px", md: "80px" }}
        py={{ base: 2 }}
        px={{ base: 4 }}
        // borderBottom={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.900")}
        align={"center"}
      >
        <Flex maxW="40px" flex={{ base: 1, md: "auto" }} ml={{ base: -2 }} display={{ base: "flex", md: "none" }}>
          <IconButton
            onClick={onToggle}
            _active={{ bgColor: "rgba(255,255,255,0.2)" }}
            _focus={{ bgColor: "rgba(255,255,255,0.2)" }}
            icon={isOpen ? <CloseIcon color="white" w={3} h={3} /> : <HamburgerIcon color="white" w={5} h={5} />}
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
          <HStack w={{ base: "full", md: "unset" }} pl={{ base: "6px", md: "0px" }} justifyContent="flex-start">
            {/* <Image h="34px" w="34px" display={{ base: "none", lg: "unset" }} src={logo} /> */}
            <Link href="/" textAlign={{ base: "center", md: "left" }} fontFamily="'new-astro', sans-serif" fontSize="24px" color={useColorModeValue("white", "white")}>
              Klaravia
            </Link>
          </HStack>
          <Flex display={{ base: "none", md: "flex" }} ml={10}>
            <DesktopNav />
          </Flex>
        </Flex>
        {signedIn ? (
          <HStack>
            {/* <Button variant="ghost" onClick={handleLogOut} fontWeight={400} fontSize="15px">
              Log Out
            </Button> */}
            <Flex alignItems={"center"}>
              <Menu>
                <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: "none" }}>
                  <HStack>
                    <Avatar size={"sm"} bgColor="gray.400" border="1px solid #D3D3D3" color="white" name={userName} />
                    <VStack display={{ base: "none", md: "flex" }} alignItems="flex-start" spacing="1px" ml="2">
                      {/* <Text fontSize="sm">{installer ? installer.company_name : "error"}</Text> */}
                      {/* TODO: implement name */}
                      <Text fontSize="sm">{userName?.slice(0, userName?.indexOf("@"))}</Text>
                      <Text fontSize="xs" color="gray.200">
                        Your Account
                      </Text>
                    </VStack>
                    <Box display={{ base: "none", md: "flex" }}>
                      <FiChevronDown />
                    </Box>
                  </HStack>
                </MenuButton>
                <MenuList bg={menuListColor} borderColor={menuListBorderColor} color="black" fontFamily="'eurostile', sans-serif">
                  {/* <MenuItem onClick={() => navigate("/profile-page")}>Profile</MenuItem>
                  <MenuItem>Settings</MenuItem>
                  <MenuDivider /> */}
                  <MenuItem onClick={handleLogOut}>Sign out</MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          </HStack>
        ) : (
          <Stack flex={{ base: 1, md: 0 }} justify={"flex-end"} direction={"row"} spacing={6}>
            <Button as={"a"} fontSize={{ base: "14px", md: "md" }} color="white" fontWeight={400} variant={"link"} href={"/log-in"} fontFamily="'eurostile', sans-serif">
              Log In
            </Button>
            <Button
              as={"a"}
              p={{ base: "10px", md: "" }}
              h={{ base: "30px", md: "unset" }}
              fontSize={{ base: "14px", md: "md" }}
              fontWeight={600}
              fontFamily="'eurostile', sans-serif"
              color="black"
              bg="#f4eee9"
              href={"#"}
              _hover={{
                bg: "gray.100",
              }}
              onClick={() => {
                navigate("/user-sign-up");
              }}
            >
              Sign Up
            </Button>
          </Stack>
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
}

const DesktopNav = () => {
  const linkColor = useColorModeValue("white", "#f4eee9");
  const linkHoverColor = useColorModeValue("gray.400", "white");
  const popoverContentBgColor = useColorModeValue("white", "gray.800");

  return (
    <HStack spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <Box
                as="a"
                p={2}
                href={navItem.href ?? "#"}
                fontSize={"md"}
                borderRadius="6px"
                fontWeight={500}
                color={linkColor}
                fontFamily="'eurostile', sans-serif"
                _hover={{
                  textDecoration: "none",
                  color: linkHoverColor,
                }}
              >
                {navItem.label}
              </Box>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent border={0} boxShadow={"xl"} bg={popoverContentBgColor} p={4} rounded={"md"} minW={"sm"}>
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
          {navItem.badge}
        </Box>
      ))}
    </HStack>
  );
};

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
  return (
    <Box as="a" href={href} role={"group"} display={"block"} p={2} rounded={"md"} _hover={{ bg: useColorModeValue("gray.100", "gray.900") }}>
      <Stack direction={"row"} align={"center"}>
        <Box>
          <Text transition={"all .3s ease"} color="black" _groupHover={{ color: "gray.700" }} fontWeight={500} fontFamily="'eurostile', sans-serif">
            {label}
          </Text>
          <Text fontSize={"sm"} color="gray.500" fontFamily="'eurostile', sans-serif">
            {subLabel}
          </Text>
        </Box>
        <Flex
          transition={"all .3s ease"}
          transform={"translateX(-10px)"}
          opacity={0}
          _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
          justify={"flex-end"}
          align={"center"}
          flex={1}
        >
          <Icon color={"gray.400"} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Box>
  );
};

const MobileNav = () => {
  return (
    <Stack bg={useColorModeValue("white", "gray.800")} p={4} display={{ md: "none" }}>
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Box
        py={2}
        as="a"
        href={href ?? "#"}
        justifyContent="space-between"
        alignItems="center"
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text fontWeight={600} color={useColorModeValue("gray.600", "gray.200")} fontFamily="'eurostile', sans-serif">
          {label}
        </Text>
        {children && <Icon as={ChevronDownIcon} transition={"all .25s ease-in-out"} transform={isOpen ? "rotate(180deg)" : ""} w={6} h={6} />}
      </Box>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack mt={2} pl={4} borderLeft={1} borderStyle={"solid"} borderColor={useColorModeValue("gray.200", "gray.700")} align={"start"}>
          {children &&
            children.map((child) => (
              <Box as="a" key={child.label} py={2} href={child.href} fontFamily="'eurostile', sans-serif">
                {child.label}
              </Box>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
  badge?: JSX.Element;
}

const NAV_ITEMS: Array<NavItem> = [
  {
    label: "Blogs",
    subLabel: "Articles written to help you on your solar journey",
    href: "/blogs",
  },
  {
    label: "Solar Course",
    subLabel: "A zero to hero course on residential solar",
    href: "/solar-course",
  },
  // {
  //   label: "Coverage",
  //   href: "/coverage-map",
  // },
  // {
  //   label: "Education",
  //   children: [
  //     {
  //       label: "Solar 101 Course",
  //       subLabel: "A zero to hero course on residential solar",
  //       href: "/solar-course",
  //     },
  //     {
  //       label: "Blogs",
  //       subLabel: "Articles written to help you on your solar journey",
  //       href: "/blogs",
  //     },
  //   ],
  //   href: "/solar-course",
  //   badge: (
  //     <Badge colorScheme="green" position="relative" bottom="20px" fontSize="10px">
  //       Learn
  //     </Badge>
  //   ),
  // },
  // {
  //   label: "Resources",
  //   children: [
  //     {
  //       label: "Home Value Calculator",
  //       subLabel: "Understand the impact installing solar will have on your home",
  //       href: "#",
  //     },
  //     {
  //       label: "Solar financing",
  //       subLabel: "Don't pay more than you have to",
  //       href: "#",
  //     },
  //   ],
  // },
];
