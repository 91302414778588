import React, { useEffect, useState } from 'react';
import { Box, Center, CircularProgress, Heading, useToast, Text } from '@chakra-ui/react';
import {
  Button,
  FormControl,
  Flex,
  Stack,
  useColorModeValue,
  HStack,
} from '@chakra-ui/react';
import { PinInput, PinInputField } from '@chakra-ui/react';
import { Auth } from "aws-amplify";
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../Providers/AuthProvider";
import { useUserDataContext } from '../../Providers/LeadDataProvider';
import { useCreateUser } from "../../hooks/userHooks";
import { UserUpdateOptions } from '../../API';
import { useAuth as useAuthentication } from '../../Providers/AuthenticationProvider';

export default function VerifyEmailForm({email, password}) {
    const [first, setFirst] = useState('');
    const [second, setSecond] = useState('');
    const [third, setThird] = useState('');
    const [fourth, setFourth] = useState('');
    const [fifth, setFifth] = useState('');
    const [sixth, setSixth] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const stackBg = useColorModeValue('white', 'gray.700')
    const centerBg = useColorModeValue('gray.800', 'gray.400')
    const navigate = useNavigate();
    const auth = useAuth();
    const { checkAuth } = useAuthentication();

    const { userData } = useUserDataContext();

    const { createUser, data: createUserData, loading: loadingUser, error: createUserError } = useCreateUser();

    const toast = useToast()

    useEffect(() => {
      setVerificationCode(`${first}${second}${third}${fourth}${fifth}${sixth}`)
    }, [first, second, third, fourth, fifth, sixth])
    
    const confirmSignUp = async () => {
      setIsLoading(true); // Set loading state to true
      try {
        await Auth.confirmSignUp(email, verificationCode);

        const cognitoUser = await Auth.signIn(email, password);
        auth.setCognitoUser(cognitoUser);

        userData.id = cognitoUser.attributes.sub;
        userData.cognito_id = cognitoUser.attributes.sub;
        userData.first_name = cognitoUser.attributes["custom:first_name"];
        userData.last_name = cognitoUser.attributes["custom:last_name"];
        userData.email = cognitoUser.attributes.email;
        userData.phone_number = cognitoUser.attributes.phone_number;
        userData.updated_by = UserUpdateOptions.DYNAMO;

        if(userData && userData.google_solar_object){
          delete userData.google_solar_object
        }

        createUser({
          variables: {
            input: userData,
          },
        });

        checkAuth();

        navigate('/LandingPage'); // Navigate to the landing page or dashboard
      } catch (error) {
        console.error('Error confirming sign up', error);
        toast({
          title: 'Error verifying email',
          description: error.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false); // Set loading state back to false
      }
    };

    const resendCode = async () => {
      try {
        await Auth.resendSignUp(email);
        console.log('Verification code resent successfully');
        toast({
          title: 'Verification code resent',
          description: "Check your email for the new code",
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
      } catch (err) {
        toast({
          title: "Couldn't resend verification",
          description: "Try refreshing the page",
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
        console.error('Error resending code: ', err);
      }
    };

    const handleFirst = (event) => {
        setFirst(event.target.value);
    }
    const handleSecond = (event) => {
        setSecond(event.target.value);
    }
    const handleThird = (event) => {
        setThird(event.target.value);
    }
    const handleFourth = (event) => {
        setFourth(event.target.value);
    }
    const handleFifth = (event) => {
        setFifth(event.target.value);
    }
    const handleSixth = (event) => {
        setSixth(event.target.value);
    }

  return (
    <>
    {isLoading ? (
            <Box w='full' minH='80vh' display='flex' justifyContent='center' alignItems='center'>
            <CircularProgress size='100px' isIndeterminate color='green.400' />
            </Box>
    ) : (
    <Flex
    w='full'
      align={'center'}
      justify={'center'}
      >
      <Stack
        spacing={4}
        w={'full'}
        maxW={'sm'}
        bg={stackBg}
        rounded={'xl'}
        boxShadow={'lg'}
        p={6}
        my={10}>
        <Center>
          <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
            Verify your Email
          </Heading>
        </Center>
        <Center
          fontSize={{ base: 'sm', sm: 'md' }}
          color={centerBg}>
          We have sent a verification code to
        </Center>
        <Center
          fontSize={{ base: 'sm', sm: 'md' }}
          fontWeight="bold"
          color={centerBg}>
          {email}
        </Center>
        <FormControl>
          <Center>
            <HStack>
              <PinInput>
                <PinInputField onChange={handleFirst} />
                <PinInputField onChange={handleSecond} />
                <PinInputField onChange={handleThird} />
                <PinInputField onChange={handleFourth} />
                <PinInputField onChange={handleFifth} />
                <PinInputField onChange={handleSixth} />
              </PinInput>
            </HStack>
          </Center>
        </FormControl>
        <Stack spacing={6}>
          <Button
            onClick={confirmSignUp}
            bg={'green.300'}
            color={'white'}
            _hover={{
              bg: 'green.400',
            }}>
            Verify
          </Button>
        </Stack>
        <HStack w='100%' textAlign='center' justifyContent='center'>
          <Text color='gray.600'>Haven't gottent your code?</Text><Text color={'green.400'} cursor='pointer' _hover={{ color: 'green.500'}} onClick={resendCode}>Resend</Text>
          </HStack>
      </Stack>
    </Flex> )}
    </>
  );
}