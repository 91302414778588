import { useEffect, useState } from "react";
import {
  Box,
  Text,
  HStack,
  VStack,
  Heading,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Image,
  Button,
  Divider,
  IconButton,
  CircularProgress,
  Avatar,
  Icon,
  Badge,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useListInstallers } from "../../hooks/userHooks";
import installersNotFound from "../Assets/installersNotFound.svg";
import { Battery, Installer, Panel, UserUpdateOptions } from "../../API";
import UserSignUpModal from "../Components/UserSignUpModal";
import { AnimatePresence, motion } from "framer-motion";
import { FaArrowRight } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { useSolarReadyContext } from "../../Providers/SolarReadyProvider";
import { UserWithoutTypeName, useUserDataContext } from "../../Providers/LeadDataProvider";
import { MdOutlineStarPurple500 } from "react-icons/md";
import { AddIcon, ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import axios from "axios";
import { FcApproval } from "react-icons/fc";
import { DollarSign, ThumbsUp, Star, BadgeCent, Timer, CheckCircle } from "lucide-react";
import { useAuth } from "../../Providers/AuthProvider";
import { useAuth as useAuthentication } from "../../Providers/AuthenticationProvider";

const MotionBox = motion(Box);
const MotionHStack = motion(HStack);
const MotionVStack = motion(VStack);
const MotionText = motion(Text);
const MotionIcon = motion(Icon);

type InstallerReviews = {
  html_attributions: string[];
  result: {
    rating: number;
    reviews: {
      author_name: string;
      author_url: string;
      language: string;
      original_language: string;
      profile_photo_url: string;
      rating: number;
      relative_time_description: string;
      text: string;
      time: number;
      translated: boolean;
    }[];
    user_ratings_total: number;
  };
  status: string;
};

const defaultInstallerReviews = {
  html_attributions: [],
  result: {
    rating: 0,
    reviews: [
      {
        author_name: "",
        author_url: "",
        language: "",
        original_language: "",
        profile_photo_url: "",
        rating: 0,
        relative_time_description: "",
        text: "",
        time: 0,
        translated: false,
      },
    ],
    user_ratings_total: 0,
  },
  status: "",
};

const InstallerSelect = () => {
  const [installerOptions, setInstallerOptions] = useState<[Installer] | undefined | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const { cognitoUser, user } = useAuth();
  const [selectedCard, setSelectedCard] = useState<Installer | null>(null);
  const [goodIndex, setGoodIndex] = useState<number>(0);
  const [filterPreference, setFilterPreference] = useState<string>("");
  const [goodDirection, setGoodDirection] = useState<string>("backward");
  const { solarReadyData } = useSolarReadyContext();
  const { data: installers, loading: loadingInstallers, error: installersError } = useListInstallers();
  const { userData, setUserData } = useUserDataContext();
  const [installerReviews, setInstallerReviews] = useState<InstallerReviews>(defaultInstallerReviews);
  const [loadingGoogleReviews, setLoadingGoogleReviews] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [selectedPanel, setSelectedPanel] = useState<Panel | null>(null);
  const [selectedBattery, setSelectedBattery] = useState<Battery | null>(null);
  const { userData: userD } = useAuthentication();

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const getGoogleBusinessReviews = async (placeId: string) => {
    setLoadingGoogleReviews(true);
    try {
      const response = await axios.get(`https://4zpask5mw0.execute-api.us-east-2.amazonaws.com/dev/get_google_business_reviews?place_id=${placeId}`);

      // Check if the request was successful
      if (response.status !== 200) {
        throw new Error(`API call failed with status: ${response.status}`);
        setLoadingGoogleReviews(false);
      }

      setLoadingGoogleReviews(false);
      return response.data;
    } catch (error) {
      setLoadingGoogleReviews(false);
      // Handle any errors that occur during the fetch
      console.error("Error calling the API:", error);
      // setErrorMessage("We're having trouble checking the status of your initial design.");
      throw error; // Rethrow the error if you want to handle it further up the call stack
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!userData.id && userD) {
      setUserData(userD);
    }
  }, [userD]);

  useEffect(() => {
    if (installers && installers.listInstallers) {
      if (userData.requires_battery) {
        const installersWithBatteries = installers.listInstallers.items.filter((e: any) => e.available_batteries.length > 0);
        setInstallerOptions(installersWithBatteries as [Installer]);
      } else {
        setInstallerOptions(installers.listInstallers.items as [Installer]);
      }
    }
  }, [installers, loadingInstallers]);

  const handleCardClick = (card: Installer) => {
    setSelectedCard(card);
  };

  const handleInstallerSelect = (selectedInstaller: any) => {
    if (!cognitoUser) {
      onOpen();
    } else {
      // setSelectedInstaller(selectedInstaller);
      handleCardClick(selectedInstaller);
      getGoogleBusinessReviews(selectedInstaller.google_place_id).then((res) => setInstallerReviews(res));
    }
  };

  const handleInstallerChosen = (chosenInstaller: any) => {
    // @ts-ignore
    setUserData((prevUserData: UserWithoutTypeName) => {
      if (
        JSON.stringify(prevUserData) !==
        JSON.stringify({
          ...prevUserData,
          installerId: chosenInstaller.id,
          installerSalesforceId: chosenInstaller.salesforceId,
          updated_by: UserUpdateOptions.DYNAMO,
        })
      ) {
        return {
          ...prevUserData,
          installerId: chosenInstaller.id,
          installerSalesforceId: chosenInstaller.salesforceId,
          updated_by: UserUpdateOptions.DYNAMO,
        };
      }
      return prevUserData;
    });
    navigate("/bill-upload");
  };

  const variants = {
    exit: {
      // x: goodDirection === "backward" ? "-50%" : "50%",
      opacity: 0,
      transition: {
        duration: 0.2,
        ease: "easeInOut", // Ensures a smooth exit
      },
    },
    hidden: {
      x: goodDirection === "forward" ? "50%" : "-50%",
      opacity: 0,
      transition: {
        duration: 0.4, // Adjust duration as needed
        ease: "easeInOut", // This easing function provides a smooth start and end
      },
    },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.4, // Keep consistent for a uniform animation speed
        ease: "easeInOut", // Smooth transition for entering animation
      },
    },
  };

  const handleGoodForward = () => {
    if (installerReviews && goodIndex + 1 <= installerReviews?.result?.reviews?.length - 1) {
      if (goodDirection !== "forward") {
        setGoodDirection("forward");
      }
      setGoodIndex(goodIndex + 1);
    }
  };

  const handleGoodBackward = () => {
    if (goodIndex - 1 >= 0) {
      if (goodDirection !== "backward") {
        setGoodDirection("backward");
      }
      setGoodIndex(goodIndex - 1);
    }
  };
  const installerOptionsForSort: Installer[] = installerOptions ? [...installerOptions] : [];

  const MetricCard = ({ icon, title, value, subtext, color }: any) => (
    <div className="rounded-lg py-6 flex flex-col items-center text-center" style={{ minWidth: "155px" }}>
      {icon}
      <h3 className="mt-4 md:text-lg sm:text-sm font-semibold text-gray-800">{title}</h3>
      <p className={`mt-2 text-2xl font-bold text-${color || "black"}-500`}>{value}</p>
      {/* {subtext && <p className="mt-2 text-sm text-gray-600">{subtext}</p>} */}
    </div>
  );

  return (
    <Box w="full" minH="100vh" p="40px" bg="#f4eee9" fontFamily="'eurostile', sans-serif">
      {loadingInstallers ? (
        <VStack w="full" minH="90vh" justifyContent="center">
          <CircularProgress color="gray.200" isIndeterminate size="120px"></CircularProgress>
        </VStack>
      ) : installerOptions ? (
        <>
          <VStack pt="80px">
            <Text w="full" textAlign="center" fontSize="24px" fontWeight="bold">
              Choose your installer
            </Text>
            <Text mb="20px" textAlign={{ base: "center" }} fontSize={{ base: "14px", md: "16px" }}>
              We recommend spending some time reviewing your options before continuing
            </Text>
            <HStack w="full" align="stretch" fontFamily="'eurostile', sans-serif">
              <VStack w="full" alignItems="flex-start" gap="20px" mt="20px">
                {installerOptions &&
                  installerOptionsForSort &&
                  installerOptionsForSort
                    .sort((a: Installer, b: Installer) => (a.company_red_line || 0) - (b.company_red_line || 0))
                    .map((installerOption: any, index: number) => {
                      return (
                        <MotionHStack
                          key={index}
                          w="full"
                          gap={{ base: "10px", lg: "6vw" }}
                          bgColor="white"
                          borderRadius="10px"
                          _hover={{ bgColor: "gray.50", borderRadius: "12px" }}
                          p="10px"
                          pl={{ base: "0px", lg: "10px" }}
                          align="stretch"
                          layoutId={installerOption.id}
                          cursor="pointer"
                          shadow="lg"
                          onClick={() => {
                            handleInstallerSelect(installerOption);
                          }}
                          flexDirection={{ base: "column", lg: "row" }}
                        >
                          <MotionVStack w="full">
                            <HStack w="full" flexDirection={{ base: "column", lg: "row" }} align="stretch">
                              <MotionVStack minW="200px">
                                <MotionText textAlign="left" fontWeight="bold" fontSize="22px">
                                  {installerOption?.company_name.includes(" - ")
                                    ? installerOption?.company_name.slice(0, installerOption?.company_name.indexOf("-")).trim()
                                    : installerOption?.company_name}
                                </MotionText>
                                <VStack flex={1} display="flex" justifyContent="center" alignItems="center">
                                  <Image
                                    maxH="100px"
                                    w="auto"
                                    // borderRadius="12px"
                                    src={installerOption.company_cover_image_url || "https://upload.wikimedia.org/wikipedia/commons/9/9d/Unknown_Member.jpg"}
                                  />
                                </VStack>
                              </MotionVStack>
                              <Divider orientation="vertical" border="1px solid gray" />
                              <MotionHStack
                                w="full"
                                pl={{ base: "0px", lg: "10px" }}
                                flexDirection={{ base: "column", lg: "row" }}
                                align="stretch"
                                justifyContent="space-around"
                                gap={{ base: "0px", lg: "unset" }}
                              >
                                <div className="grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-5 gap-2 w-full">
                                  <MetricCard
                                    icon={<DollarSign className="w-10 h-10 text-green-500" />}
                                    title="Est. System Cost"
                                    color="green"
                                    // value={`$${new Intl.NumberFormat().format(
                                    //   (Number(installerOption.company_red_line) * 1000 * Number(userData?.initialDesign?.dataOutput?.roof?.maxKWSystem) +
                                    //     400 * Number(userData?.initialDesign?.dataOutput?.roof?.maxKWSystem)) *
                                    //     0.7
                                    // )}`}
                                    value={
                                      userData.requires_battery
                                        ? `$${new Intl.NumberFormat().format(
                                            (Number(installerOption.company_red_line) * 1000 * userData?.google_system_size + 400 * Number(userData?.google_system_size)) * 0.7 +
                                              installerOption.available_batteries[0].additional_cost
                                          )}`
                                        : `$${new Intl.NumberFormat().format(
                                            (Number(installerOption.company_red_line) * 1000 * userData?.google_system_size + 400 * Number(userData?.google_system_size)) * 0.7
                                          )}`
                                    }
                                  />

                                  <MetricCard
                                    icon={<BadgeCent className="w-10 h-10 text-blue-500" />}
                                    title="Price/Watt"
                                    // color="blue"
                                    value={`$${(installerOption.company_red_line + 0.4).toFixed(2)}`}
                                  />

                                  <MetricCard
                                    icon={<Timer className="w-10 h-10 text-purple-500" />}
                                    title="Installation Time"
                                    // color="purple"
                                    value={`${installerOption.average_install_time} days`}
                                    subtext="Typical duration"
                                  />

                                  <MetricCard
                                    icon={<ThumbsUp className="w-10 h-10 text-blue-500" />}
                                    title="Warranty Period"
                                    // color="blue"
                                    value={"25 years"}
                                    subtext="Full system coverage"
                                  />

                                  {/* <MotionVStack gap={{ base: "0px" }} justifyContent="space-between">
                                  <MotionText w="100%" fontSize={{ base: "14px", lg: "18px" }}>
                                    Est. Payback Period
                                  </MotionText>
                                  <MotionText w="full" fontWeight="bold" fontSize={{ base: "20px", lg: "28px" }}>
                                    {calculateSolarPaybackPeriod(
                                      Number(installerOption.company_red_line) * 1000 * Number(userData?.initialDesign?.dataOutput?.roof?.maxKWSystem) +
                                        400 * Number(userData?.initialDesign?.dataOutput?.roof?.maxKWSystem),
                                      1000
                                    )}
                                  </MotionText>
                                </MotionVStack> */}

                                  {/* TODO: implement payback period */}
                                  {/* <MetricCard
                                  icon={<Calendar className="w-10 h-10 text-purple-500" />}
                                  title="Payback Period"
                                  // color="purple"
                                  // value={calculateSolarPaybackPeriod(
                                  //   Number(installerOption.company_red_line) * 1000 * Number(userData?.initialDesign?.dataOutput?.roof?.maxKWSystem) +
                                  //     400 * Number(userData?.initialDesign?.dataOutput?.roof?.maxKWSystem),
                                  //   1000
                                  // )}
                                  value={
                                    userData.requires_battery
                                      ? `${calculateSolarPaybackPeriod(
                                          (Number(installerOption.company_red_line) * 1000 * userData?.google_system_size + 400 * Number(userData?.google_system_size)) * 0.7 +
                                            installerOption.available_batteries[0].additional_cost,
                                          1000
                                        )} years`
                                      : `$${calculateSolarPaybackPeriod(
                                          (Number(installerOption.company_red_line) * 1000 * userData?.google_system_size + 400 * Number(userData?.google_system_size)) * 0.7,
                                          1000
                                        )} years`
                                  }
                                /> */}

                                  {/* <MotionVStack gap={{ base: "0px" }} justifyContent="space-between">
                                  <MotionText w="100%" fontSize={{ base: "14px", lg: "18px" }}>
                                    Est. Install Time
                                  </MotionText>
                                  <MotionText w="full" fontWeight="bold" fontSize={{ base: "20px", lg: "28px" }}>
                                    2.5 Months
                                  </MotionText>
                                </MotionVStack> */}

                                  <MetricCard
                                    icon={<Star className="w-10 h-10 text-yellow-500" />}
                                    title="Ratings & Reviews"
                                    color="yellow"
                                    value={installerOption.installer_rating || "-"}
                                    subtext="From Google reviews"
                                  />

                                  {/* <MotionVStack gap={{ base: "0px" }} justifyContent="space-between">
                                  <MotionText w="100%" fontSize={{ base: "14px", lg: "18px" }}>
                                    Ratings & Reviews
                                  </MotionText>
                                  <HStack w="full" alignItems={{ base: "center", lg: "flex-end" }}>
                                    <Box display={{ base: "none", lg: "flex" }} justifyContent="center" alignItems="center">
                                      <MdOutlineStarPurple500 size="40px" color="#FFCB13" />
                                    </Box>
                                    <Box display={{ base: "flex", lg: "none" }} justifyContent="center" alignItems="center">
                                      <MdOutlineStarPurple500 size="20px" color="#FFCB13" />
                                    </Box>
                                    <MotionText w="full" fontWeight="bold" fontSize={{ base: "20px", lg: "28px" }}>
                                      {installerOption.installer_rating || "-"}
                                    </MotionText>
                                  </HStack>
                                </MotionVStack> */}
                                </div>
                              </MotionHStack>
                            </HStack>
                          </MotionVStack>
                          {/* <Image w="80px" h="80px" src="https://sundog.solar/wp-content/uploads/2018/02/nabcep-solar-installer.png" /> */}
                        </MotionHStack>
                      );
                    })}
              </VStack>
              <LogInPrompt isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
            </HStack>
          </VStack>
          {selectedCard && (
            <MotionBox
              position="fixed"
              top="0"
              left="0"
              width="100vw"
              height="100vh"
              bg="rgba(0, 0, 0, 0.2)"
              display="flex"
              alignItems="center"
              justifyContent="center"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={() => {
                if (selectedCard !== null) {
                  setSelectedCard(null);
                }
              }}
            >
              <MotionBox w="full" display="flex" justifyContent="flex-end">
                <VStack
                  bgColor="white"
                  maxW={{ base: "full", md: "70vw", xl: "50vw" }}
                  minH="100vh"
                  gap="40px"
                  maxH="90vh"
                  pb="0px"
                  overflowY="scroll"
                  borderRadius="12px"
                  onClick={(e) => e.stopPropagation()}
                >
                  <HStack w="full" justifyContent="flex-end" bgColor="white" p="20px">
                    <IconButton
                      icon={<IoClose />}
                      size="lg"
                      fontSize="2xl"
                      aria-label="close"
                      onClick={() => {
                        setInstallerReviews(defaultInstallerReviews);
                        setSelectedCard(null);
                      }}
                    >
                      Close
                    </IconButton>
                  </HStack>
                  <VStack
                    w="full"
                    minW={{ base: "full", lg: "400px" }}
                    gap={{ base: "10px", lg: "4vw" }}
                    align="stretch"
                    p={{ base: "10px", lg: "40px" }}
                    // onClick={() => {
                    //   handleInstallerSelect(selectedCard);
                    // }}
                  >
                    <VStack>
                      <Image
                        h={{ base: "100px", lg: "300px" }}
                        w="auto"
                        src={selectedCard.company_cover_image_url || "https://upload.wikimedia.org/wikipedia/commons/9/9d/Unknown_Member.jpg"}
                      />
                    </VStack>
                    <VStack p={{ base: "10px" }} maxW={{ base: "100%", lg: "40vw" }}>
                      <Heading w="full" fontFamily="'eurostile', sans-serif" mt={{ base: "40px", lg: "0px" }}>
                        {selectedCard?.company_name}
                      </Heading>
                      <Text w="full" fontSize="20px" fontWeight={700} fontFamily="'eurostile', sans-serif">
                        {selectedCard?.company_tag_line}
                      </Text>
                      <Text w="full" mt="40px" fontFamily="'eurostile', sans-serif">
                        {selectedCard?.company_about_text}
                      </Text>
                      <Text w="full" mt="40px">
                        {selectedCard.company_name} has been in business: <b>{selectedCard.years_in_business}</b> years
                      </Text>
                      <HStack w="full" alignItems="center" h="40px">
                        <Text position="relative" bottom="2px" fontWeight="bold">
                          Certified and Trained Professionals
                        </Text>
                        <FcApproval />
                      </HStack>
                    </VStack>
                  </VStack>
                  <HStack w="full" align="stretch" spacing="20px">
                    <VStack w="full" spacing="40px" bgColor="white" p={{ base: "10px", lg: "20px" }} pb="20px" borderRadius="12px" justifyContent="space-between" gap={0}>
                      <Text fontWeight="bold" fontSize="20px" mb="20px">
                        Google Reviews
                      </Text>
                      {/* <Text w="90%" textAlign="left" mb="10px" mt="20px">
                        Highest to Lowest
                      </Text> */}
                      {/* <Box w="full" ml="80px">
                        <Popover>
                          <PopoverTrigger>
                            <Button rightIcon={<IoFilter />}>Filter</Button>
                          </PopoverTrigger>
                          <PopoverContent>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <PopoverBody>
                              <RadioGroup value={filterPreference} onChange={(newFilter) => setFilterPreference(newFilter)}>
                                <VStack w="full" alignItems="flex-start">
                                  <Box>
                                    <Radio value="highLow">High - Low</Radio>
                                  </Box>
                                  <Radio value="lowHigh">Low - High</Radio>
                                  <Radio value="1Star">1 Star</Radio>
                                  <Radio value="2Stars">2 Stars</Radio>
                                </VStack>
                              </RadioGroup>
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Box> */}
                      {installerReviews && !loadingGoogleReviews ? (
                        installerReviews.result && installerReviews.result.rating !== 0 ? (
                          <HStack w="full" justifyContent="center">
                            <ArrowBackIcon onClick={handleGoodBackward} _hover={{ color: "blue.500" }} cursor="pointer" />
                            <Box w="90%" overflowX="hidden" borderRadius="14px">
                              <AnimatePresence mode="wait">
                                {installerReviews.result.reviews.map((review, index) => {
                                  if (index === goodIndex) {
                                    return (
                                      <MotionBox
                                        w="full"
                                        h="auto"
                                        key={index}
                                        p="20px"
                                        bgColor="gray.100"
                                        variants={variants}
                                        initial="hidden"
                                        animate="visible"
                                        exit="exit"
                                        borderRadius="14px"
                                      >
                                        <VStack w="full" alignItems="flex-start">
                                          <HStack w="full" alignItems="flex-start">
                                            <Avatar size={{ base: "sm", lg: "md" }} src={review.profile_photo_url} />
                                            <HStack w="full" justifyContent="space-between" alignItems="flex-start" lineHeight={1}>
                                              <VStack spacing={0} gap={0}>
                                                <Text fontWeight="bold" fontSize={{ base: "16px", lg: "18px" }}>
                                                  {review.author_name}
                                                </Text>
                                                <HStack w="full" mt="6px" justifyContent="left" mb="20px" gap={0}>
                                                  {Array.from({ length: 5 }, (_, i) =>
                                                    i + 1 <= review.rating ? (
                                                      <MdOutlineStarPurple500 key={i} size="20px" color="#FFCB13" />
                                                    ) : (
                                                      <MdOutlineStarPurple500 key={i} size="20px" />
                                                    )
                                                  )}
                                                </HStack>
                                              </VStack>
                                              <Text color="gray.500" fontSize={{ base: "12px", lg: "16px" }}>
                                                {review.relative_time_description}
                                              </Text>
                                            </HStack>
                                          </HStack>
                                          <ExpandableText>
                                            <Text fontSize={{ base: "14px", lg: "16px" }}>{review.text}</Text>
                                          </ExpandableText>
                                        </VStack>
                                      </MotionBox>
                                    );
                                  }
                                })}
                              </AnimatePresence>
                            </Box>
                            <ArrowForwardIcon onClick={handleGoodForward} _hover={{ color: "blue.500" }} cursor="pointer" />
                          </HStack>
                        ) : (
                          <VStack w="full">
                            <Text>We could not locate any reviews of this installer</Text>
                            {/* <CircularProgress isIndeterminate /> */}
                          </VStack>
                        )
                      ) : (
                        <VStack w="full">
                          <CircularProgress isIndeterminate />
                        </VStack>
                      )}
                    </VStack>
                  </HStack>
                  <Box w="full" minH="2px" bgColor="black" />

                  {/* Equipment selection */}
                  <VStack w="full" gap={4} p={{ base: "10px", lg: "40px" }} pt={{ base: "10px", lg: "0px" }}>
                    {selectedCard && selectedCard.available_panels && (
                      <VStack width="100%" borderRadius="6px" display="flex" justifyContent="space-between" alignItems="center" p={8} px={4} gap={4} pt={0}>
                        <Text w="full" fontWeight="bold" fontSize="22px" mb="10px">
                          Equipment Selection ({selectedCard.available_panels.length})
                        </Text>
                        {selectedCard.available_panels.map((panel, i) => {
                          return (
                            <VStack w="full" key={i} bgColor="gray.100" p="10px" borderRadius="12px" cursor="pointer" _hover={{ bg: "gray.200" }} _active={{ bg: "gray.300" }}>
                              <HStack w="full">
                                <Box
                                  w={{ base: "100px", md: "200px" }}
                                  minW={{ base: "100px", md: "200px" }}
                                  h={{ base: "100px", md: "200px" }}
                                  bgColor="white"
                                  borderRadius="12px"
                                  // TODO: implement fallback image
                                  bgImage={panel?.image_url || ""}
                                  bgSize="contain"
                                  bgPosition="center"
                                  bgRepeat="no-repeat"
                                />
                                <VStack w="full" px={{ base: "2px", md: "20px" }}>
                                  <Text w="full" fontWeight="bold" fontSize={{ base: "16px", md: "19px" }}>
                                    {panel?.seriesName}
                                  </Text>
                                  <VStack w="full" gap="0px">
                                    <Text w="full" fontSize={{ base: "14px", md: "16px", lg: "18px" }}>
                                      {panel?.additional_cost === 0 ? "Additional Cost: None" : `Additional Cost: $${new Intl.NumberFormat().format(panel?.additional_cost || 0)}`}
                                    </Text>
                                    <Text w="full" fontSize={{ base: "14px", md: "16px", lg: "18px" }}>
                                      Efficiency: {panel?.efficiency}%
                                    </Text>
                                    <Text w="full" fontSize={{ base: "14px", md: "16px", lg: "18px" }}>
                                      DC Rating: {panel?.pMax}W
                                    </Text>
                                  </VStack>
                                </VStack>
                                {!selectedPanel && panel?.default_panel && (
                                  <Box h="full" position="relative" top="0px" right="0px">
                                    <Badge colorScheme="green">Added</Badge>
                                  </Box>
                                )}
                              </HStack>
                            </VStack>
                          );
                        })}
                      </VStack>
                    )}

                    {selectedCard && selectedCard.available_batteries && userData.requires_battery && (
                      <VStack width="100%" borderRadius="6px" display="flex" justifyContent="space-between" alignItems="center" p={8} px={4} gap={4} pt={0}>
                        <Text w="full" fontWeight="bold" fontSize="22px" mb="10px">
                          Battery Selection ({selectedCard.available_batteries.length})
                        </Text>
                        {selectedCard.available_batteries.map((battery, i) => {
                          return (
                            <VStack
                              w="full"
                              key={i}
                              bgColor="gray.100"
                              p="10px"
                              borderRadius="12px"
                              cursor="pointer"
                              _hover={{ bg: "gray.200" }}
                              _active={{ bg: "gray.300" }}
                              onClick={() => {
                                if (selectedBattery === battery) {
                                  setSelectedBattery(null);
                                } else {
                                  setSelectedBattery(battery);
                                }
                              }}
                            >
                              <HStack w="full">
                                <Image
                                  w={{ base: "100px", md: "200px" }}
                                  minW={{ base: "100px", md: "200px" }}
                                  h={{ base: "100px", md: "200px" }}
                                  bgColor="white"
                                  borderRadius="12px"
                                  // TODO: implement fallback image
                                  bgImage={battery?.image_url || ""}
                                  bgSize="contain"
                                  bgPosition="center"
                                  bgRepeat="no-repeat"
                                />
                                <VStack w="full" px={{ base: "2px", md: "20px" }}>
                                  <Text w="full" fontWeight="bold" fontSize={{ base: "16px", md: "19px" }}>
                                    {battery?.seriesName}
                                  </Text>
                                  <VStack w="full" gap="0px">
                                    <Text w="full" fontSize={{ base: "14px", md: "16px", lg: "18px" }}>
                                      {`Additional Cost: $${new Intl.NumberFormat().format(battery?.additional_cost || 0)}`}
                                    </Text>
                                    {battery?.storage_capacity && (
                                      <Text w="full" fontSize={{ base: "14px", md: "16px", lg: "18px" }}>
                                        Storage Capacity: {battery?.storage_capacity} kWh AC
                                      </Text>
                                    )}
                                    {battery?.power_output && (
                                      <Text w="full" fontSize={{ base: "14px", md: "16px", lg: "18px" }}>
                                        Power Output: {battery?.power_output} kW
                                      </Text>
                                    )}
                                  </VStack>
                                </VStack>
                                {(!selectedBattery && battery?.default_battery && userData.battery_required) ||
                                  (selectedBattery && selectedBattery.seriesName === battery?.seriesName && (
                                    <Box h="full" position="relative" top="0px" right="0px">
                                      <Badge colorScheme="green">Added</Badge>
                                    </Box>
                                  ))}
                              </HStack>
                            </VStack>
                          );
                        })}
                      </VStack>
                    )}
                  </VStack>
                  <VStack w="full" position="sticky" bottom="0px" gap="0px">
                    <HStack
                      w="full"
                      h={{ base: "250px", lg: "unset" }}
                      py="40px"
                      pt="80px"
                      justifyContent="center"
                      bg="linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(237,242,247,1) 30%)"
                    >
                      <Button
                        rightIcon={<FaArrowRight />}
                        h="46px"
                        bg="linear-gradient(270deg, rgba(223,184,24,1) 0%, rgba(233,167,9,1) 61%)"
                        color="white"
                        _hover={{ bg: "linear-gradient(270deg, rgba(241,206,63,1) 0%, rgba(247,187,42,1) 61%)", bottom: "2px", boxShadow: "lg" }}
                        onClick={() => handleInstallerChosen(selectedCard)}
                      >
                        Purchase through this installer
                      </Button>
                    </HStack>
                  </VStack>
                </VStack>
              </MotionBox>
            </MotionBox>
          )}
        </>
      ) : (
        <VStack pt="120px">
          <Heading fontSize={{ base: "28px", lg: "34px" }} fontFamily="'eurostile', sans-serif">
            We couldn't find any installers near you
          </Heading>
          <Image w="full" maxW="500px" mt="80px" mb="20px" src={installersNotFound} />
          <Text mt="10px" fontFamily="'eurostile', sans-serif" fontSize={{ base: "16px", md: "20px" }}>
            We will send you an email when we are able to service your area.
          </Text>
        </VStack>
      )}
    </Box>
  );
};
export default InstallerSelect;

function LogInPrompt({ isOpen, onOpen, onClose }: { isOpen: boolean; onOpen: () => void; onClose: () => void }) {
  return (
    <>
      <Modal onClose={onClose} size={"lg"} isOpen={isOpen}>
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
        <ModalContent maxW={{ base: "90vw", lg: "lg" }} bgColor={{ base: "white", lg: "gray.50" }}>
          <ModalHeader w="full" bgColor="white" borderTopRadius="12px" />
          <ModalCloseButton />
          <ModalBody bgColor="white" borderBottomRadius="12px">
            <VStack>
              <UserSignUpModal onClose={onClose} />
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

function ExpandableText({ children }: { children: JSX.Element }) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <Text maxH={isExpanded ? "none" : "120px"} overflowY="hidden">
        {children}
      </Text>
      {children.props.children.length >= 300 ? (
        <Text w="full" textAlign="right" color="blue" fontWeight="bold" cursor="pointer" onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? "show less" : "show more"}
        </Text>
      ) : (
        <></>
      )}
    </>
  );
}

function calculateSolarPaybackPeriod(installationCost: number, annualSavings: number, annualDegradation = 0.005) {
  let remainingCost = installationCost;
  let years = 0;

  while (remainingCost > 0) {
    years++;
    let savingsThisYear = annualSavings * Math.pow(1 - annualDegradation, years - 1);
    remainingCost -= savingsThisYear;
  }

  return years;
}
