import { Box, VStack, Text, Link } from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import doorSalesman from "../Blogs/BlogImages/doorSalesman.webp";
import solarTown from "../Blogs/BlogImages/solarTown.webp";
import questionInABottle from "../Blogs/BlogImages/questionInABottle.webp";
import solarCalc from "../Blogs/BlogImages/solarCalc.webp";

const BlogUnderstandingSolarRoofTaxIncentives = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBlogLinkClick = (link: string) => {
    navigate(link);
  };

  return (
    <Box w="full" minH="90vh" pb="60px" pt="80px">
      <Box w="full" h={{ base: "200px", lg: "500px" }} bgImage={solarCalc} bgSize="cover" bgRepeat="no-repeat" bgPosition="center" />
      <VStack w="full" gap="40px" align="stretch" p="20px">
        <VStack w="full">
          <br />
          <VStack w="full" mt="20px" lineHeight={1} maxW="800px">
            <Text w="full" fontWeight="bold" fontSize={{ base: "28px", lg: "32px" }}>
              Understanding Solar Roof Tax Incentives
            </Text>
          </VStack>
          <br />
          <Text fontSize="20px" maxW="800px">
            If you're considering harnessing the power of solar energy for your home, you're in the right place. A solar roof can work for you in powerful ways, from helping lower
            your utility bill, increasing your home value, and reducing your carbon footprint. When structured correctly, it’s the green gift that keeps on giving! To make sure
            you’re not leaving any of the other kind of green on the table, it’s important to understand what help is available to you. Today, we're diving into the world of solar
            incentives and rebates—a landscape of potential savings that could brighten your financial outlook while helping the planet.
          </Text>
          <br />
          <Text w="full" fontWeight="bold" fontSize="18px" maxW="800px">
            FEDERAL TAX CREDITS
          </Text>
          <Text fontSize="20px" maxW="800px">
            The first and most substantial help available to you comes by way of the Federal government. They, alongside the Department of Energy, have some massive goals when it
            comes to America’s adoption of solar roofs. To help stimulate growth, Congress passed legislation in 2022 providing homeowners with a solar tax credit equal to 30% of
            the total installation cost. This credit is good through 2032, then starts decreasing in the years following. The best part is there’s no income limit or maximum amount
            that can be claimed. To be eligible for the Federal solar tax credits, homeowners must meet the following criteria:
          </Text>
          <br />
          <Text w="full" maxW="700px" textAlign="left">
            • Own the solar roof system (cash purchase or finance qualifies, but leasing does not)
          </Text>
          <br />
          <Text w="full" maxW="700px" textAlign="left">
            • The solar system must be installed on the roof of your current U.S. residence that you own
          </Text>
          <br />
          <Text w="full" maxW="700px" textAlign="left">
            • The solar system must be new or being used for the first time as the tax credit can only be claimed on the “original installation” of the solar equipment.
          </Text>
          <br />
          <VStack w="full" maxW="800px">
            <Text w="full" fontWeight="bold" fontSize="18px" maxW="800px">
              WHAT IS A TAX CREDIT?
            </Text>
            <Text fontSize="20px">
              Now you might be wondering, "What exactly is a tax credit?". A tax credit is a direct reduction in the amount of tax owed by an individual or business to the
              government. Unlike a tax deduction, which reduces taxable income, a tax credit directly reduces the amount of tax owed, dollar for dollar. Tax credits can be applied
              to various expenses or activities, such as education expenses, childcare costs, or, in the case of the Federal Investment Tax Credit (ITC), the cost of installing
              renewable energy systems like solar panels. As an example, if the total cost of installation was $20,000, an eligible tax credit would provide the qualifying
              purchaser with a $6,000 tax credit that would be recouped when you file taxes. This requires you to fill out IRS Form 5695 and is best done after consulting with a
              tax professional to make sure you’re filing correctly when that next tax season rolls around.
            </Text>
          </VStack>
          <br />
          <VStack w="full" maxW="800px">
            <Text w="full" fontWeight="bold" fontSize="18px" maxW="800px">
              STATE & LOCAL INCENTIVES
            </Text>
            <Text fontSize="20px">
              While the Federal tax credit is by far the most impactful financial help available to homeowners, many state and local governments also kick in some assistance to
              help facilitate progress toward their own green initiative goals. These incentives can include tax credits, rebates, and sometimes even Renewable Energy Credits
              (RECs) that allow homeowners to sell unused energy credits back to utility companies to earn additional income. The easiest way to find out more about the financial
              incentives available in your state is to use the Database of State Incentives for Renewables & Efficiency (DSIRE) by visiting{" "}
              <Link href="https://www.dsireusa.org." color="blue">
                www.dsireusa.org.
              </Link>{" "}
              The DSIRE offers a searchable database of incentives and policies specific to the area you choose, ensuring you don’t leave any dollars on the table.
            </Text>
          </VStack>
          <br />
          <VStack w="full" maxW="800px">
            <Text w="full" fontWeight="bold" fontSize="18px" maxW="800px">
              CAN I DOUBLE-DIP?
            </Text>
            <Text fontSize="20px">
              You might be wondering if you can claim both Federal and state incentives for the same purchase. Generally speaking, getting one incentive won’t limit your ability to
              use another. However, some incentives reduce the cost of the solar roof system before the Federal tax credit is applied and others don’t, so it’s important to read up
              on the rules specific to the incentives you qualify for in your area. For more information on the IRS’ official guidelines for claiming incentives, you can visit{" "}
              <Link href="https://www.irs.gov/credits-deductions/residential-clean-energy-credit" color="blue">
                www.irs.gov/credits-deductions/residential-clean-energy-credit.
              </Link>
            </Text>
          </VStack>
          <br />
          <VStack w="full" maxW="800px">
            <Text fontSize="20px">
              So, while going solar can make a lot of sense for many homeowners, the price tag can be a barrier to entry. By taking advantage of Federal and state financial
              incentives, homeowners can reduce costs and turn a potential obstacle into an impactful asset. If you’d like to see what solar can do for you, head on over to{" "}
              <Link href="https://www.klaravia.co" color="blue">
                www.klaravia.co
              </Link>{" "}
              where you can design your roof, compare quotes, and gain access to more valuable resources in your journey towards a brighter future for you and planet Earth.
            </Text>
          </VStack>
          <br />
          <VStack maxW="800px" color="gray.500">
            <Text fontSize="16px">Additional Resources & References:</Text>
            <Text fontSize="14px">(Solar Energy Technologies Office, 2023)</Text>
            <Text fontSize="14px">(Internal Revenue Service, 2023)</Text>
            <Text fontSize="14px">(Department of Energy, 2022)</Text>
            <Text fontSize="14px">(DSIRE, n.d.)</Text>
          </VStack>
        </VStack>
        <br />
        <VStack w="full">
          <VStack w={{ base: "full", lg: "35vw" }} alignItems="center" gap="20px" maxW="800px" onClick={() => handleBlogLinkClick("/blogs/shining-a-light-on-solar")}>
            <VStack w="full" cursor="pointer">
              <Box w="full" minH="250px" h="auto" bgImage={doorSalesman} bgSize="cover" bgRepeat="no-repeat" bgPosition="top" />
              <VStack w="full" mt="10px" lineHeight={1} pb="10px">
                <Text w="full" color="gray.500">
                  How Klaravia is Fighting for Homeowners
                </Text>
                <Text w="full" fontWeight="bold" fontSize="24px">
                  Shining a Light on Solar
                </Text>
              </VStack>
            </VStack>

            <VStack w="full" cursor="pointer" alignItems="center" onClick={() => handleBlogLinkClick("/blogs/to-solar-or-not-to-solar")}>
              <Box w="full" minH="250px" h="auto" bgImage={solarTown} bgSize="cover" bgRepeat="no-repeat" bgPosition="top" />
              <VStack w="full" mt="10px" lineHeight={1} pb="10px">
                <Text w="full" color="gray.500">
                  Does solar work for you?
                </Text>
                <Text w="full" fontWeight="bold" fontSize="24px">
                  To Solar or not to Solar: When Solar makes Sense
                </Text>
              </VStack>
            </VStack>

            <VStack w="full" cursor="pointer" onClick={() => handleBlogLinkClick("/blogs/top-10-solar-questions-answered")}>
              <Box w="full" minH="250px" h="auto" bgImage={questionInABottle} bgSize="cover" bgRepeat="no-repeat" bgPosition="top" />
              <VStack w="full" mt="10px" lineHeight={1} pb="10px">
                <Text w="full" color="gray.500">
                  Better understanding solar
                </Text>
                <Text w="full" fontWeight="bold" fontSize="24px">
                  Top 10 Solar Questions Answered
                </Text>
              </VStack>
            </VStack>
          </VStack>
        </VStack>
      </VStack>
    </Box>
  );
};
export default BlogUnderstandingSolarRoofTaxIncentives;
