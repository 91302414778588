import React, { useEffect, useState } from "react";
import { Box, Button, Checkbox, Container, FormControl, Heading, HStack, IconButton, Input, InputGroup, InputRightElement, Link, Stack, Text, Image } from "@chakra-ui/react";
import { ViewOffIcon, ViewIcon } from "@chakra-ui/icons";
import { Auth } from "aws-amplify";
import UserVerificationModal from "./UserVerificationModal";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Providers/AuthProvider";
import logo from "../../Assets/logo.svg";
import { v4 as uuidv4 } from "uuid";

const UserSignUpModal = ({ onClose }: { onClose: () => void }) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showVerify, setShowVerify] = useState(false);
  const [error, setError] = useState("");
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const navigate = useNavigate();
  const auth = useAuth();

  const handleClick = () => setShowPassword(!showPassword);

  const formatPhoneNumber = (value: string) => {
    // Remove all non-numeric characters
    const cleaned = value.replace(/\D/g, "");
    // Ensure it starts with country code, e.g., +1 for US
    const formatted = cleaned.startsWith("1") ? `+${cleaned}` : `+1${cleaned}`;
    return formatted;
  };

  const handleSignUp = async () => {
    try {
      await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          // "custom:username": username,
          phone_number: formatPhoneNumber(phoneNumber),
          "custom:user_id": uuidv4(),
          "custom:first_name": firstName,
          "custom:last_name": lastName,
          // You can add other user attributes here
        },
      });
      // On successful sign up, you might want to redirect the user to a confirmation page
      setShowVerify(true);
      console.log("Sign-up successful!");
    } catch (error: any) {
      setError(error.message);
      console.error("Error during sign-up:", error);
    }
  };

  const handleSignIn = async () => {
    try {
      const cognitoUser = await Auth.signIn(email, password);
      auth.setCognitoUser(cognitoUser);
      onClose();
    } catch (error: any) {
      setError(error.message);
      console.error("Error signing in", error);
    }
  };

  const handleKeyDown = (event: { key: string }) => {
    if (event.key === "Enter") {
      handleSignIn();
    }
  };

  useEffect(() => {}, [firstName, lastName, phoneNumber, email]);

  return (
    <Box w="full" minW={{ lg: "500px" }} p="10px" bgColor="white">
      <Container maxW="lg" bgColor="white" py={{ base: "2", md: "18" }} px={{ base: "0", sm: "8" }}>
        {showLoginModal ? (
          <Stack spacing="8">
            <Stack spacing="6">
              {/* <Text w="full" textAlign="center" fontSize="50px" fontWeight={500} fontFamily="'new-astro', sans-serif">
                Klaravia
              </Text> */}
              <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
                <Stack spacing={6}>
                  <Box>
                    <HStack w="full" justifyContent="center">
                      <Image h="60px" w="60px" src={logo} />
                      <Text fontSize="40px" fontFamily="'new-astro', sans-serif">
                        Klaravia
                      </Text>
                    </HStack>
                  </Box>
                </Stack>
                <Heading mt="20px" size={{ base: "xs", md: "sm" }}>
                  Log in to your account
                </Heading>
              </Stack>
            </Stack>
            <Box py={{ base: "0", sm: "0" }} px={{ base: "4", sm: "10" }} bg={{ base: "white", sm: "bg.surface" }} borderRadius={{ base: "none", sm: "xl" }}>
              <Stack spacing="6">
                <Stack spacing="5">
                  <FormControl>
                    <Input
                      id="email"
                      type="email"
                      placeholder="Email"
                      border="2px solid rgb(238, 239, 241)"
                      fontFamily="'eurostile', sans-serif"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </FormControl>
                  <FormControl>
                    <InputGroup size="md">
                      <Input
                        pr="4.5rem"
                        type={showPassword ? "text" : "password"}
                        border="2px solid rgb(238, 239, 241)"
                        placeholder="Password"
                        fontFamily="'eurostile', sans-serif"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyDown={handleKeyDown}
                      />
                      <InputRightElement width="3rem">
                        <IconButton h="1.75rem" aria-label="Password" size="sm" icon={showPassword ? <ViewOffIcon /> : <ViewIcon />} onClick={handleClick} />
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                </Stack>
                <HStack justify="space-between" flexDir={{ base: "column-reverse", lg: "row" }}>
                  <Checkbox defaultChecked>
                    <Text fontSize={{ base: "12px" }}>Remember me</Text>
                  </Checkbox>
                  <Button variant="text" size="sm">
                    Forgot password?
                  </Button>
                </HStack>
                <Stack spacing="6">
                  <Text color="red.500">{error}</Text>
                  <Button onClick={handleSignIn}>Log in</Button>
                  {/* <HStack>
                          <Divider />
                          <Text textStyle="sm" whiteSpace="nowrap" color="fg.muted">
                            or continue with
                          </Text>
                          <Divider />
                        </HStack> */}
                </Stack>
              </Stack>
            </Box>
            <Text w="full" textAlign="center">
              Don't have an account?{" "}
              <Link color="#4810a3" pl="4px" onClick={() => setShowLoginModal(false)} fontFamily="'eurostile', sans-serif">
                Sign up
              </Link>
            </Text>
          </Stack>
        ) : showVerify ? (
          <UserVerificationModal email={email} password={password} onClose={onClose} />
        ) : (
          <Stack spacing="8">
            <Stack spacing="2">
              <Box>
                <HStack w="full" justifyContent="center">
                  <Image h="60px" w="60px" src={logo} />
                  <Text fontSize="40px" fontFamily="'new-astro', sans-serif">
                    Klaravia
                  </Text>
                </HStack>
              </Box>
              <Stack mt="20px" spacing={{ base: "2", md: "3" }} textAlign="center">
                <Heading size={{ base: "xs", md: "sm" }} fontFamily="'eurostile', sans-serif">
                  Please sign up for an account to continue
                </Heading>
              </Stack>
            </Stack>
            <Box py={{ base: "0", sm: "8" }} px={{ base: "4", sm: "10" }} bg={{ base: "white", sm: "bg.surface" }} borderRadius={{ base: "none", sm: "xl" }}>
              <Stack spacing="6">
                <form>
                  <Stack spacing="5">
                    <HStack>
                      <FormControl>
                        <Input
                          id="first_name"
                          type="text"
                          placeholder="First Name"
                          border="1px solid rgb(238, 239, 241)"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          isRequired
                        />
                      </FormControl>
                      <FormControl>
                        <Input
                          id="last_name"
                          type="text"
                          placeholder="Last Name"
                          border="1px solid rgb(238, 239, 241)"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          isRequired
                        />
                      </FormControl>
                    </HStack>
                    <FormControl>
                      <Input
                        id="phone"
                        type="phone"
                        placeholder="(***) *** ****"
                        border="1px solid rgb(238, 239, 241)"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        isRequired
                      />
                    </FormControl>
                    <FormControl>
                      <Input
                        id="email"
                        type="email"
                        placeholder="Email"
                        border="1px solid rgb(238, 239, 241)"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        isRequired
                      />
                    </FormControl>
                    <FormControl>
                      <InputGroup size="md">
                        <Input
                          pr="4.5rem"
                          type={showPassword ? "text" : "password"}
                          border="1px solid rgb(238, 239, 241)"
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          isRequired
                        />
                        <InputRightElement width="3rem">
                          <IconButton h="1.75rem" aria-label="Password" size="sm" icon={showPassword ? <ViewOffIcon /> : <ViewIcon />} onClick={handleClick} />
                        </InputRightElement>
                      </InputGroup>
                    </FormControl>
                  </Stack>
                  <Stack mt="16px">
                    <HStack justify="space-between" flexDir={{ base: "column-reverse", lg: "row" }}>
                      <Checkbox defaultChecked>
                        <Text fontSize={{ base: "12px" }}>Remember me</Text>
                      </Checkbox>
                      <Text fontSize={{ base: "12px" }}>
                        Have an account?{" "}
                        <Text color="#4810a3" pl="4px" onClick={() => setShowLoginModal(true)}>
                          Log in
                        </Text>
                      </Text>
                    </HStack>
                    <Stack spacing="6">
                      <Text color="red.500">{error}</Text>
                      <Button onClick={handleSignUp}>Sign Up</Button>
                    </Stack>
                  </Stack>
                </form>
              </Stack>
            </Box>
          </Stack>
        )}
      </Container>
    </Box>
  );
};
export default UserSignUpModal;
