import React, { useState, ChangeEvent, KeyboardEvent, useRef, useEffect } from "react";
import { Text, VStack, HStack, Input, Button, Flex, Icon } from "@chakra-ui/react";
import { FiSend } from "react-icons/fi";
import { useCreateMessage, useListMessages, useOnCreateMessageSubscription } from "../../hooks/userHooks";
import { useAuth } from "../../Providers/AuthenticationProvider";

const SupportChat: React.FC = () => {
  const [messages, setMessages] = useState<any[]>([]);
  const [input, setInput] = useState<string>("");
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const currentUser = "user"; // Adjust based on your actual user context or auth state
  const { userData } = useAuth();

  const { createMessage, data: createMessageData, loading: loadingMessage, error: createMessageError } = useCreateMessage();
  const { data: subscriptionData, loading, error: subErr } = useOnCreateMessageSubscription(userData && userData.id ? userData.id : "");
  const {
    data: messageList,
    loading: loadingMessages,
    error: messageError,
  } = useListMessages({ variables: { filter: { userId: { eq: userData && userData.id ? userData.id : "" } } } });

  useEffect(() => {
    if (messageList && messageList.listMessages && messageList.listMessages.items) {
      const initialMessages = messageList.listMessages.items
        .slice() // Create a shallow copy of the list to avoid direct mutation
        .sort((a, b) => {
          if (!a && !b) return 0; // Both are null/undefined, consider them equal
          if (!a) return -1; // a is null/undefined, treat it as earlier
          if (!b) return 1; // b is null/undefined, treat it as earlier

          const dateA = a.createdAt ? new Date(a.createdAt).getTime() : 0;
          const dateB = b.createdAt ? new Date(b.createdAt).getTime() : 0;

          return dateA - dateB;
        })
        .map((e) => {
          return { text: e?.content, sender: e?.sender };
        });
      setMessages(() => [...initialMessages]);
    }
  }, [messageList]);

  useEffect(() => {
    console.log(JSON.stringify(subErr, null, 2));
    if (subscriptionData) {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          text: subscriptionData?.onCreateMessage?.content,
          sender: subscriptionData?.onCreateMessage?.sender,
        },
      ]);
    }
  }, [subscriptionData, currentUser]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  const handleSendMessage = () => {
    if (input.trim()) {
      const newMessage = {
        id: new Date().getTime().toString(), // Temporary ID until backend update
        userId: userData?.id,
        klaravia_user_id: userData?.salesforceId,
        content: input,
        sender: "user",
        receiver: "support",
      };
      // setMessages([...messages, { text: input, sender: "user" }]);
      createMessage({ variables: { input: newMessage } });
      setInput("");
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  };

  const handleInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") handleSendMessage();
  };

  return (
    <Flex flexDirection="column" h="90vh" bg="#f4eee9" fontFamily="'eurostile', sans-serif" p="20px">
      <VStack flex={1} w="full" overflowY="auto" bg="#f4eee9" border="2px solid gray" borderRadius="12px" spacing={4} p="20px">
        <HStack spacing={0} gap={0} m="0px" p="0px" align="stretch" alignItems="flex-end" mb="40px">
          <Text w="full" textAlign="center" fontSize="28px" fontFamily="'new-astro', sans-serif" color="black" p={1} pb="0px" lineHeight={1}>
            Klaravia
          </Text>
          <Text w="full" textAlign="center" fontSize="28px" fontWeight="semibold" fontFamily="'eurostile', sans-serif" color="black" p={1} pb="2px" lineHeight={1}>
            Support
          </Text>
        </HStack>
        {loadingMessages ? (
          <VStack w="full" h="55vh" justifyContent="center">
            <Text fontSize="20px" fontWeight="semibold" p={4} color="gray.600">
              Loading...
            </Text>
          </VStack>
        ) : (
          <>
            {messages.length > 0 ? (
              messages.map((message, index) => (
                <Flex
                  key={index}
                  maxW="70%"
                  wordBreak="break-word"
                  overflowWrap="break-word"
                  bg={message.sender === "user" ? "white" : "black"}
                  color={message.sender === "user" ? "black" : "white"}
                  p={3}
                  borderRadius="md"
                  alignSelf={message.sender === "user" ? "flex-end" : "flex-start"}
                >
                  <Text>{message.text}</Text>
                </Flex>
              ))
            ) : (
              <VStack w="full" h="55vh" justifyContent="center">
                <Text fontSize="20px" fontWeight="semibold" p={4} color="gray.600">
                  You haven't sent or recieved any messages yet.
                </Text>
              </VStack>
            )}
          </>
        )}
      </VStack>
      <HStack w="full" spacing={2} position="sticky" bottom={"20px"} mt="20px">
        <Input
          h="60px"
          flex={1}
          bg="white"
          border="1px solid"
          borderColor="gray.300"
          borderRadius="md"
          placeholder="Type your message..."
          value={input}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          _focus={{ borderColor: "black", boxShadow: "none" }}
        />
        <Button bg="black" color="white" h="60px" w="60px" _hover={{ bg: "gray.800" }} onClick={handleSendMessage} borderRadius="md">
          <Icon w="24px" h="24px" as={FiSend} />
        </Button>
      </HStack>
    </Flex>
  );
};

export default SupportChat;
